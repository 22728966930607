import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// @Utility
import setBodyScroll from "./../../../../@utility/app/setBodyScroll";

//  CSS
import modalStyles from "./../assets/css/Modal.module.css";

// Component
import CloseButton from "./CloseButton";

export default function ModalApp(props) {
    const homeData = useSelector(state => state.reduxHome.result);

    const close = () => {
        setBodyScroll(false);
        props.onShow(false)
    };

    const back = () => {
        props.onStep(props.step - 1)
    };

    useEffect(() => {
        setBodyScroll(props.show);
    }, [props.show]);

    return (
        props.show ?
            <>
                <div className={modalStyles.contentPosition} style={{ zIndex: "2000" }}>
                    <div className={modalStyles.boxBody}>
                        <div className={modalStyles.box}>

                            {
                                props.step > 1 ?
                                    <div className={modalStyles.backButton} onClick={back}>
                                        <img className={modalStyles.iconClose} src="/images/game/btn_back_circle.webp" alt=" back" />
                                    </div> : null
                            }

                            {
                                !props.disable && (
                                    homeData !== null && (
                                        homeData?.auth_sms !== 1 && homeData?.auth_sms !== true && (
                                            <div className={modalStyles.closeButton} onClick={close}>
                                            <CloseButton backPage={props.exit}></CloseButton>
                                        </div>
                                        )
                                    )
                                )
                            }

                            <div className={modalStyles.boxContentWidthBig}>
                                <div className={modalStyles.boxContentPadding}>
                                    {
                                        props.children
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={modalStyles.fixedBackdrop} style={{ zIndex: "1900" }}></div>
            </>
            :
            null
    );
}
import { useDispatch } from 'react-redux';

// Redux
import { openProfile } from "./../../../../../@core/redux/actions/profile/index";

// Component
import ContentProfile from "./component/Content";
import ModalApp from "./../../components/Modal";

const ProfileApp = ({ reduxProfile }) => {
    const dispatch = useDispatch();

    const onShow = (val) => {
        if (val === false) {
            document.title = process.env.REACT_APP_OG_SITE_NAME+ " - หน้าหลัก";
        }

        dispatch(openProfile({ ...reduxProfile, show: val }));
    }

    return (
        <ModalApp show={reduxProfile.show} onShow={onShow}>
            <ContentProfile reduxProfile={reduxProfile}></ContentProfile>
        </ModalApp>
    );
}

export default ProfileApp;
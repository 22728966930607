// Component
import Mode from "./mode/index";
import ModeTwo from "./mode-two/index";

export default function ContentInvite(props) {

    const renderContent = () => {
        if(Number(props.reduxAppCMS?.mode_affiliate) === 2) {
            return  <ModeTwo linkFriend={props.linkFriend} onShow={props.onShow} reduxAppCMS={props.reduxAppCMS}></ModeTwo>;
        }
        else {
            return  <Mode linkFriend={props.linkFriend} onShow={props.onShow} reduxAppCMS={props.reduxAppCMS}></Mode>
        }
    };

    return renderContent();
}
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

//  CSS
import styles from "../../../assets/css/Style.module.css";

export default function BackButton({ backPage }) {
    const [page, setPage] = useState("/deposit/home");
    // let setPage = page;
    const history = useHistory();

    const closePage = () => {
        history.push(page);
    };

    useEffect(() => {
        setPage(backPage);
    }, [backPage]);

    return (
        <div onClick={() => closePage()}>
            <img className={styles.iconClose} src="/images/game/btn_back_circle.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
        </div>
    );
}
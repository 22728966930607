import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import { setDataConfirm } from "./../../../../@core/redux/actions/app/confirm";
import { setDataAlert } from "./../../../../@core/redux/actions/app/alert";
import { setStatusSpinner } from "./../../../../@core/redux/actions/app/spinner";
import { setHomeData } from "./../../../../@core/redux/actions/home/index";
import { openActivity } from "./../../../../@core/redux/actions/activity/index";

// Services
import { confirmShopCredit } from "./../../../../@core/services/shopService";
import { confirmActivityCoupon } from "./../../../../@core/services/activityService";
import { loadHomeData } from "./../../../../@core/services/homeService";

// @utility
import alertText from "./../../../../@utility/app/alertText";

// Component
import ModalApp from "./Modal";

// CSS
import styles from "./../assets/css/Style.module.css";
import modalStyles from "./../assets/css/Modal.module.css";
import buttonStyles from "./../assets/css/Button.module.css";

const Confirm = (props) => {
    const dispatch = useDispatch();
    const homeData = useSelector(state => state.reduxHome.result);

    const onShow = (val) => {
        dispatch(setDataConfirm({ ...props.confirmData, show: val }));
    }

    const alertPopup = (myJson) => {
        dispatch(setDataAlert(myJson));
    };

    const reloadBalance = () => {
        loadHomeData().then((data) => {
            const statusCode = data.code;
            if (statusCode === 0 || statusCode === 200) {
                dispatch(setHomeData(data.result));
            }
        });
    };

    const setAlertText = (type, title, text) => {
        return {
            type: type,
            title: title,
            text: text,
            action: true,
            redirect: null,
            show: true,
            buttonText: "ตกลง"
        }
    };

    const requestShop = (data) => {
        onShow(false);
        if(homeData.wallets[0].balance >= data.source_amount) {
            dispatch(setStatusSpinner({status: true, textProcess: null}));
            confirmShopCredit(data.id).then((data) => {
                dispatch(setStatusSpinner({status: false, textProcess: null}));
                const statusCode = data.code;
                if (statusCode === 0 || statusCode === 200) {
                    alertPopup(setAlertText("success", "แจ้งเตือน", "ทำรายการสำเร็จ"));
                    reloadBalance();
                }
                else {
                    alertPopup(setAlertText("warning", "แจ้งเตือน", "ทำรายการไม่สำเร็จ ! กรุณาลองใหม่อีกครั้ง"));
                }
            });
        }
        else {
            alertPopup(setAlertText("warning", "แจ้งเตือน", "จำนวนเพชรคงเหลือไม่เพียงพอ กรุณาลองใหม่อีกครั้ง"));
        }
    };

    const requestCoupon = (data) => {
        onShow(false);
        dispatch(setStatusSpinner({status: true, textProcess: null}));
        confirmActivityCoupon(data.activity_id, data.code).then((data) => {
            dispatch(setStatusSpinner({status: false, textProcess: null}));
            const statusCode = data.code;
            if (statusCode === 0 || statusCode === 200) {
                alertPopup(setAlertText("success", "แจ้งเตือน", "ทำรายการสำเร็จ"));
                reloadBalance();
            }
            else {
                let snackbarJson = alertText(statusCode);
                alertPopup(setAlertText(snackbarJson.statusAlert, snackbarJson.titleAlert, snackbarJson.textAlert));
            }
        });
    };

    const confirmAction = () => {
        const dataJson = props.confirmData;
        if(dataJson.tab === "shop") {
            requestShop(dataJson.data);
        }
        else if(dataJson.tab === "coupon") {
            requestCoupon(dataJson.data);
        }
        else {
            return null;
        }
    };

    const close = () => {
        const dataJson = props.confirmData;
        if(dataJson.tab === "coupon") {
            let myJson = dataJson.data;

            dispatch(openActivity({
                ...myJson,
                show: true
            }));

            onShow(false);
        }
        else {
            onShow(false);
        }
    };

    return (
        <ModalApp show={props.confirmData.show} onShow={onShow}>
            <div className={[modalStyles.boxTitle, "center"].join(" ")}>
                { props.confirmData.title }
            </div>

            {
                props.confirmData.text !== null && (
                    <>
                        <div className={styles.boxMarginFormTop}>
                            <div className={[styles.confirmText, "center"].join(" ")}>
                                { props.confirmData.text }
                            </div>
                        </div>
                        <br />
                    </>
                )
            }

            <div className={styles.boxMarginFormTop}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <button className={[buttonStyles.btnConfirm, buttonStyles.btnDemo].join(" ")} onClick={() => { close() }}>
                            ยกเลิก
                        </button>
                    </Grid>
                    <Grid item xs={6}>
                        <button className={buttonStyles.btnConfirm} onClick={() => { confirmAction() }}>
                            ยืนยัน
                        </button>
                    </Grid>
                </Grid>
            </div>
        </ModalApp>
    );
}

export default Confirm;
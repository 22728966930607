import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Redux & Action
import { openProfile } from "../../../../@core/redux/actions/profile/index";

// Component
import SnackbarSet from "./SnackbarSet";
import DialogAction from "./DialogAction";
import LoadSpinner from "./LoadSpinner";
import ConditionPopup from "./ConditionPopup";
import Contact from "./Contact";
import ConfirmApp from "./Confirm";
import AlertApp from "./Alert/index";
import LoadSkeletonScreen from "./LoadSkeletonScreen";
import SpinWheel from "./MiniGame/SpinWheel";
import SnackbarApp from "./Alert/Snackbar";

// Page
import AffiateApp from "./../pages/affiate/index";
import InviteApp from "./../pages/invite/index";
import GamePlayPopupApp from "./../pages/game-play-popup/index";

export default function ConfigSet({ loadRoutes, processing, isUserLoggedIn }) {
    const dialogData = useSelector(state => state.reduxAppDialog.dialogData);
    const conditionData = useSelector(state => state.reduxAppCondition.conditionData);
    const contactStatus = useSelector(state => state.reduxAppContact.statusShow);
    const reduxSpinner = useSelector(state => state.reduxAppSpinner.data);
    const confirmData = useSelector(state => state.reduxAppConfirm);
    const alertData = useSelector(state => state.reduxAppAlert);
    const childRef = useRef();
    const affiliate = useSelector(state => state.reduxAffiliate.affiliate);
    const linkFriend = useSelector(state => state.reduxAffiliate.linkFriend);
    const reduxAppWheel = useSelector(state => state.reduxAppWheel.show);
    const statusShow = useSelector(state => state.reduxAppSnackbar.snackbarData.statusShow);
    const popupGameData = useSelector(state => state.reduxGame.popupGameData);
    const homeData = useSelector(state => state.reduxHome.result);
    const dispatch = useDispatch();
    
    const renderAlertBar = () => {
        if (statusShow === false) return null;

        return (
            <SnackbarApp></SnackbarApp>
        );
    };

    useEffect(() => {
        if(homeData !== null) {
            if(homeData?.auth_sms !== undefined && homeData?.auth_sms !== null) {
                if(homeData?.auth_sms === 1 || homeData?.auth_sms === true) {
                    dispatch(openProfile({ tab: "profile", show: true, type: "password", button: false }));
                }
            }
        }
    }, [homeData]);

    return (
        <>
            {
                (loadRoutes === false || processing === false) && <LoadSkeletonScreen></LoadSkeletonScreen>
            }

            {
                renderAlertBar()
            }

            {
                dialogData.statusShow && (
                    <DialogAction dialogData={dialogData}></DialogAction>
                )
            }

            {
                conditionData.statusShow && (
                    <ConditionPopup conditionData={conditionData}></ConditionPopup>
                )
            }

            {
                contactStatus && (<Contact></Contact>)
            }

            {
                processing && (
                    reduxSpinner.status && (
                        <LoadSpinner textProcess={reduxSpinner.textProcess}></LoadSpinner>
                    )
                )
            }

            {
                confirmData.show && (
                    <ConfirmApp confirmData={confirmData}></ConfirmApp>
                )
            }

            {
                alertData.show && (
                    <AlertApp alertData={alertData}></AlertApp>
                )
            }

            {
                reduxAppWheel && (
                    <SpinWheel show={reduxAppWheel}></SpinWheel>
                )
            }

            {
                isUserLoggedIn() && (
                    <>
                        {
                            affiliate.show && (
                                <AffiateApp affiliate={affiliate} />
                            )
                        }

                        {
                            linkFriend.show && (
                                <InviteApp linkFriend={linkFriend} />
                            )
                        }
                    </>
                )
            }

            {
                isUserLoggedIn() && (
                    popupGameData.show && (
                        (popupGameData.camp !== null && popupGameData.gamecode !== null) && (
                            <GamePlayPopupApp popupGameData={popupGameData} isUserLoggedIn={isUserLoggedIn()}></GamePlayPopupApp>
                        )
                    )
                )
            }

            <SnackbarSet ref={childRef} />
        </>
    );
}

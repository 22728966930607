import { useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import BackButton from "./../../../components/BackButton";
import Affiliate from "./Affiliate";
import CliamAction from "./CliamAction";

//  CSS
import modalStyles from "./../../../assets/css/Modal.module.css";

export default function ContentAffliate({ onAffiliate, loadData, reduxAppCMS }) {
    document.title = process.env.REACT_APP_OG_SITE_NAME+ " - รับทรัพย์";
    const [menuSelect, setMenuSelect] = useState(null);
    const [dataArr, setDataArr] = useState(null);
    const affliateData = useSelector(state => state.reduxAffiliate.result);
    // console.log(affliateData)

    // return <Affiliate affliateData={affliateData}></Affiliate>;

    const renderContent = () => {
        if (menuSelect === null) return <Affiliate menuSelect={menuSelect} setMenuSelect={setMenuSelect} setDataArr={setDataArr} reduxAppCMS={reduxAppCMS}></Affiliate>;
        else return <CliamAction menuSelect={menuSelect} setMenuSelect={setMenuSelect} dataArr={dataArr} onAffiliate={onAffiliate} loadData={loadData} reduxAppCMS={reduxAppCMS}></CliamAction>;
    };

    return (
        <>
            {
                menuSelect !== null && (
                    affliateData !== null && (
                        affliateData.length > 1 && (
                            <div className={modalStyles.backButtonFull} onClick={() => { setMenuSelect(null) }}>
                                <BackButton></BackButton>
                            </div>
                        )
                    )
                )
            }
            {
                renderContent()
            }
        </>
    );
}
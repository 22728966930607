import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// @Utility
import setBodyScroll from "./../../../../../../@utility/app/setBodyScroll";

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setOpenWheelStatus, loadWheelSpinData } from "./../../../../../../@core/redux/actions/app/wheel";

// Component
import CloseButton from "../../CloseButton";
import SpinGame from "./SpinGame";
import Reward from "./Reward";

// CSS
import modalStyles from "./../../../assets/css/Modal.module.css";

const SpinWheel = ({ show }) => {
    const dispatch = useDispatch();
    const [rewardContent, setRewardContent] = useState(false);
    const spinDataAll = useSelector(state => state.reduxAppWheel.result);
    const history = useHistory();
    const [conditionJson, setConditionJson] = useState(null);
    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);

    const close = () => {
        setBodyScroll(false);
        dispatch(setOpenWheelStatus(false));
    };

    const gotoPage = () => {
        close();
        history.push("/history?tableType=wheel");
    };

    useEffect(() => {
        if(show) {
            dispatch(loadWheelSpinData(spinDataAll));
            setBodyScroll(true);
        }
        else {
            setBodyScroll(false);
        }
    }, [show]);

    useEffect(() => {
        if(dataSetting !== null) {
            if(dataSetting?.agent_condition !== undefined) {
                let agent_condition = dataSetting.agent_condition;
                let dataFilter = agent_condition.find((item) => ( item.code === "activity_wheel" ));

                if(dataFilter !== undefined) {
                    setConditionJson(dataFilter);
                }
            }
        }
    }, [dataSetting?.agent_condition]);

    return (
        show ?
            <>
                <div className={modalStyles.contentPosition} style={{ zIndex: "1900" }}>
                    <div className={modalStyles.boxBodyFull}>
                        <div className={modalStyles.boxFull}>
                            {
                                rewardContent && (
                                    <div className={modalStyles.backButtonFull} onClick={() => setRewardContent(false)}>
                                        <img className={modalStyles.iconClose} src="/images/game/btn_back_circle.webp" alt=" back" />
                                    </div>
                                )
                            }

                            <div className={modalStyles.closeButtonFull} onClick={close}>
                                <CloseButton></CloseButton>
                            </div>

                            {
                                spinDataAll !== null && (
                                    rewardContent ?
                                    <Reward spinDataAll={spinDataAll}></Reward>
                                    :
                                    <SpinGame spinDataAll={spinDataAll} setRewardContent={setRewardContent} gotoPage={gotoPage} conditionJson={conditionJson}></SpinGame>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className={modalStyles.fixedBackdrop} style={{ zIndex: "1800" }}></div>
            </>
            :
            null
    );
}

export default SpinWheel;
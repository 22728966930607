const SET_SHOP_ALL = 'app/SET_SHOP_ALL';

function setShopAll(data) {
    return {
        type: SET_SHOP_ALL,
        payload: {
            items: data
        }
    }
}

export {
    SET_SHOP_ALL,
    setShopAll
}
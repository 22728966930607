import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// auth
import { isUserLoggedIn } from "./../../../../../../@core/auth/utils";

// Redux
import { openProfile } from "./../../../../../../@core/redux/actions/profile/index";

// @utility
import { setStorage, getStorage, cleatStorage } from "./../../../../../../@utility/app/storage";
import currencyFormat from "./../../../../../../@utility/numbers/currencyFormat";

//  CSS
import styles from "./../../../assets/css/Style.module.css";
import modalStyles from "./../../../assets/css/Modal.module.css";

// Icon
import AddIcon from '@material-ui/icons/Add';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

// Pages
import PasswordApp from "./../../password/index";

// Component
import BackButton from "./../../../components/BackButton";
import ProfitApp from "./Profit";

export default function Profile({ homeData, reduxProfile }) {
    const history = useHistory();
    const [profileStatus, setProfileStatus] = useState(null);
    const dispatch = useDispatch();

    const useStyles = makeStyles((theme) => ({
        boxUserPosition: {
            // background: "#2F0363",
            // border: "3px solid #D4BDFF",
            // padding: "4px 4px",
            // borderRadius: "100%",
            // display: "inline-block"
            padding: "8px"
        },
        userSize: {
            width: "100%",
            height: "auto",
            borderRadius: "100%",
            border: "3px solid #D4BDFF"
        },
        boxProfile: {
            background: "linear-gradient(90deg, #5626A9 0%, #6D00B0 101.11%)",
            borderRadius: "16px",
            padding: "12px 24px",
            color: "white",
            fontSize: "20px",
            [theme.breakpoints.down('xs')]: {
                fontSize: "16px",
                padding: "8px 16px"
            }
        },
        boxLv: {
            background: "#FFFFFF",
            color: "black",
            padding: "2px 18px",
            borderRadius: "16px"
        },
        boxUsername: {
            background: "#FFFFFF",
            color: "#000000",
            padding: "4px",
            borderRadius: "20px",
            fontSize: "18px",
            [theme.breakpoints.down('xs')]: {
                // padding: "4px",
                fontSize: "14px"
            }
        },
        boxLevel: {
            background: "linear-gradient(0deg, #320064, #320064), #FFFFFF",
            color: "white",
            padding: "4px 12px"
        },
        boxCredit: {
            margin: "5px 20px 5px 20px",
            background: "#000000",
            padding: "6px 6px 0px 6px",
            borderRadius: "40px",
            fontSize: "18px",
            cursor: "pointer"
        },
        coinSize: {
            width: "32px",
            height: "auto",
            [theme.breakpoints.down('xs')]: {
                width: "28px"
            }
        },
        iconPlus: {
            width: "34px",
            height: "34px",
            [theme.breakpoints.down('xs')]: {
                width: "28px",
                height: "28px"
            }
        },
        profileMenu: {
            background: "white",
            padding: "12px 0px",
            color: "#140041",
            borderRadius: "12px",
            fontSize: "20px",
            fontWeight: "500",
            position: "relative",
            cursor: "pointer",
            filter: "drop-shadow(0px 10px 0px #12012A)",
            '&:hover': {
                top: "2px",
                filter: "none"
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: "16px"
            }
        },
        profileMenuSize: {
            width: "92px",
            height: "auto",
            [theme.breakpoints.down('xs')]: {
                width: "64px"
            }
        },
        btnLogout: {
            color: "white",
            border: "2px solid white",
            padding: "2px",
            borderRadius: "12px",
            fontSize: "20px",
            position: "relative",
            cursor: "pointer",
            '&:hover': {
                top: "2px",
                background: "white",
                color: "#1a0046"
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: "16px"
            }
        },
        iconLogout: {
            width: "46px",
            height: "46px",
            marginTop: "6px",
            [theme.breakpoints.down('xs')]: {
                width: "32px",
                height: "32px"
            }
        },
        levelText: {
            fontSize: "24px",
            fontWeight: "500",
            [theme.breakpoints.down('xs')]: {
                fontSize: "16px"
            }
        },
        boxUserLevelPosition: {
            position: "absolute",
            right: "16px",
            top: "-24px",
            [theme.breakpoints.down('xs')]: {
                top: "-20px"
            }
        },
        userLevelSize: {
            width: "68px",
            height: "68px",
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
            [theme.breakpoints.down('xs')]: {
                width: "48px",
                height: "48px"
            }
        }
    }));

    const classes = useStyles();

    const gotoPage = (page) => {
        if (page === "/logout") {
            let market = getStorage("market")
            cleatStorage();
            setStorage("market", market)
            window.location.reload();
        }
        else {
            dispatch(openProfile({
                tab: "profile",
                show: false
            }));

            history.push(page);
        }
    };

    const renderProfile = () => {
        return (
            <>
                <div className={[modalStyles.boxTitle, "center"].join(" ")}>
                    โปรไฟล์
                </div>

                <div className={styles.boxMarginFormTop}>
                    <div className={classes.boxProfile}>
                        <div className="relative">
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item xs={4}>
                                    <div className="center">
                                        <div className={classes.boxUserPosition}>
                                            {
                                                isUserLoggedIn() ?
                                                    <img className={classes.userSize} src="/images/user/01.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                    :
                                                    <img className={classes.userSize} src="/images/user/not_active.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                            }
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={8}>
                                    <div className={classes.levelText}>
                                        ระดับ{homeData !== null && (homeData.levels.name)}
                                    </div>

                                    <div className={[styles.boxMarginTop, classes.boxUsername, classes.boxLevel, "center"].join(" ")}>
                                        <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
                                            <Grid item>
                                                <span style={{ color: "#FFD600" }}>
                                                    เติมเงิน
                                                </span>
                                            </Grid>
                                            <Grid item>
                                                <span style={{ fontSize: "12px" }}>
                                                    {homeData !== null && `${homeData.exp_deposit}`} / <span style={{ color: "#FFD600", fontWeight: "500" }}>{homeData !== null && `${homeData.levels.exp_deposit}`}</span>
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className={[styles.boxMarginTop, classes.boxUsername, classes.boxLevel, "center"].join(" ")}>
                                        <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
                                            <Grid item>
                                                <span style={{ color: "#00FFF0" }}>
                                                    เทิร์นโอเวอร์
                                                </span>
                                            </Grid>
                                            <Grid item>
                                                <span style={{ fontSize: "12px" }}>
                                                    {homeData !== null && `${homeData.exp_turnover}`} / <span style={{ color: "#00FFF0", fontWeight: "500" }}>{homeData !== null && `${homeData.levels.exp_turnover}`}</span>
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className={[styles.boxMarginTop, classes.boxUsername, "center"].join(" ")}>
                                        {homeData !== null && (homeData.username)}
                                    </div>
                                </Grid>
                            </Grid>

                            <div className={classes.boxUserLevelPosition}>
                                <img className={classes.userLevelSize} src={homeData !== null && (homeData.levels.image)} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.boxMarginTop}>
                    <div className={classes.boxProfile}>
                        <Grid container alignItems="center">
                            <Grid item xs={2} className="center">
                                เครดิต
                            </Grid>
                            <Grid item xs={10}>
                                <div className={classes.boxCredit} onClick={() => { gotoPage("/deposit/form/auto") }}>
                                    <Grid container alignItems="center" className="center">
                                        <Grid item xs={2} className="left">
                                            <img className={classes.coinSize} src="/images/icon/coin.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                        </Grid>
                                        <Grid item xs={8}>
                                            {
                                                homeData !== null && currencyFormat(homeData.balance, 2)
                                            }
                                        </Grid>
                                        <Grid item xs={2}>
                                            <AddIcon className={classes.iconPlus}></AddIcon>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        {
                            homeData !== null && (
                                homeData.wallets !== null && (
                                    <Grid container alignItems="center">
                                        <Grid item xs={2} className="center">
                                            เพชร
                                        </Grid>
                                        <Grid item xs={10}>
                                            <div className={classes.boxCredit} onClick={() => { gotoPage("/activity") }}>
                                                <Grid container alignItems="center" className="center">
                                                    <Grid item xs={2} className="left">
                                                        <img className={classes.coinSize} src={homeData.wallets[0].image} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {
                                                            currencyFormat(homeData.wallets[0].balance, 2)
                                                        }
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <AddIcon className={classes.iconPlus}></AddIcon>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                )
                            )
                        }
                    </div>
                </div>

                <div className={styles.boxMarginFormTop}>
                    <Grid container alignItems="center" className="center" spacing={2}>
                        <Grid item xs={6}>
                            <div className={classes.profileMenu} onClick={() => { gotoPage("/history?page_back=profile") }}>
                                <img className={classes.profileMenuSize} src="/images/icon/hisroty.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                <br />
                                ประวัติ
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.profileMenu} onClick={() => { setProfileStatus("profit") }}>
                                <img className={classes.profileMenuSize} src="/images/icon/reward.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                <br />
                                ระดับรางวัล
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.profileMenu} onClick={() => { gotoPage("/bank?page_back=profile") }}>
                                <img className={classes.profileMenuSize} src="/images/icon/bank.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                <br />
                                บัญชีธนาคาร
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.profileMenu} onClick={() => { setProfileStatus("password") }}>
                                <img className={classes.profileMenuSize} src="/images/icon/password.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                <br />
                                เปลี่ยนรหัสผ่าน
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <div className={styles.boxMarginFormTop}>
                    <div className={[classes.btnLogout, "center"].join(" ")} onClick={() => { gotoPage("/logout") }}>
                        <Grid container alignItems="center" justifyContent="center" spacing={2}>
                            <Grid item>
                                <ExitToAppIcon className={classes.iconLogout}></ExitToAppIcon>
                            </Grid>
                            <Grid item>
                                ออกจากระบบ
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </>
        );
    };

    const renderSelectPage = () => {
        switch (profileStatus) {
            case "password":
                return <PasswordApp></PasswordApp>;
            case "profit":
                return <ProfitApp modalStyles={modalStyles}></ProfitApp>;
            default:
                return null;
        }
    };

    useEffect(() => {
        if (reduxProfile.type === "password") {
            setProfileStatus("password");
        }
    }, [reduxProfile]);

    return (
        <>
            {
                profileStatus === null ?
                    renderProfile()
                    :
                    <>
                        {
                            reduxProfile.button && (
                                <div className={modalStyles.backButton} onClick={() => { setProfileStatus(null) }}>
                                    <BackButton></BackButton>
                                </div>
                            )
                        }

                        {
                            renderSelectPage()
                        }
                    </>
            }
        </>
    );
}
// import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

// Redux
import { setDataAlert } from "./../../../../@core/redux/actions/app/alert";
// import { openProfile } from "./../../../../@core/redux/actions/profile/index";
import { openGetCredit, openInviteFriend } from "./../../../../@core/redux/actions/affiliate/index";
import { setHomeMenuType } from "./../../../../@core/redux/actions/home/index";
import { setOpenWheelStatus } from "./../../../../@core/redux/actions/app/wheel";
// Hooks
import useParameterUrl from "./../../../../@utility/hooks/useParameterUrl";

// Component
// import AffiateApp from "./../pages/affiate/index";
// import LinkFriendApp from "./LinkFriend";
// import NavbarCredit from "./NavbarCredit";
import Sidebar from "./Sidebar";
import NavbarPromotion from "./NavbarPromotion";

// CSS
import styles from "./../assets/css/Style.module.css";

export default function NavbarMenu({ isUserLoggedIn, isLargeScreen }) {
    const homeData = useSelector(state => state.reduxHome.result);
    const { path } = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);
    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    const reduxAppScrollPosition = useSelector(state => state.reduxAppScrollPosition.top);
    // const [affiliate, setAffiliate] = useState({
    //     tab: "affiliate",
    //     show: false
    // });
    // const [linkFriend, setLinkFriend] = useState({
    //     show: false
    // });

    const parsed = useParameterUrl();
    const { play_game } = parsed;

    const useStyles = makeStyles((theme) => ({
        narbarPosition: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-confirm"] : "#380170",
            padding: "10px 10px 0px 10px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            [theme.breakpoints.down('xs')]: {
                padding: "6px 10px"
            }
        },
        menuList: {
            fontSize: "16px",
            cursor: "pointer",
            width: "140px",
            [theme.breakpoints.down('xs')]: {
                width: "72px"
            }
        },
        boxFlexCenter: {
            width: "100%",
            justifyContent: "center",
            [theme.breakpoints.down('sm')]: {
                justifyContent: "flex-start"
            }
        },
        menuListSize: {
            width: "46px",
            [theme.breakpoints.down('xs')]: {
                width: "32px"
            }
        },
        menuListText: {
            fontSize: "16px",
            fontWeight: "600",
            marginTop: "4px",
            color: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "white",
            [theme.breakpoints.down('xs')]: {
                marginTop: "0px",
                fontSize: "13px"
            }
        },
        boxProfile: {
            // width: "240px",
            borderRight: `1.5px solid ${reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "#A954FF"}`,
            padding: "0px 40px 0px 0px",
            cursor: "pointer",
            [theme.breakpoints.down('sm')]: {
                width: "240px",
                padding: "0px 0px 0px 0px"
            },
            [theme.breakpoints.down('xs')]: {
                width: "120px",
                padding: "0px 0px 0px 0px"
            }
        },
        userSize: {
            width: "56px",
            height: "56px",
            // background: "#2F0363",
            border: `3px solid ${reduxAppCMS !== null ? reduxAppCMS["bg-main"] : "white"}`,
            borderRadius: "100%",
            [theme.breakpoints.down('sm')]: {
                width: "72px",
                height: "72px"
            },
            [theme.breakpoints.down('xs')]: {
                width: "36px",
                height: "36px"
            }
        },
        userLevelSize: {
            width: "46px",
            height: "46px",
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
            [theme.breakpoints.down('sm')]: {
                width: "36px",
                height: "36px"
            },
            [theme.breakpoints.down('xs')]: {
                width: "28px",
                height: "28px"
            }
        },
        usernameText: {
            fontSize: "18px",
            fontWeight: "600",
            paddingLeft: "8px",
            width: "90%",
            display: "inline-block",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            color: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "white",
            [theme.breakpoints.down('xs')]: {
                fontSize: "14px",
                paddingLeft: "6px"
            }
        },
        levelText: {
            fontSize: "16px",
            fontWeight: "600",
            paddingLeft: "8px",
            color: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "white",
            [theme.breakpoints.down('xs')]: {
                fontSize: "12px",
                paddingLeft: "6px"
            }
        },
        levelMargin: {
            [theme.breakpoints.down('xs')]: {
                marginTop: "-4px"
            }
        }
    }));

    const classes = useStyles();

    const gotoPage = (item) => {
        // if (page === "/affiate") {
        //     if (isUserLoggedIn) {
        //         openAffliate();
        //     }
        //     else {
        //         openBox(pageName);
        //     }
        // }
        // else if (page === "/friend") {
        //     if (isUserLoggedIn) {
        //         openFriend();
        //     }
        //     else {
        //         openBox(pageName);
        //     }
        // }
        // else if (page === "/profile") {
        //     if (isUserLoggedIn) {
        //         setOpenProfile();
        //     }
        //     else {
        //         openBox(pageName);
        //     }
        // }
        // else if (page === "/bank" || page === "/games/last" || page === "/history" || page === "/activity" || page === "/shop") {
        //     if (isUserLoggedIn) {
        //         history.push(page);
        //     }
        //     else {
        //         openBox(pageName);
        //     }
        // }
        // else {
        //     history.push(page);
        // }
        let page = item.link;
        let typeAction = item.link_type;

        if(typeAction === 4) {
            if (page === "affiliate") {
                if(isUserLoggedIn === false) {
                    openBox("รับทรัพย์");
                }
                else {
                    dispatch(openGetCredit({
                        tab: "affiliate",
                        show: true,
                        type: null
                    }));
                }
            }
            else if (page === "inviteFrined") {
                if(isUserLoggedIn === false) {
                    openBox("เชิญเพื่อน");
                }
                else {
                    dispatch(openInviteFriend({
                        show: true
                    }));
                }
            }
            else if (page === "wheelSpin") {
                if(isUserLoggedIn === false) {
                    openBox("กงล้อลุ้นโชค");
                }
                else {
                    dispatch(setOpenWheelStatus(true));
                }
            }
        }
        else if(typeAction === 3) {
            let menuList = dataSetting.menu_camp;
            let dataObj = {};

            let index = menuList.findIndex(item => item.code === page);
            dataObj = menuList[index];
            dataObj.id = index;

            if(dataObj !== undefined && dataObj !== null) {
                dispatch(setHomeMenuType({
                    id: dataObj.id,
                    renderPage: dataObj.code,
                    pageTitle: dataObj.title,
                    game_camp_image: dataObj.game_camp_image,
                    column: {
                        desktop: dataObj.column_desktop,
                        mobile: dataObj.column_mobile
                    },
                    typeGame: dataObj.game_camp_type
                }));

                window.scrollTo({
                    top: reduxAppScrollPosition,
                    left: 0,
                    behavior: "smooth"
                });        
            }
        }
        else if(typeAction === 1) {
            window.location.href = page;
        }
        else {
            history.push(page);
        }
    };

    // const openAffliate = () => {
    //     dispatch(openGetCredit({
    //         tab: "affiliate",
    //         show: true,
    //         type: null
    //     }));
    // };

    // const openFriend = () => {
    //     dispatch(openInviteFriend({
    //         show: true
    //     }));
    // };

    // const setOpenProfile = () => {
    //     dispatch(openProfile({ tab: "profile", show: true }));
    // };

    const openBox = (page) => {
        dispatch(setDataAlert({
            type: "warning",
            title: "แจ้งเตือน",
            text: `กรุณาเข้าสู่ระบบก่อนเข้าใช้งาน "${page}"`,
            action: true,
            redirect: "/login",
            show: true,
            buttonText: "ตกลง"
        }));
    };

    return (
        (play_game !== "true" && play_game !== true) && (
            <>
                {
                    isUserLoggedIn && (
                        <>
                            {/* <NavbarCredit isLargeScreen={isLargeScreen}></NavbarCredit> */}
                            {
                                homeData !== null && (
                                    homeData.promotion_status === 1 && (
                                        <NavbarPromotion></NavbarPromotion>
                                    )
                                )
                            }
                        </>
                    )
                }
                
                <div className={classes.narbarPosition}>
                    <Container maxWidth="lg" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                        <div className={[styles.menuPositionWidthScroll, styles.hideScrollbar].join(" ")} style={{ marginLeft: "0px", marginRight: "0px" }}>
                            <div className={["flexContent", classes.boxFlexCenter].join(" ")} style={{ alignItems: "center" }}>
                                <div>
                                    <Sidebar homeData={homeData} isUserLoggedIn={isUserLoggedIn} showChildren={true}>
                                        <div className={[classes.boxProfile, "left"].join(" ")}>
                                            <Grid container alignItems="center" spacing={isLargeScreen ? 2 : 1}>
                                                <Grid item xs={4} className="center">
                                                    <img className={classes.userSize} src="/images/user/profile-all.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                    {/* {
                                                        isUserLoggedIn ?
                                                            <img className={classes.userSize} src="/images/user/01.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                            :
                                                            <img className={classes.userSize} src="/images/user/not_active.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                    } */}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <div className={classes.usernameText}>
                                                        {
                                                            homeData !== null ? homeData.username : "บัญชีนักท่องเที่ยว"
                                                        }
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            {/* <div className={classes.levelMargin}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={4} className="center">
                                                        {
                                                            isUserLoggedIn ?
                                                                <img className={classes.userLevelSize} src={homeData !== null ? homeData.levels.image : ""} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                                :
                                                                <img className={classes.userLevelSize} src="/images/game/level/00.png" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                        }
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <div className={classes.levelText} style={{ color: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "#D1C4E9" }}>
                                                            ระดับสมาชิก
                                                        </div>
                                                        <div className={classes.levelText}>
                                                            {
                                                                homeData !== null && (homeData.levels.name)
                                                            }
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div> */}
                                        </div>
                                    </Sidebar>
                                </div>
                                {
                                    isUserLoggedIn && (
                                        dataSetting !== null && (
                                            dataSetting.menu_second !== null && (
                                                dataSetting.menu_second.map((item) => {
                                                    return (
                                                        <div key={item.id}>
                                                            <div className={[classes.menuList, "center"].join(" ")} onClick={() => { gotoPage(item) }}>
                                                                <img className={classes.menuListSize} src={item.icon} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                                <div className={classes.menuListText}>
                                                                    {
                                                                        item.name
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            )
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </Container>
                </div>
            </>
        )
    );
}
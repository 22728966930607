import axios from 'axios';
import serviceConfig from "./serviceConfig";
import { getToken } from "./../auth/utils";
import { checkStatusCode } from "./services";

const svConfig = { ...serviceConfig };

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const loadHomeData = async () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
  let config = {
    method: svConfig.method_get,
    url: '/customer/profile',
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    return {
      code,
      result
    };
  }
}

export {
  loadHomeData
};
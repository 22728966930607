import { 
    loadDataAll,
    loadHistory
} from "./../../../services/affiliateService";
import { setLoadProcessing } from "./../app/processing";
import { setStatusSpinner } from "./../app/spinner";

const SET_AFFLIATE_DATA = 'app/SET_AFFLIATE_DATA';
const OPEN_GET_CREDIT = 'app/OPEN_GET_CREDIT';
const OPEN_INVITE_FRIEND = 'app/OPEN_INVITE_FRIEND';
const LOAD_DATA_INVITE_ALL = 'app/LOAD_DATA_INVITE_ALL';
const LOAD_MEMBER_INVITE = 'app/LOAD_MEMBER_INVITE';
const LOAD_CLIAM_INVITE = 'app/LOAD_CLIAM_INVITE';

function setAffiliateData(data) {
    return {
        type: SET_AFFLIATE_DATA,
        payload: {
            items: data
        }
    }
}

function openGetCredit(data) {
    return {
        type: OPEN_GET_CREDIT,
        payload: {
            items: data
        }
    }
}

function openInviteFriend(data) {
    return {
        type: OPEN_INVITE_FRIEND,
        payload: {
            items: data
        }
    }
}

function loadDataInviteAll() {
    return dispatch => {
        dispatch(setLoadProcessing(true));
        
        loadDataAll("/customer/affiliate/data").then((data) => {
            const statusCode = data.code;
            dispatch(setLoadProcessing(false));
            dispatch(setStatusSpinner({status: false, textProcess: null}));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_DATA_INVITE_ALL,
                    payload: {
                        items: data.result
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_DATA_INVITE_ALL,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
};

function loadMemberInvite(limit = -1, offset = 0) {
    return dispatch => {
        dispatch(setLoadProcessing(true));
        
        loadHistory("/customer/affiliate/member", limit, offset).then((data) => {
            const statusCode = data.code;
            dispatch(setLoadProcessing(false));
            dispatch(setStatusSpinner({status: false, textProcess: null}));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_MEMBER_INVITE,
                    payload: {
                        items: data.result
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_MEMBER_INVITE,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
};

function loadCliamInvite(limit = -1, offset = 0) {
    return dispatch => {
        dispatch(setLoadProcessing(true));
        
        loadHistory("/customer/affiliate/history", limit, offset).then((data) => {
            const statusCode = data.code;
            dispatch(setLoadProcessing(false));
            dispatch(setStatusSpinner({status: false, textProcess: null}));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_CLIAM_INVITE,
                    payload: {
                        items: data.result
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_CLIAM_INVITE,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
};

export {
    SET_AFFLIATE_DATA,
    OPEN_GET_CREDIT,
    OPEN_INVITE_FRIEND,
    LOAD_DATA_INVITE_ALL,
    LOAD_MEMBER_INVITE,
    LOAD_CLIAM_INVITE,
    setAffiliateData,
    openGetCredit,
    openInviteFriend,
    loadDataInviteAll,
    loadMemberInvite,
    loadCliamInvite
}
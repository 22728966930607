import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

// @Utility
import timeZone from "./../../../../../../../@utility/app/timeZone";
import currencyFormat from "./../../../../../../../@utility/numbers/currencyFormat";

// Redux
import { loadMemberInvite } from "./../../../../../../../@core/redux/actions/affiliate/index";
import { setStatusSpinner } from "./../../../../../../../@core/redux/actions/app/spinner";

export default function ModeTwoCustomer({ reduxAppCMS }) {
    const dispatch = useDispatch();
    const reduxData = useSelector(state => state.reduxAffiliate.memberData);
    const [memberData, setMemberData] = useState(null);

    const useStyles = makeStyles((theme) => ({
        tableSize: {
            width: "100%",
            borderSpacing: "0px",
            fontSize: "14px"
        },
        tableTextHead: {
            fontSize: "16px",
            fontWeight: "400 !important",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-warning"] : "white",
            borderBottom: `1px solid ${reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white"}`,
            paddingBottom: "8px"
        },
        rowList: {
            padding: "8px 0px",
            borderBottom: `1px solid ${reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white"}`
        },
        dataNonePadding: {
            textAlign: "center",
            padding: "40px 80px",
            fontSize: "16px",
            color: "rgb(255, 193, 7)",
            [theme.breakpoints.down('sm')]: {
                padding: "40px 60px"
            }
        },
        textNone: {
            fontSize: "18px",
            color: "white",
            marginBottom: "30px"
        },
        reportNone: {
            width: "100%"
        },
        textColorGreen: {
            fontSize: "16px",
            fontWeight: "600",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-sucess"] : "white"
        },
        textColorWarning: {
            fontSize: "16px",
            fontWeight: "600",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-warning"] : "white",
        },
        btnFooter: {
            textAlign: "center",
            padding: "12px 24px",
            borderRadius: "12px",
            fontSize: "18px",
            cursor: "pointer",
            color: "white",
            border: "2px solid #FFFFFF",
            background: "transparent",
            position: "relative",
            '&:hover': {
                top: "2px",
                opacity: "0.8"
            }
        }
    }));

    const classes = useStyles();

    const loadMore = () => {
        dispatch(setStatusSpinner({status: true, textProcess: null}));
        dispatch(loadMemberInvite((memberData.length + 10), 0));
    };

    useEffect(() => {
        if(reduxData) {
            setMemberData(reduxData);
        }
    }, [dispatch, reduxData]);

    return (
        memberData !== null && (
            <>
                <table className={classes.tableSize}>
                    <thead>
                        <tr className="center">
                            <th className={[classes.tableTextHead, "left"].join(" ")}>วันที่สมัคร</th>
                            <th className={[classes.tableTextHead, "left"].join(" ")}>ยูสเซอร์เนม</th>
                            {/* <th className={[classes.tableTextHead, "right"].join(" ")}>การเล่นทั้งหมด</th> */}
                            <th className={[classes.tableTextHead, "right"].join(" ")}>ยอดที่ได้รับ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            memberData.length > 0 ?
                            memberData.map((item, index) => (
                                <tr key={index}>
                                    <td className={[classes.rowList, "left"].join(" ")}>{timeZone(item.time_create)}</td>
                                    <td className={[classes.rowList, "left"].join(" ")}>{item.username}</td>
                                    {/* <td className={[classes.rowList, "right"].join(" ")}><span className={classes.textColorWarning}>{item.quantity}</span> บาท</td> */}
                                    <td className={[classes.rowList, "right"].join(" ")}><span className={classes.textColorGreen}>+{currencyFormat((item.balance), 2)}</span> บาท</td>
                                </tr>
                            ))
                            :
                            <tr>
                                <td colSpan={12}>
                                    <div className={classes.dataNonePadding}>
                                        <div className={classes.textNone}>
                                            ไม่มีข้อมูล
                                        </div>
                                        <img src={"/images/game/no_game.webp"} className={classes.reportNone} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>

                {
                    memberData.length >= 10 && (
                        <>
                            <br /><br />
                            <div className={classes.btnFooter} onClick={loadMore}>
                                โหลดข้อมูลเพิ่มเติม
                            </div>
                        </>
                    )
                }
            </>
        )
    );
}
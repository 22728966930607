// import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
// import Slider from 'react-slick';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import Skeleton from '@material-ui/lab/Skeleton';

export default function LoadSkeletonScreen() {
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);

    const useStyles = makeStyles((theme) => ({
        contentPosition: {
            position: "fixed",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            background: "rgb(7, 9, 15)",
            // zIndex: "2000",
            overflowY: "scroll"
        },
        cardOther: {
            [theme.breakpoints.down('xs')]: {
                paddingLeft: "6px",
                paddingRight: "6px"
            }
        },
        homeList: {
            borderRadius: "10px",
            padding: "40px 0px",
            marginBottom: "10px",
            background: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "#1E2330",
            [theme.breakpoints.down('xs')]: {
                padding: "20px 0px"
            }
        },
        homeToolbar: {
            height: "8vh",
            background: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "#1E2330",
            marginBottom: "10px",
            [theme.breakpoints.down('xs')]: {
                height: "8vh"
            }
        },
        homeBanner: {
            height: "50vh",
            background: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "#1E2330",
            marginBottom: "10px",
            [theme.breakpoints.down('xs')]: {
                marginBottom: "0px",
                height: "20vh"
            }
        },
        homeRight: {
            height: "40vh",
            background: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "#1E2330",
            marginBottom: "10px",
            borderRadius: "10px",
            [theme.breakpoints.down('xs')]: {
                height: "20vh"
            }
        },
        homeCardOther: {
            height: "28vh",
            background: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "#1E2330",
            marginBottom: "10px",
            borderRadius: "10px",
            [theme.breakpoints.down('xs')]: {
                borderRadius: "0px"
            }
        },
        homeCardLast: {
            height: "60vh",
        }
    }));

    const classes = useStyles();

    return (
        <>
            <div className={classes.contentPosition}>
                <Skeleton animation="wave" variant="rect" className={classes.homeToolbar} />
                <Skeleton animation="wave" variant="rect" className={classes.homeBanner} />
                <Container maxWidth="lg" className="bgContainer">
                    <div className="boxGame">
                        <div className={classes.cardOther}>
                            <Grid container spacing={1}>
                                <Grid item xs={3} md={2}>
                                    <Skeleton animation="wave" variant="rect" className={classes.homeList} />
                                    <Skeleton animation="wave" variant="rect" className={classes.homeList} />
                                    <Skeleton animation="wave" variant="rect" className={classes.homeList} />
                                    <Skeleton animation="wave" variant="rect" className={classes.homeList} />
                                    <Skeleton animation="wave" variant="rect" className={classes.homeList} />
                                </Grid>
                                <Grid item xs={9} md={10}>
                                    <Skeleton animation="wave" variant="rect" className={classes.homeRight} />
                                    <Skeleton animation="wave" variant="rect" className={classes.homeRight} />
                                </Grid>
                            </Grid>
                        </div>

                        <div className="cardMargin relative">
                            <Skeleton animation="wave" variant="rect" className={classes.homeCardOther} />
                        </div>
                        <div className="cardMargin relative">
                            <Skeleton animation="wave" variant="rect" className={classes.homeCardOther} />
                        </div>
                        <div className="cardMargin relative">
                            <Skeleton animation="wave" variant="rect" className={[classes.homeCardOther, classes.homeCardLast].join(" ")} />
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
}
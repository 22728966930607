import { lazy } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

// Routes page
const ErrorTokenApp = lazy(() => import("./page/token/index"));

export default function Routes() {
    const { path } = useRouteMatch(); // useRouteMatch สามารถเปลี่ยนได้แบบ Dynamic
    
    return (
        <Switch>
            <Route path={`${path}/token`} exact>
                <ErrorTokenApp></ErrorTokenApp>
            </Route>
            <Route path={`${path}/server`} exact>
                <div>test</div>
            </Route>
        </Switch>
    )
};
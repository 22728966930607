import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';

// @utility
import loadImagesFetch from "./../../../../../../@utility/app/loadImagesFetch";

// Redux
import { setHomeMenuType } from "./../../../../../../@core/redux/actions/home/index";
import { setDataPosition } from "./../../../../../../@core/redux/actions/app/scrollPosition";
import { setLoadImages } from "./../../../../../../@core/redux/actions/app/processing";

// Component
import ListGame from "./ListGame";
import ListRank from "./ListRank";
import ListRankWithdraw from "./ListRankWithdraw";
import ListPromotion from './ListPromotion';
import ListEntertain from './ListEntertain';
import ListUserRank from '../../iframe-rank/component/ListUserRank';
import MenuHomeContent from "./Menu/Home";
import MenuOtherContent from "./Menu/Other";

// CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

export default function ContentHome({ processing, loadComponent, setLoadComponent, dataCampAll }) {
    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    const dataMenu = useSelector(state => state.reduxHome.menu);
    const homeProcessing = useSelector(state => state.reduxAppProcessing.home);
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const dispatch = useDispatch();
    const gameListRef = useRef();
    const scrollRef = useRef();
    const [value, setValue] = useState(0);
    const [menuList, setMenuList] = useState(null);
    const history = useHistory();
    const promotionData = useSelector(state => state.reduxPromotion.promotionItem);
    const imagesProcessing = useSelector(state => state.reduxAppProcessing.images);

    const useStyles = makeStyles((theme) => ({
        contentPosition: {
            width: "100%",
            height: "100%"
        },
        cardBody: {
            background: reduxAppCMS !== null ? reduxAppCMS["bg-foter"] : "black"
        },
        searchInput: {
            width: "90%",
            background: "transparent",
            border: "0px",
            padding: "6px 0px",
            fontSize: "16px",
            color: "white"
        },
        iconSize: {
            marginTop: "6px",
            width: "36px",
            height: "36px",
            color: "white"
        },
        cardOther: {
            [theme.breakpoints.down('xs')]: {
                paddingLeft: "3px",
                paddingRight: "6px"
            }
        },
        heightMenu: {
            height: "100%"
        },
        tabs: {
            height: "930px",
            [theme.breakpoints.down('xs')]: {
                height: "464px",
            }
        },
        widthMenuLeft: {
            width: "15%",
            [theme.breakpoints.down('sm')]: {
                width: "22%"
            }
        },
        widthMenuRight: {
            width: "85%",
            [theme.breakpoints.down('sm')]: {
                width: "78%"
            }
        }
    }));

    const classes = useStyles();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const selectPageHome = (item, index) => {
        if (item.code === "lottery") {
            let dataCampLottery = dataCampAll.find((item) => item.category === 6);
            if (dataCampLottery !== undefined) {
                let urlOpen = `/games/camp/${dataCampLottery.code}/${dataCampLottery.game_code}?play_game=true&redirect=home`;
                history.push(urlOpen);
            }

        }
        else {
            let offsetTopData = scrollRef?.current?.offsetTop;
            let positionTop = (offsetTopData === undefined ? 0 : offsetTopData) - 100;
            dispatch(setDataPosition(positionTop));

            window.scrollTo({
                top: positionTop,
                left: 0,
                behavior: "smooth"
            });

            dispatch(setHomeMenuType({
                id: index,
                renderPage: item.code,
                pageTitle: item.title,
                game_camp_image: item.game_camp_image,
                column: {
                    desktop: item.column_desktop,
                    mobile: item.column_mobile
                },
                typeGame: item.game_camp_type
            }));
        }
    };

    const renderPageTabs = () => {
        if (dataMenu.renderPage === "home") {
            return <MenuHomeContent></MenuHomeContent>;
        }
        else {
            return <MenuOtherContent dataCampAll={dataCampAll} isLargeScreen={isLargeScreen}></MenuOtherContent>;
        }
    };

    useEffect(() => {
        if (processing) {
            if (loadComponent === false) {
                setLoadComponent(true);
            }
        }
    }, [processing]);

    useEffect(() => {
        if (homeProcessing) {
            setTimeout(() => {
                let statusLoad = loadImagesFetch(2);
                if (statusLoad) {
                    if (scrollRef.current?.offsetTop !== undefined) {
                        let positionTop = (scrollRef.current?.offsetTop) - 100;
                        dispatch(setDataPosition(positionTop));

                        dispatch(setLoadImages(statusLoad));
                    }
                }
            }, 500);
        }
    }, [homeProcessing]);

    useEffect(() => {
        if (dataSetting.menu_camp !== null) {
            let menuArr = dataSetting.menu_camp;
            if (menuArr.length > 0) {
                if (dataMenu === null) {
                    let item = menuArr[0];
                    dispatch(setHomeMenuType({
                        id: 0,
                        renderPage: item.code,
                        pageTitle: item.title,
                        game_camp_image: item.game_camp_image,
                        column: {
                            desktop: item.column_desktop,
                            mobile: item.column_mobile
                        },
                        typeGame: item.game_camp_type
                    }));
                }

                setMenuList(menuArr);
            }
        }
    }, [dataSetting.menu_camp, dataMenu]);

    useEffect(() => {
        if (dataMenu !== null) {
            setValue(dataMenu.id);
        }
    }, [dataMenu]);

    return (
        <>
            <div className={classes.contentPosition}>
                <div className="boxGame">
                    <div className={classes.cardBody} ref={scrollRef}>
                        <div className={classes.cardOther}>
                            <Grid container spacing={isLargeScreen ? 2 : 1}>
                                <Grid item className={[classes.heightMenu, classes.widthMenuLeft].join(" ")}>
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={value}
                                        onChange={handleChange}
                                        className={classes.tabs}
                                    >
                                        {
                                            (menuList !== null && menuList !== undefined) && (
                                                menuList.map((item, index) => {
                                                    return (
                                                        <LinkTab key={index} label={item.name} {...a11yProps(index)} onClick={() => selectPageHome(item, index)} />
                                                    )
                                                })
                                            )
                                        }
                                    </Tabs>
                                </Grid>
                                <Grid item className={classes.widthMenuRight}>
                                    <div className={classes.menuContent}>
                                        {
                                            dataMenu !== null && (
                                                renderPageTabs()
                                            )
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                        {
                            homeProcessing && (
                                imagesProcessing && (
                                    <>
                                        {
                                            reduxAppCMS !== null && (
                                                <>
                                                    <br />
                                                    {
                                                        (reduxAppCMS?.section_games_rank === "1" || reduxAppCMS?.section_games_rank === 1) && (
                                                            <div className="cardMargin relative" ref={gameListRef}>
                                                                <div className="left">
                                                                    <ListRank pageName="10 อันดับยอดนิยมประจำวัน"></ListRank>
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        (reduxAppCMS?.section_casino === "1" || reduxAppCMS?.section_casino === 1) && (
                                                            <div className="cardMargin relative">
                                                                <div className="left">
                                                                    <ListGame pageType="casino" pageName="โต๊ะบาคาร่า"></ListGame>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        (reduxAppCMS?.section_promoiton === "1" || reduxAppCMS?.section_promoiton === 1) && (
                                                            <div className="cardMargin relative">
                                                                <div className="left">
                                                                    <ListPromotion pageName="โปรโมชั่น" promotionData={promotionData}></ListPromotion>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        (reduxAppCMS?.section_user_rank === "1" || reduxAppCMS?.section_user_rank === 1) && (
                                                            <div className="cardMargin relative">
                                                                <div className="left">
                                                                    <ListUserRank pageName="การจัดอันดับรางวัล"></ListUserRank>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        (reduxAppCMS?.section_withdraw_rank === "1" || reduxAppCMS?.section_withdraw_rank === 1) && (
                                                            <div className="cardMargin cardMarginOther relative">
                                                                <div className="left">
                                                                    <ListRankWithdraw pageName="รายงานผลอันดับการถอนเงิน"></ListRankWithdraw>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        (reduxAppCMS?.section_entertainment === "1" || reduxAppCMS?.section_entertainment === 1) && (
                                                            <div className="cardMargin relative">
                                                                <div className="left">
                                                                    <ListEntertain pageName="ความบันเทิงเต็มรูปแบบ"></ListEntertain>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </>
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
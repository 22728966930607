import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

// Routes page
import DepositMenuApp from "./page/menu/index";
import DepositFormApp from "./page/form-action/index";
import DepositPendingApp from "./page/pending/index";
import DepositFormSlipApp from "./page/form-slip";

export default function Routes({ isUserLoggedIn, depositItems, reduxAppCMS, loadBankDeposit, loadBank }) {
    const { path } = useRouteMatch(); // useRouteMatch สามารถเปลี่ยนได้แบบ Dynamic

    const renderHomeRoutes = () => {
        return (
            <Switch>
                <Route path={`${path}/form/slip`} exact>
                    <DepositFormSlipApp depositItems={depositItems} isUserLoggedIn={isUserLoggedIn}></DepositFormSlipApp>
                </Route>
                <Route path={`${path}/form/:type`} exact>
                    <DepositFormApp depositItems={depositItems}></DepositFormApp>
                </Route>
                <Route path={`${path}/home`} exact>
                    <DepositMenuApp reduxAppCMS={reduxAppCMS} loadBankDeposit={loadBankDeposit} loadBank={loadBank}></DepositMenuApp>
                </Route>
                <Route path="*" >
                    <Redirect to="/deposit/home" />
                </Route>
            </Switch>
        );
    };

    const renderPayPendingRoutes = () => {
        return (
            <Switch>
                <Route path={`${path}/pending/auto`} exact>
                    <DepositPendingApp depositItems={depositItems}></DepositPendingApp>
                </Route>
                <Route path="*" >
                    <Redirect to="/deposit/pending/auto" />
                </Route>
            </Switch>
        );
    };

    const renderFormRoutes = (type) => {
        let typePath = type === "bank" ? type : depositItems.method;

        return (
            <Switch>
                <Route path={`${path}/form/:type`} exact>
                    <DepositFormApp depositItems={depositItems}></DepositFormApp>
                </Route>
                <Route path="*" >
                    <Redirect to={`${path}/form/${typePath}`} />
                </Route>
            </Switch>
        );
    };

    const renderRouter = () => {
        if (isUserLoggedIn === false) {
            return renderHomeRoutes();
        }
        else {
            if (depositItems === null) return renderHomeRoutes();

            if (depositItems.mode === "pay") {
                if (depositItems.situation === 0) {
                    return renderFormRoutes();
                }
                else {
                    return renderPayPendingRoutes();
                }
            }
            else if (depositItems.mode === "deposit") {
                if (depositItems.channel.automatic === false && depositItems.channel.truemoney === false) {
                    return renderFormRoutes("bank");
                }
                else {
                    return renderHomeRoutes();
                }
            }
            else if (depositItems.mode === "promotion") {
                return renderHomeRoutes();
            }
            else {
                return renderFormRoutes();
            }
        }
    };

    return renderRouter();
};
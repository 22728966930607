import { useEffect, useState } from 'react'
import { Grid, Container, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import Slider from 'react-slick'

import RankListItem from '../../../components/RankListItem'

// CSS
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from './../../../assets/css/Style.module.css'

export default function ListUserRank({ pageName }) {
    // const reduxAppCMS = useSelector((state) => state.reduxAppCMS.cms)
    const rankDatas = useSelector(state => state.reduxRank.rankData)
    const [storeRanking, setStoreRanking] = useState([])
    const [showRank, setShowRank] = useState([])
    const [store3rd, setStore3rd] = useState([])

    const useStyles = makeStyles((theme) => ({
        rankContainer: {
            marginTop: "100px",
            [theme.breakpoints.down("xs")]: {
                marginTop: "80px",
            }
        },
        rankHead: {
            width: "100%", 
            height: "80px",
            [theme.breakpoints.down("xs")]: {
                height: "70px",
            }
        },
        rankLogo: {
            width: "280px", 
            marginLeft: "auto", 
            display: "block", 
            marginRight: "auto", 
            marginTop: "-175px", 
            marginBottom: "25px",
            [theme.breakpoints.down("xs")]: {
                width: "246px",
                marginTop: "-155px"
            }
        },
        rankBlock: {
            borderStyle: "solid", 
            borderWidth: "0px 3px", 
            borderColor: "#c69a01", 
            backgroundColor: "#000", 
            color: "#fff", 
            marginTop: "0px",
            paddingBottom: theme.spacing(2),
            [theme.breakpoints.down("xs")]: {
                borderWidth: "0px 1px",
                paddingBottom: theme.spacing(3)
            }
        },
        middleLine: {
            margin: "30px 0", 
            height: "3px", 
            border: "0", 
            backgroundImage: "linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255, 0.75), rgba(255,255,255,0))"
        },
        rankCup: {
            width: "75px", 
            marginLeft: "auto", 
            display: "block", 
            marginRight: "auto",
            [theme.breakpoints.down("xs")]: {
                width: "43px !important"
            },
            [theme.breakpoints.down("sm")]: {
                width: "65px"
            }
        },
        rankFooter: {
            width: "100%", 
            height: "70px", 
            marginBottom: "40px",
            marginTop: "-6px",
            [theme.breakpoints.down("xs")]: {
                height: "36px",
                marginBottom: "20px"
            }
        },
    }))

    const classes = useStyles()

    const settings = {
        infinite: true,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        autoplay: true,
        autoplaySpeed: 2500,
        arrows: false,
        pauseOnHover: false,
        afterChange: current => {hookEvent(current)}
    }
    
    const [ paging, setPaging ] = useState({pageSize: 10, pageNum: 1})

    // store data to component state
    useEffect(() => {
        if(rankDatas != null) {
            let rawDataRank = [...rankDatas]
            let raw3Rd = rawDataRank.splice(0, 3)

            setStoreRanking(rawDataRank)
            let mappingRank = []

            raw3Rd.forEach((val, index) => {
                
                if(index == 0) {
                    val.cup = "/images/home/rankuser/1.webp"
                } else if (index == 1) {
                    val.cup = "/images/home/rankuser/2.webp"
                } else {
                    val.cup = "/images/home/rankuser/3.webp"
                }

                mappingRank.push(val)
            })

            setStore3rd(mappingRank)
        }
    }, [rankDatas])

    function hookEvent(value) {        
        if(value >= 9) {
            let pagingNow = {...paging}
            setPaging({pageSize: 10, pageNum: pagingNow.pageNum + 1})
        }
    }

    useEffect(() => {
        let pagingNow = {...paging}

        if((paging.pageSize * paging.pageNum) >= storeRanking.length) {
            setPaging((prev) => {
                return {
                    ...prev,
                    pageNum: 1
                }
            })
        }

        setShowRank(paginate(storeRanking, pagingNow.pageSize, pagingNow.pageNum))

    }, [setPaging, paging])

    function paginate(array, pageSize, pageNum) {

        if(!array) {
            return
        }

        return array.slice((pageNum -1) * pageSize, pageNum * pageSize)
    }

    return (
        <>
            <Container maxWidth="xl" className={classes.rankContainer}>
                
                <img src={window.location.origin + "/images/home/rankuser/H.webp"} className={classes.rankHead} />
                <img src={window.location.origin + "/images/home/rankuser/TOP.webp"} className={classes.rankLogo} />
                <Paper variant='outlined' className={classes.rankBlock}>
                    <div className={styles.rankUserTitle}>
                        {pageName}
                    </div>

                    <div className={styles.boxMarginTop}>
                        <Grid container direction="column" spacing={2} justifyContent="center" alignItems="center">

                            {
                                store3rd.length > 0 ? 
                                    store3rd.map((val, index) => {
                                        return (
                                            <Grid key={index} item container direction="row" xs={12} md={6} justifyContent="center" alignItems="center">
                                                <Grid item xs={2} md={2}>
                                                    <img src={`${window.location.origin}${val.cup}`} className={classes.rankCup} />
                                                </Grid>
                                                <Grid item xs={9} md={10}>                                               
                                                    <RankListItem rankData={ val } top></RankListItem>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                : <></>
                            }
                        </Grid>
                    </div>

                    <hr className={classes.middleLine} />

                    <div className={styles.rankUserTitle}>
                        สถานะตอนนี้
                    </div>

                    <div className={styles.boxMarginTop}>
                        <Grid container direction="column" spacing={2} justifyContent="center" alignItems="center">
                            <Grid item container direction="column" xs={12} md={6} justifyContent="center" alignItems="center" spacing={2}>
                                <Grid item xs={11} md={12}>
                                    <Slider {...settings}>
                                    {
                                        showRank.length > 0 ?
                                            showRank.map((item, index) => {
                                                return (
                                                    <RankListItem key={index} rankData={ item }></RankListItem>
                                                )
                                            })
                                        :
                                        null
                                    }
                                    </Slider>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>

                <img src={window.location.origin + "/images/home/rankuser/D.webp"} className={classes.rankFooter} />
            </Container>
        </>
    )
}

import { lazy } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
// import { useSelector } from 'react-redux';

// Routes page
const WithdrawMenuApp = lazy(() => import("./page/menu/index"));
const WithdrawFormApp = lazy(() => import("./page/form-action/index"));
// const WithdrawPendingApp = lazy(() => import("./page/pending/index"));

export default function Routes({ isUserLoggedIn, withdrawItems }) {
    const { path } = useRouteMatch(); // useRouteMatch สามารถเปลี่ยนได้แบบ Dynamic
    // const withdrawItems = useSelector(state => state.reduxWithdraw.result);

    const renderHomeRoutes = () => {
        return (
            <Switch>
                <Route path={`${path}/home`} exact>
                    <WithdrawMenuApp withdrawItems={withdrawItems}></WithdrawMenuApp>
                </Route>
                <Route path="*" >
                    <Redirect to="/withdraw/home" />
                </Route>
            </Switch>
        );
    };

    // const renderPayPendingRoutes = () => {
    //     return (
    //         <Switch>
    //             <Route path={`${path}/pending`} exact>
    //                 <WithdrawPendingApp></WithdrawPendingApp>
    //             </Route>
    //             <Route path="*" >
    //                 <Redirect to="/withdraw/pending" />
    //             </Route>
    //         </Switch>
    //     );
    // };

    const renderFormRoutes = () => {
        return (
            <Switch>
                <Route path={`${path}/form`} exact>
                    <WithdrawFormApp withdrawItems={withdrawItems}></WithdrawFormApp>
                </Route>
                <Route path="*" >
                    <Redirect to="/withdraw/form" />
                </Route>
            </Switch>
        );
    };

    const renderOtherRoutes = () => {
        return (
            <Switch>
                {/* <Route path={`${path}/pending`} exact>
                    <WithdrawPendingApp></WithdrawPendingApp>
                </Route> */}
                <Route path={`${path}/form`} exact>
                    <WithdrawFormApp withdrawItems={withdrawItems}></WithdrawFormApp>
                </Route>
                <Route path="*" >
                    <Redirect to="/withdraw/form" />
                </Route>
            </Switch>
        );
    };

    const renderRouter = () => {
        if (withdrawItems === null) return null;

        if (isUserLoggedIn === false) {
            return renderFormRoutes();
        }
        else {
            if (withdrawItems.mode === "withdraw" || withdrawItems.mode === "wait-withdraw") {
                return renderOtherRoutes();
            }
            else {
                if (withdrawItems.mode === "promotion" && (withdrawItems.target_customer >= withdrawItems.target)) {
                    return renderOtherRoutes();
                }
                else {
                    return renderHomeRoutes();
                }
            }
        }
    }

    return renderRouter();
};
import { useEffect, useState } from 'react'
import { Grid, Container, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Slider from 'react-slick'
import { useDispatch, useSelector } from 'react-redux';

// @Utility
import { getStorage, setStorage, removeStorage } from "../../../../../../@utility/app/storage";
import timeZone from "../../../../../../@utility/app/timeZone";
import currencyFormat from "../../../../../../@utility/numbers/currencyFormat";

// Redux
import { setWithdrawRankData } from '../../../../../../@core/redux/actions/withdraw/index';

// Icon
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

// CSS
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from './../../../assets/css/Style.module.css'

export default function ListUserRank({ pageName }) {
    const withdrawRank = useSelector(state => state.reduxWithdraw.rank);
    const dispatch = useDispatch();
    const [withdrawRankLength, setWithdrawRankLength] = useState(0);
    const [dataBackup, setDataBackup] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [dataListLength, setDataListLength] = useState(0);

    const useStyles = makeStyles((theme) => ({
        rankContainer: {
            marginTop: "100px",
            [theme.breakpoints.down("xs")]: {
                marginTop: "80px",
            }
        },
        rankHead: {
            width: "100%",
            height: "50px",
            [theme.breakpoints.down("xs")]: {
                height: "40px",
            }
        },
        rankBlock: {
            borderStyle: "solid",
            borderWidth: "0px 3px",
            borderColor: "#c69a01",
            backgroundColor: "#000",
            color: "#fff",
            marginTop: "0px",
            paddingBottom: theme.spacing(2),
            [theme.breakpoints.down("xs")]: {
                borderWidth: "0px 1px",
                paddingBottom: theme.spacing(3)
            }
        },
        middleLine: {
            margin: "20px 0",
            height: "3px",
            border: "0",
            backgroundImage: "linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255, 0.75), rgba(255,255,255,0))",
            [theme.breakpoints.down("xs")]: {
                height: "6px",
                margin: "10px 0",
            }
        },
        rankCup: {
            width: "75px",
            marginLeft: "auto",
            display: "block",
            marginRight: "auto",
            [theme.breakpoints.down("xs")]: {
                width: "43px !important"
            },
            [theme.breakpoints.down("sm")]: {
                width: "65px"
            }
        },
        rankFooter: {
            width: "100%",
            height: "70px",
            marginBottom: "40px",
            marginTop: "-6px",
            [theme.breakpoints.down("xs")]: {
                height: "36px",
                marginBottom: "20px"
            }
        },
        rankWithdrawList: {
            background: `var(--bg-rank)`,
            // background: `linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0) 100%)`,
            border: `1px solid var(--bt-defult)`,
            color: '#fff',
            fontSize: '16px',
            marginBottom: "12px",
            width: "99% !important",
            fontWeight: "500",
            borderRadius: "5px",
            lineHeight: "30px",
            [theme.breakpoints.down("xs")]: {
                fontSize: '13px',
                lineHeight: "20px"
            }
        },
        rankWithdrawPadding: {
            padding: "20px 12px",
            [theme.breakpoints.down("xs")]: {
                padding: "14px 12px"
            }
        },
        footerIconSize: {
            color: "var(--bt-login)",
            height: "54px",
            width: "54px",
            [theme.breakpoints.down('xs')]: {
                height: "42px",
                width: "42px"
            }
        },
        textGold: {
            color: "var(--bt-warning)",
        },
        rankWithdrawWidth: {
            width: "70%",
            [theme.breakpoints.down('lg')]: {
                width: "80%"
            },
            [theme.breakpoints.down('xs')]: {
                width: "100%"
            }
        },
        rankWithdrawTextPadding: {
            paddingLeft: "12px",
        },
        withdrawRankCard: {
            [theme.breakpoints.down('sm')]: {
                padding: "0px 10px"
            }
        }
    }));

    const classes = useStyles()

    const settings = {
        infinite: true,
        dots: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        autoplay: true,
        autoplaySpeed: 2500,
        arrows: false,
        pauseOnHover: false,
        beforeChange: (oldIndex) => {
            if(dataList[oldIndex]?.key !== undefined) {
                setStorage("withdrawRankKey", dataList[oldIndex].key);
                if ((withdrawRankLength - 1) === dataList[oldIndex].key) {
                    removeStorage("withdrawRankKey");
                    dispatch(setWithdrawRankData());
                }
                else {
                    if((dataListLength - 1) === oldIndex) {
                        resetDataRank();
                    }
                }
            }
            else {
                removeStorage("withdrawRankKey");
                dispatch(setWithdrawRankData());
            }
        }
    }

    const resetDataRank = () => {
        let dataArrBackup = withdrawRank;
        let dataArrSetIndex = [];
        let withdrawRankKey = Number(getStorage("withdrawRankKey"));
        let getDataArr = [];

        dataArrBackup.forEach((item, index) => {
            dataArrSetIndex = [...dataArrSetIndex, { ...item, key: index }];
        });

        setDataBackup(dataArrSetIndex);

        if (withdrawRankKey === undefined || withdrawRankKey === null || withdrawRankKey === "" || withdrawRankKey === false || withdrawRankKey === NaN) {
            removeStorage("withdrawRankKey");
            let dataArrNull = [];

            for (let index = 0; index < 10; index++) {
                dataArrNull = [...dataArrNull, { ...dataArrSetIndex[index] }];
            }

            getDataArr = dataArrNull;
        }
        else {
            let findKey = dataArrSetIndex.find((item) => withdrawRankKey === item.key);
            if (findKey === undefined) {
                removeStorage("withdrawRankKey");
                let dataArrDefault = [];

                for (let index = 0; index < 10; index++) {
                    dataArrDefault = [...dataArrDefault, { ...dataArrSetIndex[index] }];
                }

                getDataArr = dataArrDefault;
            }
            else {
                if ((withdrawRankLength - 1) === withdrawRankKey) {
                    removeStorage("withdrawRankKey");
                    dispatch(setWithdrawRankData());
                }
                else {
                    let dataNewWithKey = dataArrSetIndex.filter((item) => item.key > withdrawRankKey);
                    let dataArrLoop = [];

                    for (let index = 0; index < 10; index++) {
                        if (dataNewWithKey[index]?.key !== undefined) {
                            dataArrLoop.push(dataNewWithKey[index]);
                        }
                    }

                    getDataArr = dataArrLoop;
                }
            }

            setDataListLength(getDataArr.length);
            setDataList(getDataArr);
        }
    };

    useEffect(() => {
        dispatch(setWithdrawRankData());
    }, []);

    useEffect(() => {
        if (withdrawRank !== null && withdrawRank !== [] && withdrawRank !== undefined) {
            setWithdrawRankLength(withdrawRank.length);
            resetDataRank();
        }
    }, [withdrawRank]);

    return withdrawRank !== null && (
        <>
            <img src={window.location.origin + "/images/home/rankuser/H.webp"} className={classes.rankHead} />
            <Paper variant='outlined' className={classes.rankBlock}>
                <div className={styles.rankUserTitle}>
                    {pageName}
                </div>

                <hr className={classes.middleLine} />

                <div className={styles.boxMarginTop}>
                    <div className={classes.withdrawRankCard}>
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xs={false} md={3}></Grid>
                            <Grid item xs={12} md={6}>
                                <Slider {...settings}>
                                    {
                                        dataList.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className={classes.rankWithdrawList}>
                                                        <div className={classes.rankWithdrawPadding}>
                                                            <Grid container justifyContent="center">
                                                                <Grid item className={classes.rankWithdrawWidth}>
                                                                    <Grid container alignItems="center" spacing={1}>
                                                                        <Grid item xs={3} className="center">
                                                                            <AccountBalanceIcon className={classes.footerIconSize}></AccountBalanceIcon>
                                                                        </Grid>
                                                                        <Grid item xs={9}>
                                                                            <div>
                                                                                <span>
                                                                                    ยูส: <span className={classes.textGold}>{item.username}</span>
                                                                                </span>
                                                                                <span className={classes.rankWithdrawTextPadding}>
                                                                                    วันที่: <span className={classes.textGold}>{timeZone(item.time_create, "datetime")}</span>
                                                                                </span>
                                                                            </div>
                                                                            <div>
                                                                                <span>
                                                                                    ยอดถอน: <span className={classes.textGold}>{currencyFormat(item.amount, 2)}</span> บาท
                                                                                </span>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </Grid>
                            <Grid item xs={false} md={3}></Grid>
                        </Grid>
                    </div>
                </div>
            </Paper>

            <img src={window.location.origin + "/images/home/rankuser/D.webp"} className={classes.rankFooter} />
        </>
    )
}

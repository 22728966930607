const SET_DATA_ALERT = 'app/SET_DATA_ALERT';

function setDataAlert(data) {
    return {
        type: SET_DATA_ALERT,
        payload: {
            items: data
        }
    }
}

export {
    SET_DATA_ALERT,
    setDataAlert
}
import { getRank } from "../../../services/rankService"
import { setLoadProcessing } from "../app/processing"

const LOAD_RANK_LIST = 'app/LOAD_RANK_LIST'

function loadRank() {
    return (dispatch) => {
        dispatch(setLoadProcessing(true))

        getRank().then((data) => {
            const statusCode = data.code
            dispatch(setLoadProcessing(false))

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_RANK_LIST,
                    payload: {
                        items: data.result
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_RANK_LIST,
                    payload: {
                        items: null
                    }
                })
            }
        })

        
    }
}

export {
    LOAD_RANK_LIST,
    loadRank
}
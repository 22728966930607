import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Services
import { loadDataAll } from "./../../../../../@core/services/affiliateService";

// Redux
import { openGetCredit } from "./../../../../../@core/redux/actions/affiliate/index";
import { setAffiliateData } from "./../../../../../@core/redux/actions/affiliate/index";
// import { setStatusSpinner } from "./../../../../../@core/redux/actions/app/spinner";

// Component
import ContentAffiate from './component/Content';
import ModalApp from "./../../components/ModalFull";

const AffiateApp = (props) => {
    const dispatch = useDispatch();
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);

    const onShow = (val) => {
        if (val === false) {
            document.title = process.env.REACT_APP_OG_SITE_NAME+ " - หน้าหลัก";
        }

        // props.onAffiliate({ ...props.affiliate, show: val })
        // dispatch(openGetCredit({ ...props.affiliate, show: val }));
        onAffiliate(props.affiliate, val);
    }

    const loadData = () => {
        // dispatch(setStatusSpinner({status: true, textProcess: null}));
        loadDataAll("/customer/property/list").then((data) => {
            // dispatch(setStatusSpinner({status: false, textProcess: null}));
            if (data.code === 0 || data.code === 200) {
                dispatch(setAffiliateData(data.result));
            }
        });
    };

    const onAffiliate = (affiliate, val) => {
        dispatch(openGetCredit({ ...affiliate, show: val }));
    };

    useEffect(() => {
        loadData();
    }, [dispatch]);

    return (
        <ModalApp show={props.affiliate.show} onShow={onShow}>
            <ContentAffiate onAffiliate={onAffiliate} loadData={loadData} reduxAppCMS={reduxAppCMS}></ContentAffiate>
        </ModalApp>
    );
}

// AffiateApp.defaultProps = {
//     affiliate: {
// tab: "affiliate",
// show: false,
// type: null
//     }
// }

export default AffiateApp;
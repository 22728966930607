const SET_SHOW_POPUP_IMAGE = 'app/SET_SHOW_POPUP_IMAGE';
const SET_SHOW_POPUP_PROMOTION = 'app/SET_SHOW_POPUP_PROMOTION';
const SET_SHOW_POPUP_LOGIN = 'app/SET_SHOW_POPUP_LOGIN';
const SET_SHOW_POPUP_REGISTER = 'app/SET_SHOW_POPUP_REGISTER';

function setShowPopupImage(data) {
    return {
        type: SET_SHOW_POPUP_IMAGE,
        payload: {
            items: data
        }
    }
}

function setShowPopupPromotion(data) {
    return {
        type: SET_SHOW_POPUP_PROMOTION,
        payload: {
            items: data
        }
    }
}

function setShowPopupLogin(data) {
    return {
        type: SET_SHOW_POPUP_LOGIN,
        payload: {
            items: data
        }
    }
}

function setShowPopupRegister(data) {
    return {
        type: SET_SHOW_POPUP_REGISTER,
        payload: {
            items: data
        }
    }
}

export {
    SET_SHOW_POPUP_IMAGE,
    SET_SHOW_POPUP_PROMOTION,
    SET_SHOW_POPUP_LOGIN,
    SET_SHOW_POPUP_REGISTER,
    setShowPopupImage,
    setShowPopupPromotion,
    setShowPopupLogin,
    setShowPopupRegister
}
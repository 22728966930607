import { useEffect, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

// Hooks
import useParameterUrl from "./../../../../../@utility/hooks/useParameterUrl";

// Redux
import { setDataAlert } from "./../../../../../@core/redux/actions/app/alert";
import { openAuthenApp } from "./../../../../../@core/redux/actions/auth/index";

// auth
import { isUserLoggedIn } from "./../../../../../@core/auth/utils";

// Services
// import { login, loginLine } from "./../../../../../@core/auth/jwt/jwtService";

// Component
import LogoApp from "./../Logo";
import ModalApp from "./../ModalFull";
import LoginApp from "./Login";
import RegisterApp from "./Register";
import RememberApp from "./Remember";
// import AuthStep from "./step";
import LoadContent from "./../LoadContent";
import SnackbarSet from "./../SnackbarSet";

//  CSS
import styles from "./../../assets/css/Style.module.css";
import buttonStyles from "./../../assets/css/Button.module.css";
import formStyles from "./../../assets/css/Input.module.css";

function AuthApp() {
    const childRef = useRef();
    const parsed = useParameterUrl();
    const auth = useSelector(state => state.reduxAuth);
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);
    const [process, setProcess] = useState(false);
    const [data, setData] = useState(undefined);
    let { code } = parsed;
    // const [lineCode, setLineCode] = useState( (code !== undefined && code !== "") ? code : "");

    // เปิดใช้งาน Auth
    const onShow = (val) => {
        dispatch(openAuthenApp({ ...auth, show: val }));
    };

    // เปลีย่นช่องทาง
    const switchChanel = (val) => {
        // setData(undefined)
        dispatch(openAuthenApp({ ...auth, tab: val }));
    };

    const alertMsg = async (da) => {
        childRef.current.snackbar(da);
    }

    useEffect(() => {

        // ตรวจสอบว่ามีการเข้าสู้ระบบหรือไม่
        if (isUserLoggedIn()) {
            dispatch(openAuthenApp({
                tab: "login",
                show: false
            }));
        }

        setStep(1)
    }, [auth.show]);

    return (
        <>
            <SnackbarSet ref={childRef} />
            <ModalApp show={auth.show} onShow={onShow} step={step} onStep={setStep} disable={process}>
                {
                    auth.tab !== "remember" ?
                        <>
                            <div className="center">
                                <LogoApp />
                            </div>

                            {/* {
                                (!process && auth.tab !== "register") && (
                                    <div className="center">
                                        <LogoApp />
                                    </div>
                                )
                            } */}

                            {/* {auth.tab === "register" && !process ?
                                <>
                                    <br />
                                    <Grid container justifyContent="center"><AuthStep currented={step} total={3} /></Grid>
                                </> : null
                            } */}

                            {(step === 1 || auth.tab === "login") && !process ?
                                <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: "20px" }} spacing={2}>
                                    <Grid item xs={6}>
                                        <div onClick={() => switchChanel('login')} className={[buttonStyles.btnLogin, auth.tab === "login" ? buttonStyles.btnOrange : buttonStyles.btnOutline].join(" ")} >
                                            เข้าสู่ระบบ
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div onClick={() => switchChanel('register')} className={[buttonStyles.btnLogin, auth.tab === "register" ? buttonStyles.btnOrange : buttonStyles.btnOutline].join(" ")} >
                                            สมัครสมาชิก
                                        </div>
                                    </Grid>
                                </Grid> : null
                            }

                            {auth.tab === "login" ? <LoginApp data={data} onData={setData} onShow={onShow} alert={alertMsg} switchChanel={switchChanel} codeLine={code} onProcess={setProcess} process={process} /> : null}
                            {auth.tab === "register" ? <RegisterApp data={data} onData={setData} onShow={onShow} alert={alertMsg} onProcess={setProcess} process={process} step={step} onStep={setStep} setDataAlert={setDataAlert} /> : null}
                        </>
                        :
                        <RememberApp onShow={onShow} alert={alertMsg} switchChanel={switchChanel} />
                }

                {process ?
                    <div className={styles.boxContentWidthBig}>
                        <div className={styles.formPadding}>
                            <div className={[styles.boxContentLineTitle, styles.center].join(" ")} style={{ marginTop: "0px" }}>
                                ระบบกำลังตรวจสอบข้อมูล
                            </div>
                            <br />
                            <LoadContent></LoadContent>
                            <div className={styles.processLineText}>
                                กรุณา "<span style={{ color: "#FFF500" }}>ห้ามปิด หรือรีเฟรช</span>" หน้าจอนี้จนกว่าระบบจะทำงานสำเร็จ
                            </div>
                        </div>
                    </div> : null
                }

                {
                    process === false && (
                        <>
                            <br /><br />
                            <div className={[formStyles.inputPaddingTop, "center"].join(" ")}>
                                <div style={{ color: "white", fontSize: "16px" }}>
                                    หากคุณพบปัญหาใด ๆ ในขณะเข้าสู่ระบบ
                                    กรุณาติดต่อ <span style={{ color: "#FF0000" }}>พนักงานบริการลูกค้า</span> เพื่อขอความช่วยเหลือเพิ่มเติม
                                </div>
                            </div>
                        </>
                    )
                }

            </ModalApp>
        </>
    );
}

export default AuthApp

import axios from 'axios';
import serviceConfig from "./serviceConfig";
import { getToken } from "./../auth/utils";
import { checkStatusCode } from "./services";

const queryString = require('query-string');
const svConfig = { ...serviceConfig };

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const getPromotion = async () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
  let config = {
    method: svConfig.method_get,
    url: `/general/promotion`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    return {
      code,
      result
    };
  }
}

const selectPromotionList = async (data) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  let myJson = {
    "amount": data
  };

  let config = {
    method: svConfig.method_post,
    url: "/customer/promotion/select-list",
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: queryString.stringify(myJson)
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    return {
      code,
      result
    };
  }
}

const cancelPromotion = async () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  let config = {
    method: svConfig.method_post,
    url: `/customer/promotion/cancel`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    }
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response);
  const { code } = dataPromise;

  if (checkStatusCode(code)) {
    return {
      code
    };
  }
}

const checkPromotionApply = async () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  let config = {
    method: svConfig.method_get,
    url: `/customer/promotion/apply-condition`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    }
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    return {
      code,
      result
    };
  }
}

const updatePromotionAuto = async (promotion_auto) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  let myJson = {
    "promotion_auto": promotion_auto
  };

  let config = {
    method: svConfig.method_post,
    url: "/customer/promotion/auto",
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: queryString.stringify(myJson)
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    return {
      code,
      result
    };
  }
}

export {
  getPromotion,
  cancelPromotion,
  selectPromotionList,
  checkPromotionApply,
  updatePromotionAuto
};
import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

// Redux
import { openPromotionPopup } from "./../../../../../../@core/redux/actions/promotion/index";

// Component
import ModalApp from "./../../../components/Modal";

//  CSS
import styles from "./../../../assets/css/Style.module.css";
import modalStyles from "./../../../assets/css/Modal.module.css";
import buttonStyles from "./../../../assets/css/Button.module.css";

const PromotionPopup = (props) => {
    const dispatch = useDispatch();
    const [dialogJson, setDialogJson] = useState(null);

    const onShow = (val) => {
        dispatch(openPromotionPopup({ ...props.reduxPromotionPopup, show: val }));
    };

    const selectPromotionData = (status) => {
        if (status) {
            dispatch(openPromotionPopup({ ...props.reduxPromotionPopup, show: false, promotionSelect: status }));
        }
        else {
            dispatch(openPromotionPopup({
                tab: "promotionPopup",
                promotionId: 0,
                promotionData: null,
                show: false,
                promotionSelect: status
            }));
        }
    };

    useEffect(() => {
        setDialogJson(props.reduxPromotionPopup.promotionData);
    }, [props]);

    return (
        <ModalApp show={props.reduxPromotionPopup.show} onShow={onShow}>
            <div className={modalStyles.boxContentScroll} style={{ color: "white" }}>
                <div className={[styles.boxContentPromotionTitle, styles.center].join(" ")} style={{ marginTop: "0px" }}>
                    รายละเอียดโปรโมชั่น
                </div>
                <div>
                    {
                        dialogJson !== null && (
                            ReactHtmlParser(dialogJson.content_text)
                        )
                    }
                </div>
                <br /><br />
            </div>

            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={6}>
                    <div className={[buttonStyles.btnConfirm, buttonStyles.btnCancel].join(" ")} style={{ height: "unset", width: "unset" }} onClick={() => { selectPromotionData(false) }}>
                        ยกเลิกรับโปรโมชั่น
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className={[buttonStyles.btnConfirm].join(" ")} style={{ height: "unset", width: "unset" }} onClick={() => { selectPromotionData(true) }}>
                        รับโปรโมชั่น
                    </div>
                </Grid>
            </Grid>
        </ModalApp>
    );
}

export default PromotionPopup;
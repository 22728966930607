import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

// @utility
import alertText from "./../../../../@utility/app/alertText";

// Redux
import { setDataDialog } from "./../../../../@core/redux/actions/app/dialog";
import { setStatusSpinner } from "./../../../../@core/redux/actions/app/spinner";
import { loadCheckPromotion } from "./../../../../@core/redux/actions/promotion";
import { setDataAlert } from "./../../../../@core/redux/actions/app/alert";

// Services
import { cancelPromotion } from "./../../../../@core/services/promotionService";

// Components
import ModalApp from './Modal';
import PromotionAlertContent from './PromotionAlertContent';

//  CSS
import styles from "./../assets/css/Style.module.css";
import buttonStyles from "./../assets/css/Button.module.css";
import modalStyles from "./../assets/css/Modal.module.css";

export default function NavbarPromotion() {
    const { path } = useRouteMatch();
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);
    const processing = useSelector(state => state.reduxAppProcessing.processing);
    const promotionData = useSelector(state => state.reduxCheckPromotion.promotionData);
    const [statusShow, setStatusShow] = useState({ show: false });
    const dispatch = useDispatch();
    let snackbarJson = {};
    const homeData = useSelector(state => state.reduxHome.result);
    
    const useStyles = makeStyles((theme) => ({
        narbarPosition: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-danger"] : "#FF0000",
            padding: "8px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            fontSize: "16px",
            cursor: "pointer",
            [theme.breakpoints.down('xs')]: {
                padding: "6px",
                fontSize: "14px"
            },
            '&:hover': {
                opacity: "0.8"
            }
        },
        processText: {
            fontSize: "18px",
            fontWeight: "500"
        }
    }));

    const classes = useStyles();

    const onShow = (val) => {
        setStatusShow({
            show: val
        });
    };

    const ModalPromotion = () => {
        if(statusShow === false) return null;
        
        return (
            <ModalApp show={statusShow.show} onShow={onShow}>
                <div className={modalStyles.boxTitle}>
                    ติดสถานะรับโปรโมชั่น
                </div>
                <div className={[styles.boxMarginFormTop, "center"].join(" ")}>
                    <div className={styles.boxDetailTop}>
                        {
                            (processing === false || promotionData !== null) ?
                            <>
                                <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <div className={styles.promotionBoxContentTitle}>
                                            กำลังรอดำเนินการ
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <button className={[buttonStyles.btnConfirm, buttonStyles.btnConditionSize].join(" ")} onClick={() => openBox(promotionData)}>
                                            อ่านเงื่อนไข
                                        </button>
                                    </Grid>
                                </Grid>
                                <PromotionAlertContent promotionData={promotionData}></PromotionAlertContent>
                            </>
                            :
                            <div className={[classes.processText, "center"].join(" ")}>
                                กำลังโหลดข้อมูล...
                            </div>
                        }
                    </div>
                    {
                        (processing === false || promotionData !== null) && (
                            <div className={styles.boxMarginFormTop}>
                                <button className={[buttonStyles.btnRedirect, buttonStyles.btnCancel, "center"].join(" ")} onClick={setCancelPromotion}>
                                    ยกเลิกรับโปร
                                </button>
                            </div>
                        )
                    }
                </div>
            </ModalApp>
        );
    };

    const openModalPromotion = () => {
        onShow(true);
        dispatch(loadCheckPromotion());
    };

    const openBox = (data) => {
        if(data?.content_text !== undefined) {
            let dialogJson = {
                pageType: "promotion",
                statusShow: true,
                type: "html",
                image: null,
                title: "เงื่อนไขโปรโมชั่น",
                content: data.content_text,
                action: null
            };
    
            dispatch(setDataDialog(dialogJson));
            onShow(false);
        }
    };

    const openAlert = (snackbarJson) => {
        dispatch(setDataAlert({
            type: snackbarJson.statusAlert,
            title: snackbarJson.titleAlert,
            text: snackbarJson.textAlert,
            action: true,
            redirect: null,
            show: true,
            buttonText: "ตกลง"
        }));
    };

    const setCancelPromotion = () => {
        if (homeData.balance <= process.env.REACT_APP_BALANCE_LIMIT) {
            dispatch(setStatusSpinner({status: true, textProcess: null}));
            cancelPromotion().then((data) => {
                const statusCode = data.code;
                dispatch(setStatusSpinner({status: false, textProcess: null}));
                if (statusCode === 200 || statusCode === 0) {
                    snackbarJson = {
                        statusShow: true,
                        statusAlert: "success",
                        titleAlert: "แจ้งเตือน",
                        textAlert: "ยกเลิกรับโบนัสสำเร็จ !"
                    };
                    
                    openAlert(snackbarJson);
                }
                else {
                    if (statusCode === 2 || statusCode === 4) {
                        snackbarJson = alertText(statusCode);
                        openAlert(snackbarJson);

                        setTimeout(function () {
                            window.location.href = `/?web=${process.env.REACT_APP_DEFAULT_AGENT_CODE}`;
                        }, 5000);
                    }
                    else {
                        snackbarJson = alertText(statusCode);
                        openAlert(snackbarJson);
                    }
                }
            });

        }
        else {
            snackbarJson = {
                statusShow: true,
                statusAlert: "warning",
                titleAlert: "แจ้งเตือน",
                textAlert: `ไม่สามารถยกเลิกโบนัสได้ เนื่องจากเครดิตของคุณลูกค้าคงเหลือมากกว่า ${process.env.REACT_APP_BALANCE_LIMIT} เครดิต`
            };

            openAlert(snackbarJson);
        }
    };

    return (
        <>
            <div className={classes.narbarPosition} onClick={openModalPromotion}>
                <Container maxWidth="lg" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item>
                            คุณติดสถานะรับโปรโมชั่น (คลิ๊กดูรายละเอียด)
                        </Grid>
                    </Grid>
                </Container>
            </div>

            {
                ModalPromotion()
            }
        </>
    );
}
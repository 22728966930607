import { useState, useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

// auth
import { isUserLoggedIn } from "./../../../../../../@core/auth/utils";

// Redux
import { setDataAlert } from "./../../../../../../@core/redux/actions/app/alert";
import { setHomeMenuType } from "./../../../../../../@core/redux/actions/home/index";

// Services
import { likeGame } from "../../../../../../@core/services/gameService";

// Component
import OpenGame from "./../../../components/OpenGame";
import ProgressiveImage from "./../../../components/ProgressiveImage";

// CSS
import styles from "./../../../assets/css/Style.module.css";
import bagdeStyles from "./../../../assets/css/Bagde.module.css";

export default function ListRank({ pageName }) {
    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    const itemsGameHitList = useSelector(state => state.reduxGame.itemsGameHitList);
    const homeData = useSelector(state => state.reduxHome.result);
    const [gameList, setGameList] = useState(null);
    const dispatch = useDispatch();
    const [hearthStatus, setHearthStatus] = useState(null);
    const [checkPromotion, setCheckPromotion] = useState(null);
    const slideRef = useRef();
    const imageRef = useRef();
    const reduxAppScrollPosition = useSelector(state => state.reduxAppScrollPosition.top);
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);
    const gameRef = useRef();

    const useStyles = makeStyles((theme) => ({
        cardGameBody: {
            // background: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "white",
            borderRadius: "15px",
            // padding: "30px",
            [theme.breakpoints.down('xs')]: {
                borderRadius: "0px",
                padding: "0px"
            }
        },
        gameSize: {
            width: "100%",
            height: "100%",
            borderRadius: "16px",
            cursor: "pointer",
            [theme.breakpoints.down('xs')]: {
                borderRadius: "10px"
            }
        },
        cardGameMenu: {
            padding: "0px 12px"
        },
        cardGameList: {
            padding: "20px 0px",
            [theme.breakpoints.down('xs')]: {
                padding: "20px 0px 0px 0px"
            }
        },
        menuList: {
            border: "2px solid #F0F1F9",
            borderRadius: "18px",
            fontSize: "18px",
            padding: "4px 24px",
            cursor: "pointer",
            position: "relative",
            color: "white",
            '&:hover': {
                background: "linear-gradient(90deg, #360092 0%, #A60E97 100%)"
            }
        },
        heartContentPosition: {
            position: "absolute",
            top: "-6px",
            right: "-6px"
        },
        heartContent: {
            width: "44px",
            height: "auto",
            cursor: "pointer",
            [theme.breakpoints.down('xs')]: {
                width: "34px"
            }
        },
        gameSizePadding: {
            padding: "10px 20px",
            marginBottom: "10px",
            width: "190px",
            height: "100%",
            [theme.breakpoints.down('sm')]: {
                marginBottom: "0px",
            },
            [theme.breakpoints.down('xs')]: {
                padding: "6px 12px",
                width: "120px"
            }
        },
        gameMore: {
            fontSize: "18px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-danger"] : "#FF0000",
            fontWeight: "600",
            cursor: "pointer",
            [theme.breakpoints.down('xs')]: {
                fontSize: "15px"
            }
        },
        gameRankNumber: {
            fontSize: "120px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            textShadow: "-2px -2px 0 #FFFFFF, 2px -2px 0 #FFFFFF, -2px 2px 0 #FFFFFF, 2px 2px 0 #FFFFFF",
            [theme.breakpoints.down('xs')]: {
                textShadow: "-1px -1px 0 #FFFFFF, 1px -1px 0 #FFFFFF, -1px 1px 0 #FFFFFF, 1px 1px 0 #FFFFFF",
                fontSize: "80px"
            }
        },
        gameRankNumberPosition: {
            position: "absolute",
            bottom: "-26px",
            left: "-35px",
            [theme.breakpoints.down('xs')]: {
                bottom: "-15px",
                left: "-20px"
            }
        }
        // gameRankNumberMargin: {
        //     marginRight: "-40px",
        //     [theme.breakpoints.down('xs')]: {
        //         marginRight: "-20px"
        //     }
        // }
    }));

    const classes = useStyles();

    const loadGame = (item) => {
        gameRef.current.loadGame(item, "game");
    };

    const renderHearth = (item, index) => {
        return (
            item.like === false ?
                <img src="/images/icon/hearth_not_active.webp" className={classes.heartContent} onClick={() => { clickLikeGame(item, index, true) }} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                :
                <img src="/images/icon/hearth_active.webp" className={classes.heartContent} onClick={() => { clickLikeGame(item, index, false) }} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
        );
    };

    const clickLikeGame = (item, index, statusActive) => {
        if (isUserLoggedIn()) {
            let myJson = {};
            let gameListBackup = gameList;

            if (item.like === false) {
                myJson = {
                    gameId: Number(item.id),
                    statusLike: true
                };
            }
            else {
                myJson = {
                    gameId: Number(item.id),
                    statusLike: false
                };
            }

            if (statusActive) {
                gameListBackup[index].like = true;
            }
            else {
                gameListBackup[index].like = false;
            }

            setGameList(gameListBackup);
            setHearthStatus(statusActive);

            likeGame(myJson).then(() => {
                setHearthStatus(null);
            });
        }
        else {
            dispatch(setDataAlert({
                type: "warning",
                title: "แจ้งเตือน",
                text: `กรุณาเข้าสู่ระบบก่อนเข้าใช้งาน "เพิ่มเกมที่ชื่นชอบ"`,
                action: true,
                redirect: "/login",
                show: true,
                buttonText: "ตกลง"
            }));
        }
    };

    const renderBagde = (item) => {
        if (item.status === 3) {
            return (
                <div className={bagdeStyles.ribbonPosition}>
                    <div className={[bagdeStyles.ribbon, bagdeStyles.ribbonDanger].join(" ")}>
                        ปิดปรับปรุง
                    </div>
                </div>
            );
        }
        else if (item.status === 2) {
            if (item.spin === 1 || item.promotion === 1) {
                if (checkPromotion === 1 && item.promotion === 1) {
                    return (
                        <div className={bagdeStyles.ribbonPosition}>
                            <div className={[bagdeStyles.ribbon, bagdeStyles.ribbonDanger].join(" ")}>
                                ติดโปรโมชั่น
                            </div>
                        </div>
                    );
                }
                else if (item.spin === 1) {
                    return (
                        <div className={bagdeStyles.ribbonPosition}>
                            <div className={[bagdeStyles.ribbon, bagdeStyles.ribbonWarning].join(" ")}>
                                ซื้อฟรีสปิน
                            </div>
                        </div>
                    );
                }
            }
            else {
                if (item.specials !== "") {
                    let specialType = item.specials.split(",");

                    if (specialType.length > 1) {
                        return (
                            <div className={bagdeStyles.ribbonPosition}>
                                <div className={[bagdeStyles.ribbon, bagdeStyles.ribbonPrimary].join(" ")}>
                                    มาใหม่
                                </div>
                            </div>
                        );
                    }
                    else {
                        let selectTypeNew = specialType.find((str) => str === "new");
                        let selectTypeHot = specialType.find((str) => str === "hot");

                        if (selectTypeNew != undefined) {
                            return (
                                <div className={bagdeStyles.ribbonPosition}>
                                    <div className={[bagdeStyles.ribbon, bagdeStyles.ribbonPrimary].join(" ")}>
                                        มาใหม่
                                    </div>
                                </div>
                            );
                        }

                        if (selectTypeHot != undefined) {
                            return (
                                <div className={bagdeStyles.ribbonPosition}>
                                    <div className={[bagdeStyles.ribbon, bagdeStyles.ribbonYellow].join(" ")}>
                                        มาแรง
                                    </div>
                                </div>
                            );
                        }
                    }
                }
            }
        }
        else {
            return null;
        }
    };

    const gotoDemo = () => {
        let menuList = dataSetting.menu_camp;
        let dataObj = {};

        let index = menuList.findIndex(item => item.code === "slot");
        dataObj = menuList[index];
        dataObj.id = index;

        if(dataObj !== undefined && dataObj !== null) {
            dispatch(setHomeMenuType({
                id: dataObj.id,
                renderPage: dataObj.code,
                pageTitle: dataObj.title,
                game_camp_image: dataObj.game_camp_image,
                column: {
                    desktop: dataObj.column_desktop,
                    mobile: dataObj.column_mobile
                },
                typeGame: dataObj.game_camp_type
            }));

            window.scrollTo({
                top: reduxAppScrollPosition,
                left: 0,
                behavior: "smooth"
            });        
        }
    };

    useEffect(() => {
        setGameList(itemsGameHitList);

        if (isUserLoggedIn()) {
            if (homeData !== null) {
                setCheckPromotion(homeData.promotion_status);
            }
        }
    }, [hearthStatus, itemsGameHitList]);

    return (
        <>
            <OpenGame ref={gameRef}></OpenGame>
            <div className={classes.cardGameBody}>
                <div className={classes.cardGameMenu}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <div className={styles.cardOtherListHeadText}>
                                {pageName}
                            </div>
                        </Grid>
                        <Grid item>
                            <div className={classes.gameMore} onClick={gotoDemo}>
                                เพิ่มเติม
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.cardGameList}>
                    <div className={styles.menuPositionWidthScroll} ref={slideRef}>
                        <div className="flexContent">
                            {
                                gameList !== null && (
                                    gameList.map((item, index) => {
                                        return (
                                            <div key={item.id}>
                                                <div className={classes.gameSizePadding}>
                                                    <div className="relative">
                                                        {/* <div className="flexContent flexContentCenter" onClick={() => ((item.status === 3 || item.status === 0 || item.status === 1) || (checkPromotion === 1 && item.promotion === 1)) ? null : loadGame(item)}>
                                                            <div className={classes.gameRankNumberMargin}>
                                                                <div className={classes.gameRankNumber}>
                                                                    { ++index }
                                                                </div>
                                                            </div>
                                                            <div className="relative">
                                                                {
                                                                    renderBagde(item, "gameList")
                                                                }

                                                                <img className={classes.gameSize} src={item.image} ref={imageRef} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                                
                                                                <div className={classes.heartContentPosition}>
                                                                    {
                                                                        renderHearth(item, index)
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        {
                                                            renderBagde(item, "gameList")
                                                        }

                                                        <div onClick={() => ((item.status === 3 || item.status === 0 || item.status === 1) || (checkPromotion === 1 && item.promotion === 1)) ? null : loadGame(item)}>
                                                            {/* <img className={classes.gameSize} src={item.image} ref={imageRef} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" /> */}
                                                            
                                                            <ProgressiveImage 
                                                                placeholder="/images/bluring/215x280.jpg" 
                                                                className={classes.gameSize}
                                                                src={item.image}
                                                                imageRef={imageRef}
                                                            ></ProgressiveImage>

                                                            <div className={classes.gameRankNumberPosition}>
                                                                <div className={classes.gameRankNumber}>
                                                                    <span className="roboto">
                                                                        { ++index }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={classes.heartContentPosition}>
                                                            {
                                                                renderHearth(item, index)
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
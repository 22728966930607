import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';

// @Utility
import currencyFormat from "./../../../../@utility/numbers/currencyFormat";

//  CSS
import styles from "./../assets/css/Style.module.css";
// import modalStyles from "./../assets/css/Modal.module.css";

export default function PromotionAlertContent({ promotionData }) {
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);

    return (
        <>
            <div className={[styles.boxMarginTop, "left"].join(" ")}>
                {
                    promotionData !== null &&
                        <div className={styles.boxDetailAmount} style={{ padding: "0px", marginTop: "10px" }}>
                            <div className={styles.promotionBoxContentText} style={{ fontWeight: "600", color: reduxAppCMS !== null ? reduxAppCMS["bt-danger"] : "#FF0000" }}>
                                {promotionData.content_subject}
                            </div>
                            <div className={styles.boxMarginTop}>
                                <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <div className={styles.promotionBoxContentText}>
                                            ยอดรวมทั้งหมดที่ได้รับ
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <span className={styles.promotionBoxContentTextSub} style={{ fontWeight: "600", color: reduxAppCMS !== null ? reduxAppCMS["bt-danger"] : "#FF0000" }}>{currencyFormat((promotionData.deposit + promotionData.bounus))}</span>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <div className={styles.promotionBoxContentText}>
                                            ชนิดโปรโมชั่น
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <span className={styles.promotionBoxContentTextSub}>{promotionData.target_type}</span>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <div className={styles.promotionBoxContentText}>
                                            ยอดปัจจุบัน
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <span className={styles.promotionBoxContentTextSub}>{currencyFormat(promotionData.target_customer)}</span>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <div className={styles.promotionBoxContentText}>
                                            ยอดเป้าหมาย
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <span className={styles.promotionBoxContentTextSub} style={{ fontWeight: "600", color: reduxAppCMS !== null ? reduxAppCMS["bt-sucess"] : "#000000" }}>{currencyFormat(promotionData.target)}</span>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                }
            </div>
        </>
    );
}
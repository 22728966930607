import { SET_BANK_LIST, SET_BANK_OPEN }  from './../../actions/bank/index';

const initialState = {
    result: null,
    statusOpen: true
};

export default function reducer(state = initialState, action) {
    switch(action.type) {
        case SET_BANK_LIST:
            return { ...state, result: action.payload.items };
        case SET_BANK_OPEN:
            return { ...state, statusOpen: action.payload.items };
        default: 
            return state;
    } 
}
import { 
    SET_STATUS_SPINNER 
} from '../../actions/app/spinner';

const initialState = {
    data: {
        status: false,
        textProcess: null
    }
};

export default function reducer(state = initialState, action) {
    switch(action.type) {
        case SET_STATUS_SPINNER:
            return { ...state, data: action.payload.items };
        default: 
            return state;
    } 
}
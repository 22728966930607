import { OPEN_PROFILE } from './../../actions/profile/index';

const initialState = {
    tab: "profile",
    type: null,
    button: true,
    show: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_PROFILE:
            return { ...state, ...action.payload.items };
        default:
            return state;
    }
}
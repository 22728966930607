import {
    LOAD_SETTING,
    LOAD_FAILURE
} from './../../actions/app/menuSetting';

const initialState = {
    isError: false,
    menu_items: {
        agent_setting_menu: {
            menu_activity: 1,
            menu_camp: 1,
            menu_chat: 1,
            menu_checkname: 1,
            menu_contact: 1,
            menu_deposit: 1,
            menu_like: 1,
            menu_mailbox: 1,
            menu_mission: 1,
            menu_multiplayer: 1,
            menu_promotion: 1,
            menu_reward: 1,
            menu_shop: 1,
            menu_social: 1,
            menu_statistics: 1,
            menu_tournament: 1,
            menu_withdraw: 1
        },
        agent_image: null,
        menu_camp: null,
        cms: null,
        menu_second: null
    }
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_SETTING:
            return { ...state, isError: false, menu_items: action.payload.items };
        case LOAD_FAILURE:
            return { ...state, isError: true, menu_items: null };
        default:
            return state;
    }
}
import { 
    SET_SNACKBAR_DATA 
} from '../../actions/app/snackbar';

const initialState = {
    snackbarData: {
        statusShow: false,
        statusAlert: "",
        titleAlert: "",
        textAlert: ""
    }
};

export default function reducer(state = initialState, action) {
    switch(action.type) {
        case SET_SNACKBAR_DATA:
            return { ...state, snackbarData: action.payload.items };
        default: 
            return state;
    } 
}
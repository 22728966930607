import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Auth
import { isUserLoggedIn } from "../../../../../../../@core/auth/utils";

// Hooks
import useParameterUrl from "../../../../../../../@utility/hooks/useParameterUrl";

// Services
import {
    loadGameCamp,
    loadGameCategory
} from "../../../../../../../@core/services/gameService";

// Redux
import {
    setGameAll,
    setTypeAll,
    setPopupGameData
} from "./../../../../../../../@core/redux/actions/game/index";
import { setStatusSpinner } from "./../../../../../../../@core/redux/actions/app/spinner";

// Component
import GameCamp from './GameCamp';
import GameLoad from "./../../component/GameLoad";
import SelectType from "./../../../../components/SelectType";

const GameCampApp = () => {
    const { type } = useParams();
    document.title = process.env.REACT_APP_OG_SITE_NAME + " - " + type;
    const dispatch = useDispatch();
    const itemsGameList = useSelector(state => state.reduxGame.itemsGameList);
    const itemsTypeList = useSelector(state => state.reduxGame.itemsTypeList);
    const [processing, setProcessing] = useState(true);
    const [processingGame, setProcessingGame] = useState(false);
    const [rowGameList, setRowGameList] = useState(0);
    // const [itemsTypeList, setItemsTypeList] = useState(null);
    const [gameNull, setGameNull] = useState(null);
    const [processingType, setProcessingType] = useState(false);
    const [processingPopup, setProcessingPopup] = useState(null);
    const menuPage = useSelector(state => state.reduxHome.menu);
    const parsed = useParameterUrl();
    const { gamecode, popup } = parsed;
    const [selectTypeData, setSelectTypeData] = useState({
        tab: "game",
        type: menuPage !== null ? (menuPage.typeGame === 1 || menuPage.typeGame === 2 || menuPage.typeGame === 3 || menuPage.typeGame === 4 || menuPage.typeGame === 12) ? menuPage.typeGame : 0 : 0,
        name: "",
        itemList: []
    });

    const loadData = (camp, type, filter, limit, offset, statusUseScroll = false, specials = "") => { // โหลดข้อมูลเกม
        if (itemsGameList === false || itemsGameList[camp] === undefined) { // เช็คข้อมูล ถ้าไม่มีแสดงตัวโหลด
            setProcessing(true);
            dispatch(setStatusSpinner({ status: true, textProcess: null }));
        }
        else {
            if (itemsGameList[camp] !== undefined) {
                setProcessing(false);
            }
        }

        // 200 = ยอดฮิต, 201 = เกมใหม่, 99 = เกมทั้งหมด
        loadGameCamp(camp, (type === 200 ? 99 : type === 201 ? 99 : type), filter, limit, offset, specials).then((result) => {
            dispatch(setStatusSpinner({ status: false, textProcess: null }));
            setGameNull(result);

            if (result !== null) {
                let myObj = {};
                let dataRes = result.data;
                let dataList = [];

                if (result.count > 0) { // เช็คจำนวนข้อมูล
                    setRowGameList(result.count);
                }

                if (specials === "new" || specials === "hot") {  // จัดข้อมูล ถ้าเป็น Type new, hot
                    if (dataRes === null) {
                        dataList = [];
                    }
                    else {
                        dataList = dataRes;
                    }
                }
                else {
                    if (dataRes === null) { // เอาข้อมูลเข้าไปเก็บในตัวแปร โดยกรองจาก ค่าย และหมวดหมู่
                        dataList = itemsGameList[camp][type];
                    }
                    else {
                        dataList = dataRes;
                    }
                }

                if (statusUseScroll) { // เช็คสถานะการเลื่อน Scroll ว่าจะข้อมูลรีเฟรช หรือข้อมูลจาก Scroll
                    let gameArrOld = (itemsGameList[camp][type]) === undefined ? [] : itemsGameList[camp][type];
                    let dataArr = [...gameArrOld, ...dataList]; // จัดกรุ๊ปข้อมูลใหม่ และเก่า
                    dataArr = dataArr.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.id === value.id
                        ))
                    );

                    myObj = { // นำข้อมูลไปแทนที่
                        ...itemsGameList,
                        [camp]: {
                            ...itemsGameList[camp],
                            [type]: dataArr
                        }
                    };
                }
                else {
                    if (itemsGameList === false) { // เช็คว่ามีการเซ็ทข้อมูลแล้วหรือยัง
                        myObj = { // นำข้อมูลไปแทนที่
                            [camp]: {
                                [type]: dataList
                            }
                        };
                    }
                    else {
                        myObj = { // นำข้อมูลไปแทนที่
                            ...itemsGameList,
                            [camp]: {
                                ...itemsGameList[camp],
                                [type]: dataList
                            }
                        };
                    }
                }

                dispatch(setGameAll(myObj));
                setProcessing(false);
            }

            setProcessingGame(false);
        });
    };

    const renderTypeGame = (type, itemList) => { // เขียนข้อมูลหมวดหมู่เกม
        // console.log(itemList) 
        let selectType = type;

        if (menuPage.typeGame === 1 || menuPage.typeGame === 2 || menuPage.typeGame === 3 || menuPage.typeGame === 4 || menuPage.typeGame === 12) {
            selectType = menuPage.typeGame;
        }
        else {
            selectType = type === 200 ? 200 : type === 201 ? 201 : type;
        }

        setSelectTypeData({
            tab: "game",
            type: selectType,
            name: "",
            itemList: itemList
        });

        setProcessingType(true);
    };

    const filterGameType = () => {
        if (itemsGameList !== false) {
            let selectType = selectTypeData.type;
            if (itemsGameList[type] !== undefined) {
                if (itemsGameList[type][selectType] !== undefined) {
                    let dataArr = itemsGameList[type][selectType];
                    return dataArr;
                }
                else {
                    return undefined;
                }
            }
            else {
                return undefined;
            }
        }
        else {
            return undefined;
        }
    };

    const setDataAll = (data) => {
        if (itemsGameList === false) {
            loadData(type, data, "", 12, 0, false, (data === 0 || data === 99) ? "" : "hot");
        }
        else {
            if (itemsGameList[type] !== undefined) {
                let countAll = (itemsGameList[type]).length;
                loadData(type, data, "", 12, countAll, false, (data === 0 || data === 99) ? "" : "hot");
            }
            else {
                loadData(type, data, "", -1, 0, false, (data === 0 || data === 99) ? "" : "hot");
            }
        }
    };

    const loadDefault = () => {
        let typeArr = [
            {
                id: 99,
                image: null,
                name: "ทั้งหมด"
            },
            {
                id: 200,
                image: null,
                name: "ยอดฮิต"
            },
            {
                id: 201,
                image: null,
                name: "มาใหม่"
            }
        ];

        let dataArr = [...typeArr, ...itemsTypeList.type];
        renderTypeGame(99, dataArr);

        setDataAll(99);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [type]);

    useEffect(() => {
        if(isUserLoggedIn()) {
            if((gamecode !== undefined && popup !== undefined) && (gamecode !== null && popup !== null) && (gamecode !== "" && popup !== "")) {
                if(popup === "show") {
                    setProcessingPopup(true);
                    dispatch(setPopupGameData({
                        show: true,
                        camp: type.toLowerCase(), 
                        gamecode: gamecode
                    }));
                }
                else {
                    setProcessingPopup(false);
                }
            }
            else {
                setProcessingPopup(false);
            }
        }
        else { 
            setProcessingPopup(false);
        }

    }, [gamecode, popup, isUserLoggedIn()]);

    useEffect(() => {
        if (processingPopup === false) {
            if (menuPage !== null) {
                if (itemsTypeList !== null) {
                    if (itemsTypeList.specials !== "") {
                        let specialType = itemsTypeList.specials.split(",");
                        let selectTypeNew = specialType.find((str) => str === "new");
                        let selectTypeHot = specialType.find((str) => str === "hot");

                        if (selectTypeNew === undefined && selectTypeHot === undefined) {
                            let dataArr = itemsTypeList.type;

                            renderTypeGame(0, dataArr);
                            setDataAll(0);
                        }
                        else {
                            loadDefault();
                        }
                    }
                    else {
                        loadDefault();
                    }
                }
                else {
                    loadGameCategory(type).then((result) => {
                        if (result !== null) {
                            dispatch(setTypeAll({
                                type: result.type,
                                specials: result.specials
                            }));
                        }
                        else {
                            dispatch(setTypeAll({
                                type: [],
                                specials: ""
                            }));
                        }
                    });
                }
            }
        }
    }, [processingPopup, type, itemsTypeList, menuPage]);

    useEffect(() => {
        if (processingPopup === false) {
            if (processingType) {
                let specials = "hot";
                let selectType = selectTypeData.type;

                if (selectTypeData.type === 200) {
                    specials = "hot";
                }
                else if (selectTypeData.type === 201) {
                    specials = "new";
                }
                else {
                    specials = "";
                }

                if (itemsGameList !== false) {
                    let gameTypeList = filterGameType();
                    let countAll = 0;

                    if (gameTypeList === undefined) {
                        countAll = 0;
                    }
                    else {
                        countAll = gameTypeList.length;
                    }

                    if (countAll <= 0) {
                        loadData(type, selectType, "", 12, countAll, false, specials);
                    }
                    else {
                        if (selectTypeData.type === 200 || selectTypeData.type === 201) {
                            loadData(type, selectType, "", -1, 0, false, specials);
                        }
                        else {
                            loadData(type, selectType, "", 12, countAll, true, specials);
                        }
                    }
                }
            }
        }
    }, [processingPopup, selectTypeData]);

    // useEffect(() => {
    //     setItemsTypeList(null);
    // }, [type]);

    // useEffect(() => {
    //     let selectType = selectTypeData.type;
    //     console.log(processingType)
    //     if(processingType === false) {
    //         console.log(itemsTypeList)
    //         if (itemsTypeList !== undefined && itemsTypeList !== null) {
    //             setSelectTypeData({
    //                 tab: "game",
    //                 type: selectType === 200 ? 200 : selectType === 201 ? 201 : selectType,
    //                 name: "",
    //                 itemList: itemsTypeList
    //             });
    //         }
    //     }
    // }, []);

    // console.log(selectTypeData)

    return (
        processingPopup === false && (
            <div className="GameApp">
                <SelectType selectTypeData={selectTypeData} onSelectType={setSelectTypeData}></SelectType>
                {
                    processing === false && (
                        <>
                            {
                                gameNull !== null && (
                                    <>

                                        <GameCamp camp={type} loadData={loadData} processingGame={processingGame} setProcessingGame={setProcessingGame} rowGameList={rowGameList} selectTypeData={selectTypeData} filterGameType={filterGameType}></GameCamp>

                                        {
                                            processingGame && (
                                                <GameLoad />
                                            )
                                        }
                                    </>
                                )
                            }
                        </>
                    )
                }

            </div>
        )

    );
}

export default GameCampApp;
const SET_BANK_LIST = 'app/SET_BANK_LIST';
const SET_BANK_OPEN = 'app/SET_BANK_OPEN';

function setBankOpen(data) {
    return {
        type: SET_BANK_OPEN,
        payload: {
            items: data
        }
    }
}

function setBankList(data) {
    return {
        type: SET_BANK_LIST,
        payload: {
            items: data
        }
    }
}

export {
    SET_BANK_LIST,
    SET_BANK_OPEN,
    setBankList,
    setBankOpen
}
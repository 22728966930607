import { useDispatch, useSelector } from 'react-redux';

// Redux
import { openInviteFriend } from "./../../../../../@core/redux/actions/affiliate/index";

// Component
import ContentInvite from './component/Content';
import ModalApp from "./../../components/ModalFull";

const InviteApp = (props) => {
    const dispatch = useDispatch();
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);

    const onShow = (val) => {
        if (val === false) {
            document.title = process.env.REACT_APP_OG_SITE_NAME+ " - หน้าหลัก";
        }

        dispatch(openInviteFriend({ ...props.linkFriend, show: val }));
    };

    return (
        <ModalApp show={props.linkFriend.show} onShow={onShow}>
            <ContentInvite reduxAppCMS={reduxAppCMS} linkFriend={props.linkFriend} onShow={onShow}></ContentInvite>
        </ModalApp>
    );
}

export default InviteApp;
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';

// Check Auth
import { isUserLoggedIn } from "./../../../../@core/auth/utils";

// @Utility
import filterImages from "./../../../../@utility/app/filterImages";

// Redux
import { openGetCredit, openInviteFriend } from "./../../../../@core/redux/actions/affiliate/index";
import { setHomeMenuType } from "./../../../../@core/redux/actions/home/index";
import { setDataAlert } from "./../../../../@core/redux/actions/app/alert";
import { setOpenWheelStatus } from "./../../../../@core/redux/actions/app/wheel";

// Component
import ProgressiveImage from "./ProgressiveImage";

// CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function BrannerSlide({ isLargeScreen }) {
    const { path } = useRouteMatch();
    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    const reduxAppScrollPosition = useSelector(state => state.reduxAppScrollPosition.top);
    const [pathName, setPathName] = useState("/");
    const [dataList, setDataList] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();

    const settings = {
        infinite: true,
        // fade: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 600,
        autoplaySpeed: 3000,
        arrows: isLargeScreen ? true : false,
        accessibility: true
    };

    const useStyles = makeStyles(() => ({
        cardCoverCamp: {
            width: "100%",
            height: "100%",
            cursor: "pointer"
        }
    }));

    const classes = useStyles();

    const gotoPage = (item) => {
        let page = item.image_link;
        let typeAction = item.image_link_type;

        if(typeAction === 4) {
            if (page === "affiliate") {
                if(isUserLoggedIn() === false) {
                    openBox("รับทรัพย์");
                }
                else {
                    dispatch(openGetCredit({
                        tab: "affiliate",
                        show: true,
                        type: null
                    }));
                }
            }
            else if (page === "inviteFrined") {
                if(isUserLoggedIn() === false) {
                    openBox("เชิญเพื่อน");
                }
                else {
                    dispatch(openInviteFriend({
                        show: true
                    }));
                }
            }
            else if (page === "wheelSpin") {
                if(isUserLoggedIn() === false) {
                    openBox("กงล้อลุ้นโชค");
                }
                else {
                    dispatch(setOpenWheelStatus(true));
                }
            }
        }
        else if(typeAction === 3) {
            let menuList = dataSetting.menu_camp;
            let dataObj = {};

            let index = menuList.findIndex(item => item.code === page);
            dataObj = menuList[index];
            dataObj.id = index;

            if(dataObj !== undefined && dataObj !== null) {
                dispatch(setHomeMenuType({
                    id: dataObj.id,
                    renderPage: dataObj.code,
                    pageTitle: dataObj.title,
                    game_camp_image: dataObj.game_camp_image,
                    column: {
                        desktop: dataObj.column_desktop,
                        mobile: dataObj.column_mobile
                    },
                    typeGame: dataObj.game_camp_type
                }));

                window.scrollTo({
                    top: reduxAppScrollPosition,
                    left: 0,
                    behavior: "smooth"
                });        
            }
        }
        else if(typeAction === 1) {
            window.location.href = page;
        }
        else {
            history.push(page);
        }
    };

    const openBox = (page) => {
        dispatch(setDataAlert({
            type: "warning",
            title: "แจ้งเตือน",
            text: `กรุณาเข้าสู่ระบบก่อนเข้าใช้งาน "${page}"`,
            action: true,
            redirect: "/login",
            show: true,
            buttonText: "ตกลง"
        }));
    };

    useEffect(() => {
        setPathName(window.location.pathname);
    }, [window.location.pathname, path]);

    useEffect(() => {
        if (dataSetting.agent_image !== null) {
            const dataImages = filterImages(dataSetting.agent_image, "slide")
            setDataList(dataImages);
        }
    }, [dataSetting.agent_image]);

    return (
        pathName === "/" && (
            dataList !== null && (
                dataList.length > 0 && (
                    <div id="Branner">
                        <Slider {...settings}>
                            {
                                dataList.map((item) => {
                                    return (
                                        <div key={item.id}>
                                            <ProgressiveImage className={classes.cardCoverCamp} placeholder="/images/bluring/1000x250.jpg" src={isLargeScreen ? item.image_picture : item.picture_mobile} onClick={() => gotoPage(item)}></ProgressiveImage>
                                            {/* <img className={classes.cardCoverCamp} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" onClick={() => gotoPage(item)} /> */}
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                )
            )
        )
    );
}
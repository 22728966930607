// import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import * as clipboard from 'clipboard-polyfill/text';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

// @Utility
import copyText from "./../../../../../../../@utility/app/copyText";

// Services
// import { loadDataAll } from "./../../../../@core/services/affiliateService";

// Redux
import { setDataAlert } from "./../../../../../../../@core/redux/actions/app/alert";
// import { openInviteFriend } from "./../../../../@core/redux/actions/affiliate/index";

// Component
// import ModalApp from "./ModalFull";

//  CSS
import styles from "./../../../../assets/css/Style.module.css";
import modalStyles from "./../../../../assets/css/Modal.module.css";

const Friends = ({ dataObj, onShow, reduxAppCMS }) => {
    // const [dataObj, setDataObj] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();

    const useStyles = makeStyles((theme) => ({
        boxPadding: {
            padding: "12px 0px",
            borderRadius: "6px",
            fontSize: "18px",
            color: "white"
        },
        boxBg: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white"
        },
        socialBorder: {
            border: "solid 2px white",
            borderRadius: "6px",
            padding: "0px 6px",
            height: "56px",
            [theme.breakpoints.down('xs')]: {
                height: "50px"
            }
        },
        socialSize: {
            position: "relative",
            width: "96%",
            height: "auto",
            marginTop: "8px",
            cursor: "pointer",
            '&:hover': {
                top: "2px"
            }
        },
        btnCopy: {
            position: "relative",
            height: "56px",
            lineHeight: "56px",
            borderRadius: "6px",
            border: "solid 2px transparent",
            background: reduxAppCMS !== null ? reduxAppCMS["bt-confirm"] : "linear-gradient(180deg, #FFC700 0%, #FF8A00 100%)",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            fontSize: "18px",
            fontWeight: "bold",
            cursor: "pointer",
            '&:hover': {
                top: "2px"
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: "16px",
                height: "50px",
                lineHeight: "50px"
            }
        },
        titleBox: {
            fontSize: "20px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            marginBottom: "12px",
            textAlign: "left"
        },
        btnHistory: {
            position: "relative",
            borderRadius: "6px",
            padding: "6px 16px",
            border: "solid 2px transparent",
            background: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            cursor: "pointer",
            fontSize: "18px",
            '&:hover': {
                top: "2px"
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: "15px"
            }
        },
        textFriend: {
            fontSize: "16px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            [theme.breakpoints.down('xs')]: {
                fontSize: "15px"
            }
        }
    }));

    const classes = useStyles();

    const gotoShare = (url) => {
        window.open(url, "_blank");
    };

    const copyUrl = () => {
        const val = dataObj !== null ? dataObj.shared_link : "-";
        if(copyText(val)) {
            dispatch(setDataAlert({
                type: "success",
                title: "แจ้งเตือน",
                text: "คัดลอกลิงค์สำเร็จ !",
                action: true,
                redirect: "",
                show: true,
                buttonText: "ตกลง"
            }));
        }
    };

    const gotoPage = (page) => {
        history.push(page);
        onShow(false);
    };

    return (
        <>
            <div className={modalStyles.boxTitle}>
                เชิญเพื่อน
            </div>

            <div className={styles.boxMarginFormTop} style={{ marginBottom: "20px" }}>
                {
                    reduxAppCMS !== null && (
                        <img src={reduxAppCMS["image_invite"]} style={{ borderRadius: "16px", width: "100%" }} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                    )
                }
            </div>

            <div className={[styles.boxMarginTop, "right"].join(" ")} style={{ marginBottom: "20px" }}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <div className={classes.textFriend}>
                            ลิงค์สำหรับเชิญเพื่อน
                        </div>
                    </Grid>
                    <Grid item>
                        <button className={classes.btnHistory} onClick={() => gotoPage("/history?tableType=affiliate")}>
                            ประวัติเชิญเพื่อน
                        </button>
                    </Grid>
                </Grid>
            </div>

            <div className={[classes.boxPadding, classes.boxBg, "center", styles.boxMarginTop].join(" ")} style={{ marginBottom: "12px" }}>
                {dataObj !== null ? dataObj.shared_link : "-"}
            </div>

            <div className={[classes.boxPadding, "center", styles.boxMarginTop].join(" ")}>
                <Grid container spacing={1}>
                    <Grid item xs={7} sm={6}>
                        <div className={classes.socialBorder}>
                            <Grid container alignItems="center">
                                <Grid item xs={6} md={7}>
                                    แชร์ลิงค์
                                </Grid>
                                <Grid item xs={6} md={5}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <img src="/images/button/share_fb.webp" className={classes.socialSize} onClick={() => gotoShare(dataObj.shared_facebook)} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <img src="/images/button/share_line.webp" className={classes.socialSize} onClick={() => gotoShare(dataObj.shared_line)} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={5} sm={6}>
                        <div className={classes.btnCopy} onClick={copyUrl}>
                            คัดลอกลิงค์
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default Friends;
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

export default function LogoApp({ typePage }) {
    const history = useHistory();
    const setting = useSelector(state => state.reduxAppMenu.menu_items.agent);

    const useStyles = makeStyles((theme) => ({
        imgLogo: {
            width: "180px",
            height: "auto",
            cursor: "pointer",
            [theme.breakpoints.down('sm')]: {
                width: "150px"
            },
            [theme.breakpoints.down('xs')]: {
                width: typePage === "home" ? "100px" : "130px"
            }
        },
    }));

    const gotoHome = () => {
        window.scrollTo(0, 0);
        history.push("/")
    };

    const classes = useStyles();

    return <img className={classes.imgLogo} src={setting?.agent_image} alt={setting?.agent_code} onClick={() => { gotoHome() }} />;
    // return <img className={classes.imgLogo} src="/images/logo/tonyslot_now.webp" alt={setting?.agent_code} onClick={() => { history.push("/") }} />;
}
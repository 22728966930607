import { useState, useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { useHistory } from 'react-router-dom';

// Icon
// import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

// Component
import OpenGame from "./../../../components/OpenGame";
import ProgressiveImage from "./../../../components/ProgressiveImage";

// CSS
import styles from "./../../../assets/css/Style.module.css";
// import buttonStyles from "./../../../assets/css/Button.module.css";


export default function ListEntertain({ pageName }) {
    // const history = useHistory();
    const slideRef = useRef();
    const imageRef = useRef();

    const entertainList = [
        {
            id: 1,
            name: "ดูหนังออนไลน์",
            image: "/images/entertainment/typeA-movie.webp",
            url: "https://xn--42cg7cudj0a.com/"
        },
        {
            id: 2,
            name: "ดูซีรีส์ดัง",
            image: "/images/entertainment/typeA-series.webp",
            url: "https://xn--42cgm6gej8f8ab4qtb.com/"
        },
        {
            id: 3,
            name: "ดูคลิปโป๊ AV OnlyFans",
            image: "/images/entertainment/typeA-18.webp",
            url: "https://www.xn--l3c0a4a2b.com/"
        }
    ];

    const useStyles = makeStyles((theme) => ({
        cardGameBody: {
            // background: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "white",
            borderRadius: "15px",
            // padding: "9px 18px",
            // padding: "30px",
            [theme.breakpoints.down('xs')]: {
                borderRadius: "0px",
                padding: "15px 5px"
            }
            // padding: "18px"
        },
        gameSize: {
            width: "100%",
            height: "100%",
            borderRadius: "16px",
            cursor: "pointer",
            [theme.breakpoints.down('xs')]: {
                borderRadius: "10px"
            }
            // [theme.breakpoints.down('xs')]: {
            //     height: "160px"
            // }
        },
        cardGameMenu: {
            // borderBottom: "2px solid #F0F1F9",
            padding: "0px 12px"
        },
        cardGameList: {
            padding: "20px 0px",
            [theme.breakpoints.down('xs')]: {
                padding: "20px 0px 0px 0px"
            }
        },
        menuList: {
            border: "2px solid #F0F1F9",
            borderRadius: "18px",
            fontSize: "18px",
            padding: "4px 24px",
            cursor: "pointer",
            position: "relative",
            color: "white",
            '&:hover': {
                background: "linear-gradient(90deg, #360092 0%, #A60E97 100%)"
            }
        },
        gameSizePadding: {
            padding: "10px 7px",
            marginBottom: "10px",
            width: "190px",
            height: "100%",
            [theme.breakpoints.down('sm')]: {
                marginBottom: "0px",
            },
            [theme.breakpoints.down('xs')]: {
                padding: "6px 5px",
                width: "140px"
            }
        },
        pageTypePosition: {
            position: "absolute",
            left: "0px",
            top: "-10px"
        },
        pageTypeSize: {
            width: "64px",
            height: "auto"
        },
    }));

    const classes = useStyles();

    const gotoLink = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <>
            <div className={classes.cardGameBody}>
                <div className={classes.cardGameMenu}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <div className={styles.cardOtherListHeadText}>
                                {pageName}
                            </div>
                        </Grid>
                            {/* <Grid item>
                                <div className={classes.gameMore} onClick={gotoDemo}>
                                    เพิ่มเติม
                                </div>
                            </Grid> */}
                    </Grid>
                </div>
                <div className={classes.cardGameList}>
                    <div className={styles.menuPositionWidthScroll} ref={slideRef}>
                        <div className="flexContent">
                            {
                                entertainList !== null && (
                                    entertainList.map((item, index) => {
                                        return (
                                            <div key={item.id}>
                                                <div className={classes.gameSizePadding}>
                                                    <div className="relative">

                                                        <ProgressiveImage className={classes.gameSize} placeholder="/images/bluring/215x280.jpg" src={item.image} imageRef={imageRef} onClick={() => gotoLink(item.url)}></ProgressiveImage>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

// auth
// import { isUserLoggedIn } from "./../../../../../../../@core/auth/utils";

// Services
import { updatePromotionAuto } from "./../../../../../../../@core/services/promotionService";

// @Utility
import copyText from "./../../../../../../../@utility/app/copyText";
// import currencyFormat from "./../../../../../../../@utility/numbers/currencyFormat";

// CSS
import styles from "./../../../../assets/css/Style.module.css";
import buttonStyles from "./../../../../assets/css/Button.module.css";

// Component
import Switch from "./../../component/Switch";

export default function BoxAutoTwo({ depositItems, openBox, typePage, bankList }) {
    const [promotionSwitch, setPromotionSwitch] = useState(null);
    const homeData = useSelector(state => state.reduxHome.result);
    let snackbarJson = {};
    const [bankAgent, setBankAgent] = useState(null);

    const copyData = (data) => {
        if(copyText(data)) {
            snackbarJson = {
                statusShow: true,
                statusAlert: "success",
                titleAlert: "แจ้งเตือน",
                textAlert: "คัดลอกสำเร็จ !"
            };

            openBox(snackbarJson);
        }
    };

    useEffect(() => {
        if(homeData !== null) {
            setPromotionSwitch(homeData.promotion_auto === 1 ? true : false);
        }
    }, [homeData]);

    useEffect(() => {
        if(promotionSwitch !== null) {
            let promotion_auto = promotionSwitch ? 1 : 0;
            updatePromotionAuto(promotion_auto).then(() => {});
        }
    }, [promotionSwitch]);

    useEffect(() => {
        if(depositItems.bank_agent !== null) {
            let dataAgentBank = depositItems.bank_agent;
            let agentBank = {};

            if(typePage === "truemoney") {
                agentBank = dataAgentBank.filter((item) => item.agent_bank_switfcode === "TRUEMONEY");
            }
            else {
                agentBank = dataAgentBank.filter((item) => item.agent_bank_switfcode !== "TRUEMONEY");
            }

            setBankAgent(agentBank);
        }
    }, [depositItems.bank_agent]);

    return (
        <>
            {
                depositItems.bank_agent !== null && (
                    bankAgent !== null && (
                        bankAgent.map((item, index) => (
                            <div key={index} className={styles.boxDetailTop} style={{ marginBottom: "10px" }}>
                                <div className={styles.boxAccountAgentTitle}>
                                    โอนเงินมาที่บัญชี
                                </div>
                                <div className={[styles.boxDetailAmount, styles.boxDetailAgent].join(" ")} style={{ marginTop: "0px" }}>
                                    <Grid container alignItems="center" spacing={3}>
                                        <Grid item xs={2}>
                                            <img src={item?.agent_bankimg} style={{ width: "100%", borderRadius: "12px" }} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <div className={styles.boxAccountAgentText} style={{ fontWeight: "600" }}>
                                                {item?.agent_bankname}
                                                <br />
                                                ชื่อบัญชี  : {item?.agent_bank_account_name}
                                                <br />
                                                เลขบัญชี : {item?.agent_bank_account}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <div className="center">
                                        <button className={[buttonStyles.btnConfirm, buttonStyles.btnCopy].join(" ")} onClick={() => copyData(item?.agent_bank_account)}>
                                            คัดลอกเลขบัญชี
                                        </button>
                                    </div>
                                    <div className={[styles.boxMarginTop, "center"].join(" ")}>
                                        <div className={styles.bankPromotionComment}>
                                            ** กรุณาใช้ "บัญชีลูกค้าที่ต้องใช้โอน" โอนเงินมาเท่านั้น **
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )
                )
            }

            <div className={styles.boxMarginTop}>
                <div className={styles.boxDetailTop}>
                    <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                        <Grid item>
                            <div className={styles.boxAccountAgentTitle}>
                                เลือกรับโปรโมชั่นอัตโนมัติ
                            </div>
                        </Grid>
                        <Grid item>
                            <Switch
                                isOn={promotionSwitch}
                                onColor="#38C172"
                                handleToggle={() => setPromotionSwitch(!promotionSwitch)}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}
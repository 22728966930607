function validateInput(event, type) {
    let char = String.fromCharCode(event.charCode); // Get the character
    let statusInput = /^[a-zA-Z0-9ก-๙ ]+$/.test(char);
    if (type === "number") {
        statusInput = /^[0-9]+$/.test(char);
    }
    else if (type === "numberDecimal") {
        statusInput = /^[0-9.]+$/.test(char);
    }
    else if (type === "textNumber") {
        statusInput = /^[a-zA-Z0-9]+$/.test(char);
    }
    else if (type === "textSearch") {
        statusInput = /^[a-zA-Z0-9 ]+$/.test(char);
    }
    else if (type === "datetime") {
        statusInput = /^[0-9/: ]+$/.test(char);
    }
    else {
        statusInput = /^[a-zA-Z0-9ก-๙ ]+$/.test(char);
    }

    if (statusInput) return true; // Match with regex 
    else event.preventDefault();
}

export default validateInput;
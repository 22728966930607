import axios from 'axios';
import serviceConfig from "./serviceConfig";
import { getToken } from "./../auth/utils";
import { checkStatusCode } from "./services";

const queryString = require('query-string');
const svConfig = { ...serviceConfig };

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const loadBankAll = async () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
  let config = {
    method: svConfig.method_get,
    url: `/customer/profile/bank`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    if (code === 0 || code === 200) {
      return {
        result: result,
        code: code
      };
    }
    else {
      return {
        result: [],
        code: code
      };
    }
  }
}

const loadBankDepositAll = async () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
  let config = {
    method: svConfig.method_get,
    url: `/customer/payment/deposit/bank/customer/select-list`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    if (code === 0 || code === 200) {
      return {
        result: result,
        code: code
      };
    }
    else {
      return {
        result: [],
        code: code
      };
    }
  }
}

const createBankList = async (data) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
  const {
    bank,
    bankAccountName,
    bankAccount
  } = data;

  let myJson = {
    "swift": bank,
    "name": bankAccountName,
    "account": bankAccount
  };

  let config = {
    method: svConfig.method_put,
    url: `/customer/profile/bank`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: queryString.stringify(myJson)
  };

  const dataPromise = await axios(config).then((response) => response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    if (code === 0 || code === 200) {
      return {
        result: result,
        code: code
      };
    }
    else {
      return {
        result: [],
        code: code
      };
    }
  }
}

const deleteBankList = async (data) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
  let config = {
    method: svConfig.method_delete,
    url: `/customer/profile/bank/${data}`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data);
  const { code } = dataPromise;

  if (checkStatusCode(code)) {
    return code;
  }
}

export {
  loadBankAll,
  loadBankDepositAll,
  createBankList,
  deleteBankList
};
const OPEN_AUTHEN = 'app/login/OPEN_AUTHEN';
// const OPEN_REGISTER = 'app/register/OPEN_REGISTER';

function openAuthenApp(data) {
    return {
        type: OPEN_AUTHEN,
        payload: {
            items: data
        }
    }
}

// function openRegisterApp(status) {
//     return {
//         type: OPEN_REGISTER,
//         payload: {
//             status: status
//         }
//     }
// }

export {
    OPEN_AUTHEN,
    // OPEN_REGISTER,
    // openAuthenApp,
    openAuthenApp
}
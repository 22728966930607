import { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Component
import Register01App from "./Register-01";
import SnackbarSet from "./../../SnackbarSet";

// @Utility
import { getStorage } from "./../../../../../../@utility/app/storage";

export default function RegisterApp(props) {
    const childRef = useRef();
    const [bankList, setBankList] = useState([]);
    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    const [register, setRegister] = useState({
        "username": "",
        "password": "",
        "phone": "",
        "affiliate": "",
        "refer": "",
        "know_web": "",
        "bank": "",
        "bank_account": "",
        "bank_account_name": "",
        "tm_phone": "",
        "line_data": props.data,
        "allusion": getStorage("allusion")
    });

    const snackbar = (da) => {
        childRef.current.snackbar(da);
    }

    const updateRegister = (da) => {
        setRegister({ ...register, ...da })
    }


    useEffect(() => {
        setBankList(dataSetting.agent_setting_bank);
    }, [dataSetting.agent_setting_bank]);

    return (
        <>
            <SnackbarSet ref={childRef} />
            {
                !props.process && (
                    <Register01App update={updateRegister} onProcess={props.onProcess} onShow={props.onShow} register={register} snackbar={snackbar} setDataAlert={props.setDataAlert} bankList={bankList} />
                )
            }
        </>
    );
}
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isUserLoggedIn } from "./../../../../../@core/auth/utils";

// Redux
import * as authActions from "./../../../../../@core/redux/actions/auth/index";

// Component
import LogoApp from "./../Logo";
import Sidebar from "./../Sidebar";
import NavbarCredit from "./../NavbarCredit";

export default function NavbarDesktop({ homeData, reduxAppCMS }) {
    const dispatch = useDispatch();;
    // const history = useHistory();

    const useStyles = makeStyles((theme) => ({
        navbarFixed: {
            position: "fixed",
            top: "0px",
            width: "100%",
            left: "0px",
            zIndex: "1200"
        },
        navbarPosition: {
            background: reduxAppCMS !== null ? reduxAppCMS["bg-navbar"] : "#270058",
            width: "100%"
        },
        navbarPositionGame: {
            background: reduxAppCMS !== null ? reduxAppCMS["bg-navbar"] : "#270058",
            width: "100%",
            padding: "10px 0px 10px 0px",
            fontSize: "20px"
        },
        navbarContent: {
            padding: "0px 22px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            [theme.breakpoints.down('xs')]: {
                padding: "0px 8px"
            }
        },
        navbarContentMore: {
            padding: "0px 22px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            [theme.breakpoints.down('xs')]: {
                padding: "0px 4px 0px 8px"
            }
        },
        cardPlayerHeight: {
            height: "64px",
            [`${theme.breakpoints.up('sm')} and (max-device-height: 1000px)`]: {
                height: "30px",
            }
        },
        iconPlayerClose: {
            width: "40px",
            position: "relative",
            cursor: "pointer",
            '&:hover': {
                top: "2px"
            },
            [`${theme.breakpoints.up('sm')} and (max-device-height: 1000px)`]: {
                width: "28px",
            }
        },
        iconPlayerPadding: {
            [`${theme.breakpoints.up('sm')} and (max-device-height: 1000px)`]: {
                padding: "0 4px 0 4px !important;",
                fontSize: "14px"
            }
        },
        loginClick: {
            background: "transparent",
            width: "160px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            position: "relative",
            cursor: "pointer",
            borderRadius: "10px",
            fontSize: "18px",
            padding: "10px",
            border: "1px solid white",
            fontWeight: "500",
            '&:hover': {
                top: "2px"
            },
            [theme.breakpoints.down('xs')]: {
                borderRadius: "5px",
                padding: "6px 4px",
                fontSize: "14px",
                width: "85px"
            }
        },
        registerClick: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-confirm"] : "linear-gradient(180deg, #FFC700 0%, #FF8A00 100%)",
            width: "160px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            position: "relative",
            cursor: "pointer",
            borderRadius: "10px",
            fontSize: "18px",
            padding: "10px",
            fontWeight: "500",
            '&:hover': {
                top: "2px"
            },
            [theme.breakpoints.down('xs')]: {
                borderRadius: "5px",
                padding: "6px 4px",
                fontSize: "14px",
                width: "85px"
            }
        },
        alertDemo: {
            background: "#A80000",
            color: "white",
            padding: "4px 12px",
            borderRadius: "6px",
            [theme.breakpoints.down('xs')]: {
                fontSize: "16px"
            }
        },
        alertTrue: {
            background: "linear-gradient(180deg, #06C755 0%, #00712E 100%)",
            color: "white",
            padding: "4px 12px",
            borderRadius: "6px",
            [theme.breakpoints.down('xs')]: {
                fontSize: "16px"
            }
        }
    }));

    const classes = useStyles();

    const openLogin = () => {
        let myObj = {
            tab: "login",
            show: true
        };

        dispatch(authActions.openAuthenApp(myObj));
    };

    const openRegister = () => {
        let myObj = {
            tab: "register",
            show: true
        };

        dispatch(authActions.openAuthenApp(myObj));
    };

    return (
        <div className={classes.navbarFixed}>
            <div className={classes.navbarPosition}>
                <div className={classes.navbarContentMore}>
                    <Grid container alignItems="center" className={classes.cardPlayerHeight}>
                        <Grid item xs={3} style={{ marginTop: "4px" }}>
                            <LogoApp typePage="home"></LogoApp>
                        </Grid>
                        <Grid item xs={9}>
                            {
                                isUserLoggedIn() ?
                                    <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
                                        <Grid item>
                                            <NavbarCredit></NavbarCredit>
                                        </Grid>
                                        <Grid item>
                                            <Sidebar homeData={homeData} isUserLoggedIn={isUserLoggedIn}></Sidebar>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
                                        <Grid item>
                                            <div className={classes.loginClick} onClick={openLogin}>
                                                เข้าสู่ระบบ
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div className={classes.registerClick} onClick={openRegister}>
                                                สมัครสมาชิก
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <Sidebar homeData={homeData} isUserLoggedIn={isUserLoggedIn()}></Sidebar>
                                        </Grid>
                                    </Grid>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}
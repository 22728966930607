import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// CSS
import modalStyles from "./../../../../assets/css/Modal.module.css";

// Component
import FormDeposit from './FormDeposit';
import FormAuto from './FormAuto';
import FormAutoTwo from './FormAutoTwo';
import SnackbarSet from "./../../../../components/SnackbarSet";
import BackButton from "./../../../../components/BackButton";

const DepositFormApp = ({ depositItems }) => {
    const childRef = useRef();
    const [typePage, setTypePage] = useState("");
    const [bankList, setBankList] = useState(null);
    const { type } = useParams();
    const history = useHistory();
    const [selectBank, setSelectBank] = useState(0);
    const dataBankCustomerList = useSelector(state => state.reduxDeposit.dataBank);

    const renderFormDeposit = () => {
        if(type === "automatic") {
            if(depositItems !== null) {
                if(depositItems.mode === "pay") {
                    return (
                        <FormAuto typePage={typePage} bankList={bankList} depositItems={depositItems} selectBank={selectBank}></FormAuto>
                    );
                }
                else {
                    if(depositItems.nick_name) {
                        return (
                            <FormAutoTwo typePage={typePage} bankList={bankList} depositItems={depositItems} selectBank={selectBank}></FormAutoTwo>
                        );
                    }
                    else {
                        return (
                            <FormAuto typePage={typePage} bankList={bankList} depositItems={depositItems} selectBank={selectBank}></FormAuto>
                        );
                    }
                }
            }
        }
        else if(type === "truemoney") {
            return (
                <FormAutoTwo typePage={typePage} bankList={bankList} depositItems={depositItems} selectBank={selectBank}></FormAutoTwo>
            );
        }
        else {
            return (
                <FormDeposit typePage={typePage} bankList={bankList} depositItems={depositItems} selectBank={selectBank} setSelectBank={setSelectBank}></FormDeposit>
            );
        }
    };

    useEffect(() => {
        if(depositItems.mode === "deposit") {
            if(dataBankCustomerList !== null) {
                let dataRes = dataBankCustomerList;
                let dataFilter = [];
    
                if(type === "bank") {
                    if(dataRes.length > 0) {
                        if(dataRes.length > 1) {
                            setBankList(dataRes);
                        }
                        else {
                            setBankList(dataRes);
                            setSelectBank(dataRes[0].id);
                        }
                    }
                    else {
                        setBankList([]);
                        setSelectBank(0);
                    }
                }
                else {
                    if(type === "truemoney") {
                        dataFilter = dataRes.filter((item) => (item.swift_code === "TRUEMONEY"));
                    }
                    else {
                        dataFilter = dataRes.filter((item) => (item.swift_code !== "TRUEMONEY"));
                    }
    
                    if(dataFilter.length > 0) {
                        setBankList(dataFilter);
                        setSelectBank(dataFilter[0].id);
                    }
                    else {
                        setBankList([]);
                        setSelectBank(0);
                    }
                }
            }
        }
        else {
            setBankList(null);
            setSelectBank(0);
        }
    }, [dataBankCustomerList]);

    useEffect(() => {
        setTypePage(type);
    }, [type]);

    return (
        <>
            <SnackbarSet ref={childRef} />
            <div className={modalStyles.backButtonFull} onClick={() => { history.push("/deposit/home") }}>
                {
                    depositItems !== null && (
                        depositItems.mode !== "pay" && (
                            (depositItems?.channel?.automatic !== false || depositItems?.channel?.truemoney !== false) && (
                                <BackButton></BackButton>
                            )
                        )
                    )
                }
            </div>
            <div id="DepositApp">
                {
                    renderFormDeposit()
                }
            </div>
        </>
    );
}

export default DepositFormApp;
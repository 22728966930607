const SET_DATA_CONTACT = 'app/SET_DATA_CONTACT';

function setDataContact(data) {
    return {
        type: SET_DATA_CONTACT,
        payload: {
            items: data
        }
    }
}

export {
    SET_DATA_CONTACT,
    setDataContact
}
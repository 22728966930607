const SET_DATA_CASHBACK = 'app/SET_DATA_CASHBACK';
const OPEN_CASHBACK = 'app/OPEN_CASHBACK';

function setCashbackData(data) {
    return {
        type: SET_DATA_CASHBACK,
        payload: {
            items: data
        }
    }
}

function openCashback(data) {
    return {
        type: OPEN_CASHBACK,
        payload: {
            items: data
        }
    }
}

export {
    OPEN_CASHBACK,
    SET_DATA_CASHBACK,
    openCashback,
    setCashbackData
}
import { 
    SET_DATA_DIALOG 
} from '../../actions/app/dialog';

const initialState = {
    dialogData: {
        statusShow: false,
        type: null,
        image: null,
        title: null,
        content: null,
        action: null
    }
};

export default function reducer(state = initialState, action) {
    switch(action.type) {
        case SET_DATA_DIALOG:
            return { ...state, dialogData: action.payload.items };
        default: 
            return state;
    } 
}
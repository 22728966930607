export default function LoadSpinner({ textProcess }) {
    return (
        <div className="loadPage" style={{ zIndex: "9999" }}>
            <div className="loadderCenter">
                <div className="lds-shape">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div>
                            <ul style={{ paddingInlineStart: "0px", marginBlockStart: "0px", marginBlockEnd: "0px" }}>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>
                    </div>

                    <div className="textLoadding">
                        {
                            (textProcess !== undefined && textProcess !== null) ?
                            textProcess
                            :
                            "กำลังโหลดข้อมูล..."
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
function loadImagesFetch(divide) {
    let maxLength = parseInt(((Array.from(document.images)).length) / divide);
    let countRow = 0;
    Promise.all(Array.from(document.images).map(img => {
        if(countRow === maxLength) {
            return new Promise(resolve => {
                img.addEventListener('load', () => resolve(true));
            });
        }
        else {
            countRow++;
            if (img.complete) {
                return Promise.resolve(img.naturalHeight !== 0)
            };

            return new Promise(resolve => {
                img.addEventListener('load', () => resolve(true));
            });
        }
    })).then((results) => {
        if (maxLength === (parseInt(results.length / divide))) {
            return true;
        }
    });

    if (maxLength === countRow) {
        return true;
    }
}

export default loadImagesFetch;
import { loadGameHitAll } from "./../../../../@core/services/gameService";

const SET_CAMP_ALL = 'app/SET_CAMP_ALL';
const SET_CATEGORY_ALL = 'app/SET_CATEGORY_ALL';
const SET_GAME_ALL = 'app/SET_GAME_ALL';
const SET_GAME_LAST_ALL = 'app/SET_GAME_LAST_ALL';
const SET_GAME_HIT_ALL = 'app/SET_GAME_HIT_ALL';
const SET_GAME_JACKPOT_ALL = 'app/SET_GAME_JACKPOT_ALL';
const SET_TYPE_ALL = 'app/SET_TYPE_ALL';
const SET_PROCESSING_GAME = 'app/SET_PROCESSING_GAME';
const SET_GAME_HOT_ALL = 'app/SET_GAME_HOT_ALL';
const SET_POPUP_GAME_DATA = 'app/SET_POPUP_GAME_DATA';

function setCampAll(data) {
    return {
        type: SET_CAMP_ALL,
        payload: {
            items: data
        }
    }
}

function setCategoryAll(data) {
    return {
        type: SET_CATEGORY_ALL,
        payload: {
            items: data
        }
    }
}

function setGameAll(data) {
    return {
        type: SET_GAME_ALL,
        payload: {
            items: data
        }
    }
}

function setGameLastAll(data) {
    return {
        type: SET_GAME_LAST_ALL,
        payload: {
            items: data
        }
    }
}

function setGameHitAll() {
    return dispatch => {
        loadGameHitAll().then((data) => {
            dispatch({
                type: SET_GAME_HIT_ALL,
                payload: {
                    items: data
                }
            })
        });
    }
}

function setGameHotAll(data) {
    return {
        type: SET_GAME_HOT_ALL,
        payload: {
            items: data
        }
    }
}


function setGameJacpotAll(data) {
    return {
        type: SET_GAME_JACKPOT_ALL,
        payload: {
            items: data
        }
    }
}

function setTypeAll(data) {
    return {
        type: SET_TYPE_ALL,
        payload: {
            items: data
        }
    }
}

function setProcessingGame(data) {
    return {
        type: SET_PROCESSING_GAME,
        payload: {
            items: data
        }
    }
}

function setPopupGameData(data) {
    return {
        type: SET_POPUP_GAME_DATA,
        payload: {
            items: data
        }
    }
}

export {
    SET_CAMP_ALL,
    SET_CATEGORY_ALL,
    SET_GAME_ALL,
    SET_GAME_LAST_ALL,
    SET_GAME_HIT_ALL,
    SET_GAME_JACKPOT_ALL,
    SET_TYPE_ALL,
    SET_PROCESSING_GAME,
    SET_GAME_HOT_ALL,
    SET_POPUP_GAME_DATA,
    setCampAll,
    setCategoryAll,
    setGameAll,
    setGameLastAll,
    setGameHitAll,
    setGameJacpotAll,
    setTypeAll,
    setProcessingGame,
    setGameHotAll,
    setPopupGameData
}
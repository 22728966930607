import {
    SET_DATA_POSITION
} from '../../actions/app/scrollPosition';

const initialState = {
    top: 0
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_DATA_POSITION:
            return { ...state, top: action.payload.items };
        default:
            return state;
    }
}
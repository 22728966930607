import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

// Icon
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// CSS
import styles from "./../assets/css/Style.module.css";
import buttonStyles from "./../assets/css/Button.module.css";

const SelectType = (props) => {
    const [itemsTypeList, setItemsTypeList] = useState([]);
    const history = useHistory();
    let pathText = window.location.pathname;
    let campType = pathText.split('/').pop();
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);

    const useStyles = makeStyles((theme) => ({
        backPage: {
            color: reduxAppCMS !== null ? reduxAppCMS["bg-main"] : "black",
            cursor: "pointer",
            fontSize: "20px",
            fontWeight: "600",
            [theme.breakpoints.down('xs')]: {
                fontSize: "14px"
            }
        },
        iconSize: {
            width: "32px",
            height: "32px",
            [theme.breakpoints.down('xs')]: {
                width: "18px",
                height: "18px"
            }
        },
        titleText: {
            fontSize: "20px",
            fontWeight: "600",
            paddingLeft: "20px",
            [theme.breakpoints.down('xs')]: {
                fontSize: "16px",
                paddingLeft: "10px"
            }
        }
    }));

    const classes = useStyles();

    const setTypeData = (val) => {
        props.onSelectType({ ...props.selectTypeData, type: val })
    };

    const gotoPage = () => {
        let pathName = window.location.pathname;

        if(pathName === "/games/search") {
            if(history.length <= 2) {
                history.push("/");
            }
            else {
                history.goBack();
            }
        }
        else {
            history.push("/");
        }
    };

    const renderTitle = () => {
        if (campType === "jackpot" || campType === "customer" || campType === "jackpot_customer") {
            switch (campType) {
                case "jackpot":
                    return "10 เกมแตกบ่อย";
                case "customer":
                    return "10 เกมยอดฮิต";
                case "jackpot_customer":
                    return "10 เกมแตกล่าสุด";
                default:
                    return null;
            }
        }
        else if (campType === "like") {
            return "เกมชื่นชอบ";
        }
        else if (campType === "gameLast") {
            return "เล่นล่าสุด";
        }
        else {
            return campType
        }
    };

    const renderContentCamp = () => {
        return (
            <>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item style={{ borderRight: "2px solid #F0F1F9" }} xs={4} md={2} >
                        <div className={classes.backPage} onClick={() => { history.push("/") }}>
                            <Grid container alignItems="center" justifyContent="center" spacing={2}>
                                <Grid item xs={2}>
                                    <ArrowBackIosIcon className={classes.iconSize}></ArrowBackIosIcon>
                                </Grid>
                                <Grid item xs={10}>
                                    ย้อนกลับ
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={8} md={10}>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <div className={classes.titleText}>
                                    {
                                        props.selectTypeData.name
                                    }
                                </div>
                            </Grid>
                            <Grid item>
                                <div className={styles.menuPosition} onClick={() => { history.push("/games/search") }}>
                                    <SearchIcon className={styles.iconSize}></SearchIcon>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <div className={[styles.menuPosition, styles.menuPositionWidthScroll].join(" ")} style={{ marginLeft: "0px", marginRight: "0px" }}>
                            <div className="flexContent">
                                {
                                    itemsTypeList.map((item) => {
                                        return (
                                            <div key={item.id} className={props.selectTypeData.type === item.id ? [buttonStyles.btnMenuType, buttonStyles.btnMenuTypeActive, buttonStyles.btnMenuTypeMargin].join(" ") : [buttonStyles.btnMenuType, buttonStyles.btnMenuTypeMargin].join(" ")} onClick={() => setTypeData(item.id)}>
                                                {item.name}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </>
        );
    };

    const renderContentGame = () => {
        return (
            <>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item style={{ borderRight: `2px solid ${reduxAppCMS !== null ? reduxAppCMS["bg-foter"] : "#F0F1F9"}` }} xs={4} md={2} >
                        <div className={classes.backPage} onClick={() => { history.push("/") }}>
                            <Grid container alignItems="center" justifyContent="center" spacing={2}>
                                <Grid item xs={2}>
                                    <ArrowBackIosIcon className={classes.iconSize}></ArrowBackIosIcon>
                                </Grid>
                                <Grid item xs={10}>
                                    ย้อนกลับ
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={8} md={10}>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <div className={classes.titleText}>
                                    {
                                        renderTitle()
                                    }
                                </div>
                            </Grid>
                            <Grid item>
                                <div className={styles.menuPosition} onClick={() => { history.push("/games/search") }}>
                                    <SearchIcon className={styles.iconSize}></SearchIcon>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <div className={[styles.menuPosition, styles.menuPositionWidthScroll].join(" ")} style={{ marginLeft: "0px", marginRight: "0px" }}>
                            <div className="flexContent">
                                {
                                    itemsTypeList.length > 0 ?
                                        <>
                                            {
                                                itemsTypeList.map((item) => {
                                                    return (
                                                        <div key={item.id} className={props.selectTypeData.type === item.id ? [buttonStyles.btnMenuType, buttonStyles.btnMenuTypeActive, buttonStyles.btnMenuTypeMargin].join(" ") : [buttonStyles.btnMenuType, buttonStyles.btnMenuTypeMargin].join(" ")} onClick={() => setTypeData(item.id)}>
                                                            {item.name}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                        :
                                        <div className={[buttonStyles.btnMenuType, buttonStyles.btnMenuTypeActive, buttonStyles.btnMenuTypeMargin].join(" ")}>
                                            ทั้งหมด
                                        </div>
                                }
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </>
        );
    };

    const renderContentOther = () => {
        return (
            <>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item style={{ borderRight: `2px solid ${reduxAppCMS !== null ? reduxAppCMS["bg-foter"] : "#F0F1F9"}` }} xs={4} md={2}>
                        <div className={classes.backPage} onClick={() => { gotoPage() }}>
                            <Grid container alignItems="center" justifyContent="center" spacing={2}>
                                <Grid item xs={2}>
                                    <ArrowBackIosIcon className={classes.iconSize}></ArrowBackIosIcon>
                                </Grid>
                                <Grid item xs={10}>
                                    ย้อนกลับ
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={8} md={10}>
                        <div className={[classes.titleText, "left"].join(" ")}>
                            {
                                props.selectTypeData.name
                            }
                        </div>
                    </Grid>
                </Grid>
            </>
        );
    };

    const renderContent = () => {
        if (props.selectTypeData.tab === "camp") {
            return renderContentCamp();
        }
        else if (props.selectTypeData.tab === "game") {
            return renderContentGame();
        }
        else {
            return renderContentOther();
        }
    };

    useEffect(() => {
        setItemsTypeList(props.selectTypeData.itemList);
    }, [props]);

    return (
        <>
            <div className={styles.boxFilter}>
                {
                    renderContent()
                }
            </div>
        </>
    );
};

SelectType.defaultProps = {
    selectTypeData: {
        tab: "",
        type: 0,
        name: "",
        itemList: []
    }
}

export default SelectType;
// Component
import ContentPassword from "./component/Content";
import NotiEvent from "./../../components/NotiEvent";

const PasswordApp = () => {
    document.title = process.env.REACT_APP_OG_SITE_NAME+ " - เปลี่ยนรหัสผ่าน";

    return (
        <>
            <ContentPassword></ContentPassword>
            <NotiEvent></NotiEvent>
        </>
    );
}

export default PasswordApp;
import {
    SET_DATA_CONFIRM
} from '../../actions/app/confirm';

const initialState = {
    tab: "",
    title: "",
    text: "",
    action: "",
    data: [],
    show: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_DATA_CONFIRM:
            return { ...state, ...action.payload.items };
        default:
            return state;
    }
}
import axios from 'axios';
import serviceConfig from "./serviceConfig";
import { getToken } from "./../auth/utils";
import { checkStatusCode } from "./services";

const queryString = require('query-string');
const svConfig = { ...serviceConfig };

const loadWithdrawFirst = async () => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    let config = {
        method: svConfig.method_get,
        url: '/customer/payment/withdraw',
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: {}
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

const confirmWithdraw = async (data) => {
    const {
        amount,
        bankId
    } = data;

    let myJson = {};

    let urlpath = "";

    if (process.env.REACT_APP_PAYMENT_V2 === "true" || process.env.REACT_APP_PAYMENT_V2 === true) {
        axios.defaults.baseURL = process.env.REACT_APP_API_URL_V2;
        urlpath = '/customer/payment/withdraw';
        myJson = {
            "amount": amount,
            "bank_id": bankId
        };
    }
    else {
        axios.defaults.baseURL = process.env.REACT_APP_API_URL;
        urlpath = '/customer/payment/withdraw/automatic';
        myJson = {
            "amount": amount,
            "bankId": bankId
        };
    }

    let config = {
        method: svConfig.method_post,
        url: urlpath,
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: queryString.stringify(myJson)
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

const checkWithdraw = async (depositId) => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    let myJson = {
        "id": depositId
    };

    let config = {
        method: svConfig.method_post,
        url: '/customer/payment/withdraw/check',
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: queryString.stringify(myJson)
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

const loadWithdrawRank = async () => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    let config = {
        method: svConfig.method_get,
        url: `/common/section/withdraw`,
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: {}
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        if (code === 0) {
            return result;
        }
        else {
            return [];
        }
    }
}

export {
    loadWithdrawFirst,
    confirmWithdraw,
    checkWithdraw,
    loadWithdrawRank
};
const SET_STATUS_DROPDOWN = 'app/SET_STATUS_DROPDOWN';

function setStatusDropdown(data) {
    return {
        type: SET_STATUS_DROPDOWN,
        payload: {
            items: data
        }
    }
}

export {
    SET_STATUS_DROPDOWN,
    setStatusDropdown
}
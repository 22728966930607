import {
    LOAD_ACTIVITY,
    OPEN_ACTIVITY,
    SET_ACTIVITY_DATA,
    LOAD_ACTIVITY_DETAIL,
    LOAD_HISTORY_LIST,
    LOAD_HISTORY_REWARD_LIST,
    LOAD_ACTIVITY_SELECT
} from './../../actions/activity/index';

const initialState = {
    tab: "",
    show: false,
    items: null,
    result: null,
    detail: null,
    history: null,
    history_reward: null,
    select: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_ACTIVITY:
            return { ...state, result: action.payload.items };
        case LOAD_HISTORY_LIST:
            return { ...state, history: action.payload.items };
        case LOAD_HISTORY_REWARD_LIST:
            return { ...state, history_reward: action.payload.items };
        case LOAD_ACTIVITY_DETAIL:
            return { ...state, detail: action.payload.items };
        case OPEN_ACTIVITY:
            return { ...state, ...action.payload.items };
        case SET_ACTIVITY_DATA:
            return { ...state, items: action.payload.items };
        case LOAD_ACTIVITY_SELECT:
            return { ...state, select: action.payload.items };
        default:
            return state;
    }
}
import {
    SET_DATA_CONDITION
} from '../../actions/app/conditions';

const initialState = {
    conditionData: {
        statusShow: false,
        title: null,
        contentType: null
    }
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_DATA_CONDITION:
            return { ...state, conditionData: action.payload.items };
        default:
            return state;
    }
}
import axios from 'axios';
import serviceConfig from "./serviceConfig";
import { getToken } from "./../auth/utils";
import { checkStatusCode } from "./services";

const queryString = require('query-string');
const svConfig = { ...serviceConfig };

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const loadShopAll = async () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
  let config = {
    method: svConfig.method_get,
    url: `/customer/shop/list-wallet`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    if (checkStatusCode(code)) {
      if (code === 0) {
        return {
            result: result === null ? [] : result,
            code
        };
      }
      else {
        return [];
      }
    }
  }
}

const confirmShopCredit = async (shop_wallet_id) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
    let myJson = {
        "shop_wallet_id": shop_wallet_id
    };

    let config = {
        method: svConfig.method_post,
        url: "/customer/shop/exchange/wallet",
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: queryString.stringify(myJson)
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

export {
    loadShopAll,
    confirmShopCredit
};
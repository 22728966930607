import { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

// Component
import ProgressiveImage from "./../../../../components/ProgressiveImage";
import OpenGame from "./../../../../components/OpenGame";

// CSS
import bagdeStyles from "./../../../../assets/css/Bagde.module.css";
import styles from "./../../../../assets/css/Style.module.css";

export default function MenuOtherContent({ dataCampAll, isLargeScreen }) {
    const [dataList, setDataList] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const menuPage = useSelector(state => state.reduxHome.menu);
    const [loadContentOther, setLoadContentOther] = useState(false);
    const gameRef = useRef();

    const useStyles = makeStyles((theme) => ({
        bgWhite: {
            background: "white"
        },
        cardBox: {
            color: "black",
            borderRadius: "15px",
            padding: "30px 12px",
            [theme.breakpoints.down('xs')]: {
                borderRadius: "0px"
            }
        },
        textTitle: {
            fontSize: "26px",
            fontWeight: "600",
            marginBottom: "20px",
            [theme.breakpoints.down('xs')]: {
                fontSize: "20px"
            }
        },
        boxImgSize: {
            width: "100%",
            height: "100%",
            borderRadius: "10px"
        },
        textCopyR: {
            fontSize: "18px"
        },
        selectCamp: {
            cursor: "pointer",
            position: "relative",
            '&:hover': {
                top: "2px"
            }
        },
        imgSize: {
            width: "100%",
            height: "100%"
        },
        imgDisable: {
            opacity: "0.8"
        }
    }));

    const classes = useStyles();

    const gotoPage = (item) => {
        gameRef.current.loadGame(item, "camp");
    };

    const loadImageType = (dataArr) => {
        let imgArr = dataArr.filter((item) => {
            return item.id === menuPage.typeGame;
        });

        if (imgArr.length > 0) {
            return imgArr[0].image;
        }
        else {
            return dataArr[0].image;
        }
    };

    const renderBagde = (specials) => {
        if (specials !== "") {
            let specialType = specials.split(",");
            if (specialType.length > 1) {
                let selectTypeNew = specialType.find((str) => str === "new");
                if (selectTypeNew === "new" && selectTypeNew !== undefined) {
                    return (
                        <div className={bagdeStyles.ribbonPosition}>
                            <div className={[bagdeStyles.ribbon, bagdeStyles.ribbonPrimary, bagdeStyles.ribbonTextHome].join(" ")}>
                                มาใหม่
                            </div>
                        </div>
                    );
                }
            }
            else if (specialType.length === 1) {
                let selectTypeNew = specialType.find((str) => str === "new");
                let selectTypeHot = specialType.find((str) => str === "hot");
                // let selectTypeNewGame = specialType.find((str) => str === "new-game");

                if (selectTypeNew !== undefined) {
                    return (
                        <div className={bagdeStyles.ribbonPosition}>
                            <div className={[bagdeStyles.ribbon, bagdeStyles.ribbonPrimary, bagdeStyles.ribbonTextHome].join(" ")}>
                                มาใหม่
                            </div>
                        </div>
                    );
                }

                // if(selectTypeNewGame !== undefined) {
                //     return (
                //         <div className={bagdeStyles.ribbonPosition}>
                //             <div className={[bagdeStyles.ribbon, bagdeStyles.ribbonPrimary, bagdeStyles.ribbonTextHome].join(" ")} style={{ borderRadius: "6px" }}>
                //                 มีเกมมาใหม่
                //             </div>
                //         </div>
                //     );
                // }

                if (selectTypeHot !== undefined) {
                    return (
                        <div className={bagdeStyles.ribbonPosition}>
                            <div className={[bagdeStyles.ribbon, bagdeStyles.ribbonYellow, bagdeStyles.ribbonTextHome].join(" ")}>
                                มาแรง
                            </div>
                        </div>
                    );
                }
            }
        }

        return null;
    };

    useEffect(() => {
        setShowMessage(false);

        setTimeout(() => {
            setShowMessage(true);
        }, 150);

        if (dataCampAll !== null) {
            if(dataCampAll.length > 0) {
                setDataList([]);
                let filterData = dataCampAll.filter((item) => {
                    let checkType = item.types.filter((element) => {
                        return menuPage.typeGame === element.id
                    });
    
    
                    return checkType.length > 0;
                });
    
                let filterDataNew = [];
    
                filterData.forEach(item => {
                    if (menuPage.game_camp_image) {
                        let obj = item.types.find(o => o.id === menuPage.typeGame);
                        if (obj !== undefined) {
                            let images = loadImageType(item.types);
    
                            filterDataNew.push({
                                bet: item.bet,
                                camp: item.camp,
                                category: item.category,
                                category_name: item.category_name,
                                code: item.code,
                                customer: item.customer,
                                game_code: item.game_code,
                                image: images,
                                jackpot: item.jackpot,
                                status: item.status,
                                types: item.types,
                                win: item.win,
                                specials: item.specials,
                                win_rate: item.win_rate
                            });
                        }
                    }
                    else {
                        let images = loadImageType(item.types);
    
                        filterDataNew.push({
                            bet: item.bet,
                            camp: item.camp,
                            category: item.category,
                            category_name: item.category_name,
                            code: item.code,
                            customer: item.customer,
                            game_code: item.game_code,
                            image: images,
                            jackpot: item.jackpot,
                            status: item.status,
                            types: item.types,
                            win: item.win,
                            specials: item.specials,
                            win_rate: item.win_rate
                        });
                    }
                });
    
                setDataList(filterDataNew);
            }
        }
    }, [menuPage, dataCampAll]);

    useEffect(() => {
        setLoadContentOther(true);
    }, []);

    return (
        loadContentOther && (
            <>
                <OpenGame ref={gameRef}></OpenGame>
                <div className={[styles.cardOtherListHeadText, "left"].join(" ")}>
                    {
                        menuPage.pageTitle
                    }
                </div>

                <div className={styles.boxMarginMediumTop}>
                    <Grid container alignItems="center" spacing={isLargeScreen ? 2 : 1}>
                        {
                            showMessage && (
                                dataList.map((item, index) => (
                                    <Grid key={index} item xs={menuPage.column.mobile} md={menuPage.column.desktop}>
                                        {
                                            item.status === 2 ?
                                                <div className="relative" onClick={() => { gotoPage(item) }}>
                                                    <div className={classes.selectCamp}>
                                                        {/* <LazyLoadImage
                                                            alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม"
                                                            effect="blur"
                                                            src={item.image}
                                                            className={classes.imgSize}
                                                        ></LazyLoadImage> */}

                                                        <ProgressiveImage 
                                                            className={classes.imgSize}
                                                            placeholder="/images/bluring/308x191.jpg" 
                                                            src={item.image} 
                                                        ></ProgressiveImage>

                                                        {
                                                            item.status === 2 && (
                                                                renderBagde(item.specials)
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className={["relative", bagdeStyles.bagdeDanger].join(" ")} attribute-bagde-name={item.status === 3 ? "ปิดปรับปรุง" : "เร็ว ๆ นี้"}>
                                                    {/* <LazyLoadImage
                                                        alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม"
                                                        effect="blur"
                                                        src={item.image}
                                                        className={[classes.imgSize, classes.imgDisable].join(" ")}
                                                    ></LazyLoadImage> */}
                                                    <ProgressiveImage 
                                                        className={[classes.imgSize, classes.imgDisable].join(" ")}
                                                        placeholder="/images/bluring/308x191.jpg" 
                                                        src={item.image} 
                                                    ></ProgressiveImage>
                                                </div>
                                        }
                                    </Grid>
                                ))
                            )
                        }
                    </Grid>
                </div>
            </>
        )
    );
}
import { useDispatch } from 'react-redux';
// import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

// Redux
import { openAuthenApp } from "./../../../../../../../@core/redux/actions/auth/index";

// CSS
import styles from "./../../../../assets/css/Style.module.css";
import buttonStyles from "./../../../../assets/css/Button.module.css";

export default function BankSelect({ typePage, isUserLoggedIn, bankList, depositItems }) {
    const history = useHistory();
    const dispatch = useDispatch();
    
    const gotoPage = (page) => {
        history.push(page);
    };

    const gotoLogin = () => {
        dispatch(openAuthenApp({ tab: "login", show: true }));
        history.push("/");
    };

    const renderTextBankNone = () => {
        return (
            <div className={styles.boxNoneBankMargin}>
                <div className={styles.boxNoneBank}>
                    <div className={styles.boxNoneBankTitle}>
                        {
                            typePage === "truemoney" ?
                            "ไม่มีข้อมูลบัญชีทรูมันนี่วอลเล็ท กรุณาเพิ่มบัญชีก่อนทำรายการ"
                            :
                            "ไม่มีข้อมูลบัญชีเติมเงิน กรุณาเพิ่มบัญชีเติมเงินก่อนทำรายการ"
                        }
                    </div>
                    <br />
                    <button className={[buttonStyles.btnConfirm, buttonStyles.btnCancel].join(" ")} onClick={() => { gotoPage("/bank") }}>
                        เพิ่มบัญชีเติมเงิน
                    </button>
                    {/* <div className={styles.boxNoneBankGoto} onClick={() => { gotoPage("/bank") }}>
                        เพิ่มบัญชีเติมเงิน
                    </div> */}
                </div>
            </div>
        );
    };

    const renderBankListContent = () => {
        if(depositItems.mode === "deposit") {
            if(bankList !== null) {
                if(bankList.length > 0) {
                    return (
                        <div className={styles.boxMarginFormTop}>
                            <div className={styles.boxBankCustomer}>
                                <div className={styles.boxAccountAgentTitle}>
                                    บัญชีลูกค้าที่ต้องใช้โอน
                                </div>
                                <div className={styles.boxMarginTop}>
                                    <Grid container alignItems="center" spacing={3}>
                                        <Grid item xs={2}>
                                            <img src={bankList[0].bankimg} style={{ width: "100%", borderRadius: "8px" }} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <div className={styles.boxAccountAgentText}>
                                                บัญชี {
                                                    bankList[0].bankname
                                                }
                                                <br />
                                                <Grid container alignItems="center" justifyContent="space-between">
                                                    <Grid item>
                                                        {bankList[0].account}
                                                    </Grid>
                                                    <Grid item>
                                                        {bankList[0].name}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    );
                }
                else {
                    return renderTextBankNone();
                }
            }
            else {
                return renderTextBankNone();
            }
        }
        else {
            return (
                <div className={styles.boxMarginFormTop}>
                    <div className={styles.boxBankCustomer}>
                        <div className={styles.boxAccountAgentTitle}>
                            บัญชีลูกค้าที่ต้องใช้โอน
                        </div>
                        <div className={styles.boxMarginTop}>
                            <Grid container alignItems="center" spacing={3}>
                                <Grid item xs={2}>
                                    <img src={depositItems.customer_bankimg} style={{ width: "100%", borderRadius: "8px" }} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                </Grid>
                                <Grid item xs={10}>
                                    <div className={styles.boxAccountAgentText}>
                                        บัญชี {
                                            depositItems.customer_bankname
                                        }
                                        <br />
                                        <Grid container alignItems="center" justifyContent="space-between">
                                            <Grid item>
                                                {depositItems.customer_bank_account}
                                            </Grid>
                                            <Grid item>
                                                {depositItems.customer_bank_account_name}
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            );
        }
    };

    // useEffect(() => {
    //     if(depositItems.mode !== "pay") {

    //     }
    // }, [depositItems, bankList]);

    return (
        <>
            {
                isUserLoggedIn === false ?
                <div className={styles.boxNoneBankMargin}>
                    <div className={styles.boxNoneBank}>
                        <div className={styles.boxNoneBankTitle} style={{ color: "white" }}>
                            ไม่สามารถทำรายการได้ เนื่องจากยังไม่ได้เข้าสู่ระบบ
                        </div>
                        <div className={styles.boxNoneBankGoto} onClick={() => { gotoLogin() }}>
                            คลิ๊ก ไปที่หน้าเข้าสู่ระบบ
                        </div>
                    </div>
                </div>
                :
                renderBankListContent()
            }
        </>
    );
}
import { useSelector } from 'react-redux';

// Component
import PendingAuto from './PendingAuto';
import PendingSlip from './PendingSlip';

const DepositPendingApp = () => {
    const depositItems = useSelector(state => state.reduxDeposit.result);

    return (
        <>
            {
                depositItems !== null && (
                    <PendingSlip depositItems={depositItems}></PendingSlip>
                    // depositItems.situation === 0 ?
                    // <PendingAuto depositItems={depositItems}></PendingAuto>
                    // :
                    // <PendingSlip depositItems={depositItems}></PendingSlip>
                )
            }
            
        </>
    );
}

export default DepositPendingApp;
import {
    SET_DEPOSIT_DATA,
    SET_DEPOSIT_MANUAL,
    SET_DATA_BANK_CUSTOMER_ALL
} from './../../actions/deposit/index';

const initialState = {
    result: false,
    dataManual: null,
    dataBank: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_DEPOSIT_DATA:
            return { ...state, result: action.payload.items };
        case SET_DEPOSIT_MANUAL:
            return { ...state, dataManual: action.payload.items };
        case SET_DATA_BANK_CUSTOMER_ALL:
            return { ...state, dataBank: action.payload.items };
        default:
            return state;
    }
}
import { 
    LOAD_CHECK_PROMOTION 
} from './../../actions/promotion/index';

const initialState = {
    promotionData: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_CHECK_PROMOTION:
            return { ...state, promotionData: action.payload.items };
        default:
            return state;
    }
}
import {
    SET_OPEN_WHEEL_STATUS,
    LOAD_WHEEL_SPIN_DATA
} from '../../actions/app/wheel';

const initialState = {
    show: false,
    result: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_OPEN_WHEEL_STATUS:
            return { ...state, show: action.payload.items };
        case LOAD_WHEEL_SPIN_DATA:
            return { ...state, result: action.payload.items };
        default:
            return state;
    }
}
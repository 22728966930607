const SET_LOAD_HOME = 'app/SET_LOAD_HOME';
const SET_LOAD_IMAGES = 'app/SET_LOAD_IMAGES';
const SET_LOAD_PROCESSING = 'app/SET_LOAD_PROCESSING';

function setLoadHome(data) {
    return {
        type: SET_LOAD_HOME,
        payload: {
            items: data
        }
    }
}

function setLoadImages(data) {
    return {
        type: SET_LOAD_IMAGES,
        payload: {
            items: data
        }
    }
}

function setLoadProcessing(data) {
    return {
        type: SET_LOAD_PROCESSING,
        payload: {
            items: data
        }
    }
}

export {
    SET_LOAD_HOME,
    SET_LOAD_IMAGES,
    SET_LOAD_PROCESSING,
    setLoadHome,
    setLoadImages,
    setLoadProcessing
}
import { 
    SET_AFFLIATE_DATA,
    OPEN_GET_CREDIT,
    OPEN_INVITE_FRIEND,
    LOAD_DATA_INVITE_ALL,
    LOAD_CLIAM_INVITE,
    LOAD_MEMBER_INVITE
} from './../../actions/affiliate/index';

const initialState = {
    result: null,
    affiliate: {
        tab: "affiliate",
        show: false,
        type: null
    },
    linkFriend: {
        show: false
    },
    inviteData: null,
    memberData: null,
    cliamData: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_AFFLIATE_DATA:
            return { ...state, result: action.payload.items };
        case OPEN_GET_CREDIT:
            return { ...state, affiliate: action.payload.items };
        case OPEN_INVITE_FRIEND:
            return { ...state, linkFriend: action.payload.items };
        case LOAD_DATA_INVITE_ALL:
            return { ...state, inviteData: action.payload.items };
        case LOAD_CLIAM_INVITE:
            return { ...state, cliamData: action.payload.items };
        case LOAD_MEMBER_INVITE:
            return { ...state, memberData: action.payload.items };
        default:
            return state;
    }
}
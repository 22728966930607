import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import {isMobile} from 'react-device-detect';

// Check Auth
import { isUserLoggedIn } from "./../../../../@core/auth/utils";

// @Utility
// import filterImages from "./../../../../@utility/app/filterImages";

// Redux
import { openGetCredit, openInviteFriend } from "./../../../../@core/redux/actions/affiliate/index";
import { setHomeMenuType } from "./../../../../@core/redux/actions/home/index";
import { setDataAlert } from "./../../../../@core/redux/actions/app/alert";
import { setOpenWheelStatus } from "./../../../../@core/redux/actions/app/wheel";

// @Utility
// import setCookie from "./../../../../@utility/app/setCookie";

// Redux
import { setShowPopupImage } from "./../../../../@core/redux/actions/app/popup";

// Component
import ProgressiveImage from "./ProgressiveImage";
import CloseButton from "./CloseButton";

//  CSS
import modalStyles from "./../assets/css/Modal.module.css";
// import buttonStyles from "./../assets/css/Button.module.css";

export default function PopupImages({ popupData }) {
    const [popupJson, setPopupJson] = useState(null);
    const dispatch = useDispatch();
    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    const reduxAppScrollPosition = useSelector(state => state.reduxAppScrollPosition.top);
    const history = useHistory();

    const settings = {
        dots: true,
        // fade: true,
        infinite: true,
        autoplay: true,
        speed: 600,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const useStyles = makeStyles((theme) => ({
        slideSize: {
            // width: "800px !important",
            height: "100%",
            [theme.breakpoints.down('xs')]: {
                // width: `${screenWidth - 150}px !important`
            }
        },
        imgPopup: {
            width: "100%",
            height: "100%",
            cursor: "pointer",
            borderRadius: "12px"
            // maxWidth: "380px"
        }
    }));

    const classes = useStyles();

    const closeBox = () => {
        dispatch(setShowPopupImage(false));
    };

    const gotoPage = (item) => {
        let page = item.image_link;
        let typeAction = item.image_link_type;

        if(typeAction === 4) {
            if (page === "affiliate") {
                if(isUserLoggedIn() === false) {
                    openBox("รับทรัพย์");
                }
                else {
                    dispatch(openGetCredit({
                        tab: "affiliate",
                        show: true,
                        type: null
                    }));
                }
            }
            else if (page === "inviteFrined") {
                if(isUserLoggedIn() === false) {
                    openBox("เชิญเพื่อน");
                }
                else {
                    dispatch(openInviteFriend({
                        show: true
                    }));
                }
            }
            else if (page === "wheelSpin") {
                if(isUserLoggedIn() === false) {
                    openBox("กงล้อลุ้นโชค");
                }
                else {
                    dispatch(setOpenWheelStatus(true));
                }
            }

            if(isUserLoggedIn()) {
                dispatch(setShowPopupImage(false));
            }
        }
        else if(typeAction === 3) {
            let menuList = dataSetting.menu_camp;
            let dataObj = {};

            let index = menuList.findIndex(item => item.code === page);
            dataObj = menuList[index];
            dataObj.id = index;

            if(dataObj !== undefined && dataObj !== null) {
                dispatch(setHomeMenuType({
                    id: dataObj.id,
                    renderPage: dataObj.code,
                    pageTitle: dataObj.title,
                    game_camp_image: dataObj.game_camp_image,
                    column: {
                        desktop: dataObj.column_desktop,
                        mobile: dataObj.column_mobile
                    },
                    typeGame: dataObj.game_camp_type
                }));

                window.scrollTo({
                    top: reduxAppScrollPosition,
                    left: 0,
                    behavior: "smooth"
                });   
                
                dispatch(setShowPopupImage(false));
            }
        }
        else if(typeAction === 1) {
            if (isMobile) {
                window.location.href = page;
            } else {
                window.open(page, '_blank');
            }   
            
            dispatch(setShowPopupImage(false));
        }
        else {
            history.push(page);
            dispatch(setShowPopupImage(false));
        }
    };

    const openBox = (page) => {
        dispatch(setDataAlert({
            type: "warning",
            title: "แจ้งเตือน",
            text: `กรุณาเข้าสู่ระบบก่อนเข้าใช้งาน "${page}"`,
            action: true,
            redirect: "/login",
            show: true,
            buttonText: "ตกลง"
        }));
    };

    useEffect(() => {
        // console.log(popupData)
        setPopupJson(popupData);
    }, [popupData, dispatch]);

    return (
        <>  
            <div className={modalStyles.contentPosition} style={{ zIndex: "1800", height: "100%" }}>
                <div className={modalStyles.boxBody}>
                    <div className={modalStyles.box} style={{ border: "unset", borderRadius: "0px" }}>

                        <div className={modalStyles.closeButton} onClick={closeBox}>
                            <CloseButton></CloseButton>
                        </div>

                        <div className={modalStyles.boxContentWidthBigImage}>
                            {
                                popupJson !== null && (
                                    <Slider {...settings}>
                                        {
                                            popupJson.map((item) => {
                                                // console.log(item)
                                                return ( 
                                                    <div key={item.id} className={classes.slideSize}>
                                                        {/* <div style={{ padding: "0px 6px 0px 0px" }}> */}
                                                        <ProgressiveImage className={classes.imgPopup} placeholder="/images/bluring/520x520.jpg" src={item.image_picture} onClick={() => {gotoPage(item)}}></ProgressiveImage>
                                                        {/* <img className={classes.imgPopup} src={item.image_picture} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" onClick={() => {gotoPage(item)}} /> */}
                                                        {/* </div> */}
                                                    </div>
                                                )
                                            })
                                        }
                                    </Slider> 
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className={modalStyles.fixedBackdrop} style={{ zIndex: "1790" }} onClick={closeBox}></div>
        </>
    );
}
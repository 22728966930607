import { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

// @utility
import alertText from "./../../../../../../@utility/app/alertText";
import validateInput from "./../../../../../../@utility/app/validateInput";

// Redux & Action
import { openProfile } from "../../../../../../@core/redux/actions/profile/index";
import { setHomeData } from "../../../../../../@core/redux/actions/home/index";

// Services
import { changePassword } from "./../../../../../../@core/services/profileService";
import { loadHomeData } from "../../../../../../@core/services/homeService";

//  CSS
import modalStyles from "./../../../assets/css/Modal.module.css";
import buttonStyles from "./../../../assets/css/Button.module.css";

// Components
import SnackbarSet from "./../../../components/SnackbarSet";

export default function Password() {
    const { register, handleSubmit, reset } = useForm();
    const childRef = useRef();
    const theme = useTheme();
    let snackbarJson = {};
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);
    const homeData = useSelector(state => state.reduxHome.result);
    const [passwordSmsContent, setPasswordSmsContent] = useState(null);
    const dispatch = useDispatch();

    const useStyles = makeStyles(() => ({
        contentAlign: {
            marginTop: "30px",
            [theme.breakpoints.down('sm')]: {
                marginTop: "10px"
            }
        },
        buttonPassword: {
            fontSize: "16px",
            width: "140px",
            padding: "4px 16px"
        },
        textTitle: {
            fontSize: "20px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white"
        },
        PasswordContentTop: {
            marginTop: "10px"
        },
        PasswordContentList: {
            background: "#001a84",
            padding: "16px",
            borderLeft: "3px solid #28a745",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            marginBottom: "4px",
            borderRadius: "4px"
        },
        PasswordContentTitle: {
            fontSize: "16px"
        },
        PasswordContentSub: {
            fontSize: "14px",
            color: "rgb(255, 193, 7)"
        },
        dialogWidth: {
            width: "360px",
            textAlign: "center",
            padding: "10px 20px"
        },
        dialogTitle: {
            fontSize: "22px",
        },
        modalInput: {
            width: "100%",
            fontSize: "18px",
            padding: "6px"
        },
        formPadding: {
            paddingTop: "16px"
        },
        inputPaddingTop: {
            margin: "14px 0px",
            [theme.breakpoints.down('sm')]: {
                margin: "6px 0px",
            }
        },
        inputTextLabel: {
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            paddingBottom: "8px",
            paddingLeft: "8px",
            fontSize: "18px",
            [theme.breakpoints.down('sm')]: {
                fontSize: "14px",
                paddingBottom: "4px"
            }
        },
        inputStyles: {
            width: "94%",
            height: "48px",
            // border: "none",
            padding: "0 0px 0 20px",
            outline: "none",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            fontSize: "16px",
            borderRadius: "8px",
            background: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            border: "1px solid white",
            [theme.breakpoints.down('sm')]: {
                height: "38px",
                fontSize: "14px"
            }
        }
    }));

    const classes = useStyles();

    const onSubmit = async (data) => {
        const { passwordOld, passwordNew, passwordNewAgain } = data;
        const pathUrl = passwordSmsContent === 1 ? "/customer/profile/reset-password-sms" : "/customer/profile/reset-password" 
        let myJson = {
            password_old: passwordSmsContent === 1 ? "" : passwordOld,
            password: passwordNew,
            password_confirm: passwordNewAgain
        }

        changePassword(myJson, pathUrl).then((data) => {
            let statusCode = data;
            if (statusCode === 0 || statusCode === 200) {
                reloadHomeData();
                snackbarJson = {
                    statusShow: true,
                    statusAlert: "success",
                    titleAlert: "แจ้งเตือน",
                    textAlert: "เปลี่ยนรหัสผ่านสำเร็จ !"
                };

                childRef.current.snackbar(snackbarJson);
                dispatch(openProfile({ tab: "profile", show: false, type: "password", button: false }));
            }
            else {
                snackbarJson = alertText(statusCode);
                childRef.current.snackbar(snackbarJson);
            }

            reset("");
        });
    };

    const reloadHomeData = () => {
        loadHomeData().then((data) => {
            if (data.code === 0 || data.code === 200) {
                dispatch(setHomeData(data.result));
            }
        });
    }

    useEffect(() => {
        if (homeData !== null) {
            if (homeData?.auth_sms !== undefined && homeData?.auth_sms !== null) {
                if (homeData?.auth_sms === 1 || homeData?.auth_sms === true) {
                    setPasswordSmsContent(1);
                }
            }
        }
    }, [homeData]);

    return (
        <>
            <SnackbarSet ref={childRef} />
            <div className={modalStyles.boxTitle}>
                {
                    passwordSmsContent !== null ?
                        "กรุณากำหนดรหัสผ่านใหม่"
                        :
                        "เปลี่ยนรหัสผ่าน"
                }

            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.contentAlign}>
                    {
                        passwordSmsContent !== 1 && (
                            <div className={classes.inputPaddingTop}>
                                <div className={classes.inputTextLabel}>
                                    รหัสผ่านเก่า
                                </div>
                                <div>
                                    <input type="password" className={[classes.inputStyles, "kanit"].join(" ")} placeholder="รหัสผ่านเก่า" {...register("passwordOld", { required: true, maxLength: 20, minLength: 4, pattern: /^[A-Za-z0-9]+$/i })} maxLength="20" onKeyPress={(event) => validateInput(event, "textNumber")} required />
                                </div>
                            </div>
                        )
                    }

                    <div className={classes.inputPaddingTop}>
                        <div className={classes.inputTextLabel}>
                            รหัสผ่านใหม่
                        </div>
                        <div>
                            <input type="password" className={[classes.inputStyles, "kanit"].join(" ")} placeholder="รหัสผ่านใหม่" {...register("passwordNew", { required: true, maxLength: 20, minLength: 4, pattern: /^[A-Za-z0-9]+$/i })} maxLength="20" onKeyPress={(event) => validateInput(event, "textNumber")} required />
                        </div>
                        <div className="validateWarning">
                            รหัสผ่านมากกว่า 4 ตัวอักษร
                        </div>
                    </div>
                    <div className={classes.inputPaddingTop}>
                        <div className={classes.inputTextLabel}>
                            รหัสผ่านใหม่อีกครั้ง
                        </div>
                        <div>
                            <input type="password" className={[classes.inputStyles, "kanit"].join(" ")} placeholder="รหัสผ่านใหม่อีกครั้ง"  {...register("passwordNewAgain", { required: true, maxLength: 20, minLength: 4, pattern: /^[A-Za-z0-9]+$/i })} maxLength="20" onKeyPress={(event) => validateInput(event, "textNumber")} required />
                        </div>
                        <div className="validateWarning">
                            รหัสผ่านมากกว่า 4 ตัวอักษร
                        </div>
                    </div>
                </div>
                <br />
                <div className="center">
                    <button type="submit" className={buttonStyles.btnConfirm}>
                        ยืนยัน
                    </button>
                </div>

            </form>
        </>
    );
}
import { 
    SET_STATUS_DROPDOWN 
} from '../../actions/app/dropdrown';

const initialState = {
    status: false
};

export default function reducer(state = initialState, action) {
    switch(action.type) {
        case SET_STATUS_DROPDOWN:
            return { ...state, status: action.payload.items };
        default: 
            return state;
    } 
}
import {
    loadActivityAll,
    loadActivityDetail,
    selectVoteActivity,
    loadHistoryDataList,
    loadActivitySelectData,
    confirmActivityReward
} from "./../../../services/activityService";
import { setLoadProcessing } from "./../app/processing";
import { setStatusSpinner } from "./../app/spinner";
import { setDataAlert } from "../app/alert";

// @Utility
import alertText from "./../../../../@utility/app/alertText";

const LOAD_ACTIVITY = 'app/LOAD_ACTIVITY';
const LOAD_ACTIVITY_DETAIL = 'app/LOAD_ACTIVITY_DETAIL';
const LOAD_HISTORY_LIST = 'app/LOAD_HISTORY_LIST';
const LOAD_HISTORY_REWARD_LIST = 'app/LOAD_HISTORY_REWARD_LIST';
const OPEN_ACTIVITY = 'app/OPEN_ACTIVITY';
const SET_ACTIVITY_DATA = 'app/SET_ACTIVITY_DATA';
const LOAD_ACTIVITY_SELECT = 'app/LOAD_ACTIVITY_SELECT';

function loadActivityList(reduxActivity) {
    return dispatch => {
        if (reduxActivity === null) {
            dispatch(setLoadProcessing(true));
            dispatch(setStatusSpinner({ status: true, textProcess: null }));
        }

        loadActivityAll().then((data) => {
            const statusCode = data.code;
            dispatch(setLoadProcessing(false));
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_ACTIVITY,
                    payload: {
                        items: data.result
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_ACTIVITY,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
};

function loadHistoryList(reduxActivityHistory) {
    return dispatch => {
        if (reduxActivityHistory === null) {
            dispatch(setLoadProcessing(true));
            dispatch(setStatusSpinner({ status: true, textProcess: null }));
        }

        loadHistoryDataList("/customer/activity/history").then((data) => {
            const statusCode = data.code;
            dispatch(setLoadProcessing(false));
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_HISTORY_LIST,
                    payload: {
                        items: data.result
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_HISTORY_LIST,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
};

function loadHistoryRewardList(reduxActivityHistoryReward) {
    return dispatch => {
        if (reduxActivityHistoryReward === null) {
            dispatch(setLoadProcessing(true));
            dispatch(setStatusSpinner({ status: true, textProcess: null }));
        }

        loadHistoryDataList("/customer/activity/history/reward").then((data) => {
            const statusCode = data.code;
            dispatch(setLoadProcessing(false));
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_HISTORY_REWARD_LIST,
                    payload: {
                        items: data.result
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_HISTORY_REWARD_LIST,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
};

function loadActivityDetailData(id, reduxActivityDetail) {
    return dispatch => {
        if (reduxActivityDetail === null) {
            dispatch(setLoadProcessing(true));
        }

        dispatch(setStatusSpinner({ status: true, textProcess: null }));
        dispatch({
            type: LOAD_ACTIVITY_DETAIL,
            payload: {
                items: null
            }
        })

        loadActivityDetail(id).then((data) => {
            const statusCode = data.code;
            dispatch(setLoadProcessing(false));
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_ACTIVITY_DETAIL,
                    payload: {
                        items: data.result
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_ACTIVITY_DETAIL,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
};

function loadActivitySelect(id, reduxActivitySelect) {
    return dispatch => {
        if (reduxActivitySelect === null) {
            dispatch(setLoadProcessing(true));
            dispatch(setStatusSpinner({ status: true, textProcess: null }));
        }

        loadActivitySelectData(id).then((data) => {
            const statusCode = data.code;
            dispatch(setLoadProcessing(false));
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_ACTIVITY_SELECT,
                    payload: {
                        items: data.result
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_ACTIVITY_SELECT,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
};

function selectActivityApply(choose_id, activity_id, voteStatus) {
    return dispatch => {
        if (voteStatus !== "join") {
            dispatch(setDataAlert({
                type: "warning",
                title: "เเจ้งเตือน",
                text: "ไม่สามารถกดเลือกได้ เนื่องจากคุณได้เข้าร่วมกิจกรรมแล้ว !",
                action: true,
                redirect: null,
                show: true,
                buttonText: "ตกลง"
            }));
        }
        else {
            dispatch(setLoadProcessing(true));
            dispatch(setStatusSpinner({ status: true, textProcess: null }));

            selectVoteActivity(choose_id, activity_id).then((data) => {
                const statusCode = data.code;
                dispatch(setLoadProcessing(false));
                dispatch(setStatusSpinner({ status: false, textProcess: null }));

                if (statusCode === 200 || statusCode === 0) {
                    dispatch(loadActivityDetailData(activity_id));
                    dispatch(loadActivitySelect(activity_id, []));
                    dispatch(setDataAlert({
                        type: "success",
                        title: "เเจ้งเตือน",
                        text: "เข้าร่วมกิจกรรมสำเร็จ !",
                        action: true,
                        redirect: null,
                        show: true,
                        buttonText: "ตกลง"
                    }));
                }
                else {
                    let snackbarJson = alertText(statusCode);
                    dispatch(setDataAlert({
                        type: snackbarJson.statusAlert,
                        title: snackbarJson.titleAlert,
                        text: snackbarJson.textAlert,
                        action: true,
                        redirect: null,
                        show: true,
                        buttonText: "ตกลง"
                    }));
                }
            });
        }
    }
};

function openActivity(data) {
    return {
        type: OPEN_ACTIVITY,
        payload: {
            items: data
        }
    }
}

function setActivityData(data) {
    return {
        type: SET_ACTIVITY_DATA,
        payload: {
            items: data
        }
    }
}

function getActivityReward(activity_apply_id, activity_id) {
    return dispatch => {
        dispatch(setLoadProcessing(true));
        dispatch(setStatusSpinner({ status: true, textProcess: null }));

        confirmActivityReward(activity_apply_id).then((data) => {
            const statusCode = data.code;
            dispatch(setLoadProcessing(false));
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch(loadActivityDetailData(activity_id));
                dispatch(setDataAlert({
                    type: "success",
                    title: "เเจ้งเตือน",
                    text: "รับรางวัลสำเร็จ !",
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
            else {
                let snackbarJson = alertText(statusCode);
                dispatch(setDataAlert({
                    type: snackbarJson.statusAlert,
                    title: snackbarJson.titleAlert,
                    text: snackbarJson.textAlert,
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
        });
    }
};


export {
    OPEN_ACTIVITY,
    SET_ACTIVITY_DATA,
    LOAD_ACTIVITY,
    LOAD_ACTIVITY_DETAIL,
    LOAD_HISTORY_LIST,
    LOAD_HISTORY_REWARD_LIST,
    LOAD_ACTIVITY_SELECT,
    openActivity,
    setActivityData,
    loadActivityList,
    loadActivityDetailData,
    selectActivityApply,
    loadHistoryList,
    loadHistoryRewardList,
    loadActivitySelect,
    getActivityReward
}
// Redux
import { getWheelDataAll } from "./../../../services/miniGameService";
import { setStatusSpinner } from "./../app/spinner";
import { setDataAlert } from "./../app/alert";

// @Utility
import alertText from "./../../../../@utility/app/alertText";

const SET_OPEN_WHEEL_STATUS = 'app/SET_OPEN_WHEEL_STATUS';
const LOAD_WHEEL_SPIN_DATA = 'app/LOAD_WHEEL_SPIN_DATA';

function setOpenWheelStatus(data) {
    return {
        type: SET_OPEN_WHEEL_STATUS,
        payload: {
            items: data
        }
    }
}

function loadWheelSpinData(spinDataAll) {
    return dispatch => {
        if(spinDataAll === null) {
            dispatch(setStatusSpinner({ status: true, textProcess: "ระบบกำลังโหลดข้อมูล กรุณารอสักครู่" }));
        }

        getWheelDataAll().then((data) => {
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            const statusCode = data.code
            if (statusCode === 0 || statusCode === 200) {
                dispatch({
                    type: LOAD_WHEEL_SPIN_DATA,
                    payload: {
                        items: data.result
                    }
                })
            }
            else {
                if(spinDataAll === null) {
                    let snackbarJson = alertText(statusCode);
                    dispatch(setDataAlert({
                        type: snackbarJson.statusAlert,
                        title: snackbarJson.titleAlert,
                        text: snackbarJson.textAlert,
                        action: true,
                        redirect: null,
                        show: true,
                        buttonText: "ตกลง"
                    }));
                    
                    dispatch(setOpenWheelStatus(false));
                    dispatch({
                        type: LOAD_WHEEL_SPIN_DATA,
                        payload: {
                            items: null
                        }
                    })
                }
            }
        });
    }
};

export {
    SET_OPEN_WHEEL_STATUS,
    LOAD_WHEEL_SPIN_DATA,
    setOpenWheelStatus,
    loadWheelSpinData
}
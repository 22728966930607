const SET_DATA_CONDITION = 'app/SET_DATA_CONDITION';

function setDataCondition(data) {
    return {
        type: SET_DATA_CONDITION,
        payload: {
            items: data
        }
    }
}

export {
    SET_DATA_CONDITION,
    setDataCondition
}
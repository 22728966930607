// import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { Grid, Container } from '@material-ui/core';
// import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import { setDataContact } from "./../../../../../@core/redux/actions/app/contact";
// import { openAuthenApp } from "./../../../../../@core/redux/actions/auth/index";
// import { setHomeMenuType } from "./../../../../../@core/redux/actions/home/index";

// Icon
import ChatIcon from '@material-ui/icons/Chat';

export default function FooterNotActive({ play_game, reduxAppCMS }) {
    // const reduxAppScrollPosition = useSelector(state => state.reduxAppScrollPosition.top);
    // const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    // const history = useHistory();
    const dispatch = useDispatch();

    const useStyles = makeStyles(() => ({
        // footerPosition: {
        //     position: "fixed",
        //     bottom: "0px",
        //     left: "0px",
        //     zIndex: "999",
        //     width: "100%"
        // },
        // footerContentPosition: {
        //     background: reduxAppCMS !== null ? reduxAppCMS["bg-foter"] : "white",
        //     boxShadow: "0 4px 8px 0 rgb(0 0 0 / 40%)",
        //     borderRadius: "15px 15px 0px 0px",
        //     borderTop: `6px solid ${reduxAppCMS !== null ? reduxAppCMS["bt-secondary"] : "#D1C4E9"}`,
        //     padding: "8px 6px"
        // },
        // footerList: {
        //     background: reduxAppCMS !== null ? reduxAppCMS["bg-popup"] : "linear-gradient(180deg, #8F00FF 0%, #5000B7 100%)",
        //     borderRadius: "6px",
        //     color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
        //     padding: "12px",
        //     fontSize: "16px",
        //     fontWeight: "600"
        // },
        // footerListCenter: {
        //     background: reduxAppCMS !== null ? reduxAppCMS["bt-confirm"] : "linear-gradient(180deg, #FFC700 0%, #FF8A00 100%)",
        //     borderRadius: "6px",
        //     color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
        //     padding: "12px 2px",
        //     fontSize: "18px",
        //     fontWeight: "600"
        // }
        footerPosition: {
            position: "fixed",
            bottom: "10px",
            right: "10px",
            zIndex: "999"
        },
        footerBtn: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-confirm"] : "linear-gradient(180deg, #FFC700 0%, #FF8A00 100%)",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            padding: "14px 14px 8px 14px",
            borderRadius: "50px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            cursor: "pointer",
            position: "relative",
            '&:hover': {
                top: "2px"
            }
        },
        footerIconSize: {
            width: "36px",
            height: "38px"
        }
    }));

    const classes = useStyles();

    // const gotoPage = (page) => {
    //     history.push(page);
    // };

    const openContact = () => {
        dispatch(setDataContact(true));
    };

    // const openRegister = () => {
    //     let myObj = {
    //         tab: "register",
    //         show: true
    //     };

    //     dispatch(openAuthenApp(myObj));
    // };

    // const gotoDemo = () => {
    //     let menuList = dataSetting.menu_camp;
    //     let dataObj = {};

    //     dataObj = menuList.find((item, index) => {
    //         dataObj["id"] = index;
    //         return item.code === "slot";
    //     });
        
    //     if(dataObj !== undefined && dataObj !== null) {
    //         dispatch(setHomeMenuType({
    //             id: (dataObj.id - 1),
    //             renderPage: dataObj.code,
    //             pageTitle: dataObj.title,
    //             game_camp_image: dataObj.game_camp_image,
    //             column: {
    //                 desktop: dataObj.column_desktop,
    //                 mobile: dataObj.column_mobile
    //             },
    //             typeGame: dataObj.game_camp_type
    //         }));

    //         window.scrollTo({
    //             top: reduxAppScrollPosition,
    //             left: 0,
    //             behavior: "smooth"
    //         });       
    //     }

    //     // window.scrollTo({
    //     //     top: reduxAppScrollPosition,
    //     //     left: 0,
    //     //     behavior: "smooth"
    //     // });

    //     // dispatch(setHomeMenuType({
    //     //     renderPage: "slot",
    //     //     renderPageType: "other",
    //     //     category: 0,
    //     //     typeGame: 0,
    //     //     pageTitle: "ค่ายเกมสล็อตยอดนิยม",
    //     //     column: {
    //     //         desktop: 4,
    //     //         mobile: 6
    //     //     }
    //     // }));
    // };

    return (
        <div className={classes.footerPosition}>
            <div className={classes.footerBtn} onClick={openContact}>
                <ChatIcon className={classes.footerIconSize}></ChatIcon>
            </div>
            {/* {
                (play_game !== "true" && play_game !== true) && (
                    <Container maxWidth="md" style={{ padding: "0px" }}>
                        <div className="relative">
                            <div className={classes.footerContentPosition}>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={4}>
                                        <div className={classes.footerList} onClick={gotoDemo}>
                                            ทดลองเล่น
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className={classes.footerListCenter} onClick={openRegister}>
                                            <div className={classes.footerIconTextCenter}>
                                                สมัครสมาชิก
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className={classes.footerList} onClick={openContact}>
                                            ติดต่อเรา
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Container>
                )
            } */}
        </div>
    );
}
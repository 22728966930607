import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// @Utility
import setBodyScroll from "./../../../../../@utility/app/setBodyScroll";

// Redux
import { setDataAlert } from "./../../../../../@core/redux/actions/app/alert";
import { openAuthenApp } from "./../../../../../@core/redux/actions/auth/index";

// Component
import ModalAlert from "./ModalAlert";

// CSS
import styles from "./../../assets/css/Style.module.css";
import modalStyles from "./../../assets/css/Modal.module.css";
import buttonStyles from "./../../assets/css/Button.module.css";
 
const Alert = ({ alertData }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const onShow = (val) => {
        dispatch(setDataAlert({ ...alertData, show: val }));
    };

    const renderSVGAlert = (type) => {
        if(type === "wheelSpin") {
            return (
                <div className="img-box center">
                    {
                        alertData?.image !== undefined && (
                            <img className="img-wheel-reward" src={alertData?.image} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                        )
                    }
                </div>
            );
        }   
        else {
            if(type === "success") {
                return (
                    <div className="svg-box">
                        <svg className="circular green-stroke">
                            <circle className="path" cx="75" cy="75" r="50" fill="none" strokeWidth="5" strokeMiterlimit="10"/>
                        </svg>
                        <svg className="checkmark green-stroke">
                            <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                                <path className="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53"/>
                            </g>
                        </svg>
                    </div>
                );
            }
            else if(type === "warning") {
                return (
                    <div className="svg-box">
                        <svg className="circular yellow-stroke">
                            <circle className="path" cx="75" cy="75" r="50" fill="none" strokeWidth="5" strokeMiterlimit="10"/>
                        </svg>
                        <svg className="alert-sign yellow-stroke">
                            <g transform="matrix(1,0,0,1,-615.516,-257.346)">
                                <g transform="matrix(0.56541,-0.56541,0.56541,0.56541,93.7153,495.69)">
                                    <path className="line" d="M634.087,300.805L673.361,261.53" fill="none"/>
                                </g>
                                <g transform="matrix(2.27612,-2.46519e-32,0,2.27612,-792.339,-404.147)">
                                    <circle className="dot" cx="621.52" cy="316.126" r="1.318" />
                                </g>
                            </g>
                        </svg>
                    </div>
                );
            }
            else if(type === "error") {
                return (
                    <div className="svg-box">
                        <svg className="circular red-stroke">
                            <circle className="path" cx="75" cy="75" r="50" fill="none" strokeWidth="5" strokeMiterlimit="10"/>
                        </svg>
                        <svg className="cross red-stroke">
                            <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-502.652,-204.518)">
                                <path className="first-line" d="M634.087,300.805L673.361,261.53" fill="none"/>
                            </g>
                            <g transform="matrix(-1.28587e-16,-0.79961,0.79961,-1.28587e-16,-204.752,543.031)">
                                <path className="second-line" d="M634.087,300.805L673.361,261.53"/>
                            </g>
                        </svg>
                    </div>
                );
            }
        }

        return null;
    };

    const clickAction = () => {
        setBodyScroll(false);
        if(alertData.redirect === null) {
            onShow(false);
        }
        else {
            if(alertData.redirect === "/login") {
                openLogin();
            }
            else {
                onShow(false);
                history.push(alertData.redirect);
            }
        }
    };

    const openLogin = () => {
        let myObj = {
            tab: "login",
            show: true
        };

        onShow(false);
        dispatch(openAuthenApp(myObj));
    };

    // console.log(alertData)

    return (
        <ModalAlert show={alertData.show} onShow={onShow}>
            <div className={[modalStyles.boxTitle, "center"].join(" ")} style={{ textShadow: "none" }}>
                { alertData.title }
            </div>

            <Grid container justifyContent="center">
                <Grid item>
                    {
                        renderSVGAlert(alertData.type)
                    }
                </Grid>
            </Grid>

            {
                alertData.text !== null && (
                    <div className={styles.boxMarginTop}>
                        {
                            alertData.type === "none" ?
                            <div className={[styles.confirmParagrap, "center"].join(" ")}>
                                { alertData.text }
                            </div>
                            :
                            <div className={[styles.confirmText, "center"].join(" ")}>
                                { alertData.text }
                            </div>
                        }
                    </div>
                )
            }

            {
                alertData.action && (
                    <div className={styles.boxMarginFormTop}>
                        <Grid container spacing={1} justifyContent="center">
                            <Grid item xs={12}>
                                <div className="center">
                                    <button className={buttonStyles.btnConfirm} onClick={clickAction}>
                                        { alertData.buttonText }
                                    </button>
                                </div>
                            </Grid>
                        </Grid>
                    </div> 
                )
            }

        </ModalAlert>
    );
}

export default Alert;
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

// @Utility
import { setStorage, removeStorage } from "./../../../../../../../@utility/app/storage";
import alertText from "./../../../../../../../@utility/app/alertText";
import timeZone from "./../../../../../../../@utility/app/timeZone";

// auth
import { isUserLoggedIn } from "./../../../../../../../@core/auth/utils";

// Redux
import { setDataAlert } from "./../../../../../../../@core/redux/actions/app/alert";
import { setStatusSpinner } from "./../../../../../../../@core/redux/actions/app/spinner";
import { setDepositData } from "./../../../../../../../@core/redux/actions/deposit/index";

// Services
import {
    loadDepositFirst,
    cancenDeposit,
    checkDeposit
} from "./../../../../../../../@core/services/depositService";

//  CSS
import styles from "./../../../../assets/css/Style.module.css";
import modalStyles from "./../../../../assets/css/Modal.module.css";
import buttonStyles from "./../../../../assets/css/Button.module.css";

// Component
import BankSelect from "./BankSelectAuto";
import BoxAuto from "./BoxAuto";
import Condition from "./../../component/Condition";

export default function FormAuto({ typePage, bankList, depositItems }) {
    const dispatch = useDispatch();
    let snackbarJson = {};
    const history = useHistory();
    const [timeDepositProcess, setTimeDepositProcess] = useState(null);
    const [timeShow, setTimeShow] = useState(0);

    const openBox = (snackbarJson) => {
        dispatch(setDataAlert({
            type: snackbarJson.statusAlert,
            title: snackbarJson.titleAlert,
            text: snackbarJson.textAlert,
            action: true,
            redirect: null,
            show: true,
            buttonText: "ตกลง"
        }));
    };

    const reloadData = (page) => {
        loadDepositFirst().then((data) => {
            const statusCode = data.code;

            if (statusCode === 200 || statusCode === 0) {
                dispatch(setDepositData(data.result));
                setStorage("processDeposit", data.result.id);

                if (page === "home") {
                    history.push("/");
                }
                else if (page === "deposit") {
                    history.push("/deposit/home");
                }
                else {
                    history.push("/deposit/pending/manual");
                }
            }
        });
    };

    const checkTransaction = (checkTime) => {
        checkDeposit(depositItems.id).then((data) => {
            const statusCode = data.code;

            if (statusCode === 200 || statusCode === 0) {
                switch (data.result.status) {
                    case 0:
                    case 1: break;
                    case 2:
                        snackbarJson = {
                            statusShow: true,
                            statusAlert: "success",
                            titleAlert: "แจ้งเตือน",
                            textAlert: "ทำรายการเติมเงินสำเร็จ !"
                        };

                        openBox(snackbarJson);

                        clearInterval(checkTime);
                        removeStorage("processDeposit");
                        reloadData("home");
                        break;
                    case 3:
                        // snackbarJson = {
                        //     statusShow: true,
                        //     statusAlert: "error",
                        //     titleAlert: "แจ้งเตือน",
                        //     textAlert: "ทำรายการเติมเงินไม่สำเร็จ ! กรุณาติดต่อผู้ดูแลระบบ"
                        // };

                        // openBox(snackbarJson);

                        clearInterval(checkTime);
                        removeStorage("processDeposit");
                        reloadData("deposit");
                        break;
                    default:
                        clearInterval(checkTime);
                        removeStorage("processDeposit");
                        break;
                }
            }
            else {
                if (statusCode === 2 || statusCode === 4) {
                    snackbarJson = alertText(statusCode);
                    openBox(snackbarJson);

                    setTimeout(function () {
                        window.location.href = `/?web=${process.env.REACT_APP_DEFAULT_AGENT_CODE}`;
                    }, 5000);
                }
                else {
                    snackbarJson = alertText(statusCode);
                    openBox(snackbarJson);
                }
            }
        });
    };

    const checkTypePage = () => {
        if (typePage === "truemoney") {
            return "เติมเงินทรูมันนี่";
        }
        else {
            return "เติมเงินด่วน";
        }
    }

    const cancelTransaction = () => {
        dispatch(setStatusSpinner({status: true, textProcess: null}));

        cancenDeposit().then((data) => {
            const statusCode = data.code;
            dispatch(setStatusSpinner({status: false, textProcess: null}));

            if (statusCode === 200 || statusCode === 0) {
                removeStorage("processDeposit");

                if((depositItems?.channel.automatic === true && depositItems?.channel.truemoney === false && depositItems?.channel.manual === false) || (depositItems?.channel.automatic === false && depositItems?.channel.truemoney === true && depositItems?.channel.manual === false) || (depositItems?.channel.automatic === false && depositItems?.channel.truemoney === false && depositItems?.channel.manual === false)) {
                    reloadData("home");
                }
                else {
                    reloadData("deposit");
                }
            }
        });
    };

    const renderBoxAuto = () => {
        if(depositItems.mode !== "pay" && bankList === null) return null;

        return (
            <div className={styles.boxMarginFormTop}>
                <BoxAuto openBox={openBox} depositItems={depositItems}></BoxAuto>
            </div>
        );
    };

    const renderCancelBtn = () => {
        if(depositItems.mode !== "pay" && bankList === null) return null;

        if(timeDepositProcess !== null) {
            return (
                <div className={styles.boxMarginFormTop}>
                    <div className="center">
                        <button className={[buttonStyles.btnConfirm, buttonStyles.btnCancel].join(" ")} style={{ opacity: "0.6" }}>
                            ยกเลิก{ checkTypePage() } ({timeShow})
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.boxMarginFormTop}>
                <div className="center">
                    <button className={[buttonStyles.btnConfirm, buttonStyles.btnCancel].join(" ")} onClick={cancelTransaction}>
                        ยกเลิก{ checkTypePage() }
                    </button>
                </div>
            </div>
        );
    };

    const secondsToTime = (secs) => {
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
    
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        setTimeShow(`${minutes} : ${seconds}`);
    };

    useEffect(() => {
        if(depositItems !== null) {
            if(depositItems.mode === "pay") {
                setTimeDepositProcess(depositItems.time_create);

                let timer = depositItems.time, minutes, seconds;
                let countTime = 0;
                const checkTime = setInterval(() => {
                    minutes = parseInt(timer / 60, 10);
                    seconds = parseInt(timer % 60, 10);

                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    seconds = seconds < 10 ? "0" + seconds : seconds;

                    // setTimeShow(`${minutes} : ${seconds}`);
                    if (--timer < 0) {
                        // setTimeShow(0);
                        clearInterval(checkTime);
                        return null;
                    }
                    else {
                        countTime++;
                        if (countTime === 3) {
                            checkTransaction(checkTime);
                            countTime = 0;
                        }
                    }

                }, 1000);

                return () => {
                    clearInterval(checkTime);
                }
            }
            else {
                setTimeDepositProcess(null);
            }
        }
    }, [depositItems]);

    useEffect(() => {
        if(timeDepositProcess !== null) {
            let travelTime = moment(timeDepositProcess).add(2, 'minutes');
            let dateEnd = timeZone(travelTime, "noFormat");
            let dateCurrent = timeZone(moment(), "noFormat");
            let duration = moment.duration(dateEnd.diff(dateCurrent));
            let seconds = duration.asSeconds();

            const checkTime = setInterval(() => {
                --seconds;
                if(seconds <= 0) {
                    clearInterval(checkTime);
                    setTimeDepositProcess(null);
                    setTimeShow(0);
                }
                else {
                    secondsToTime(seconds);
                }
            }, 1000);

            return () => {
                clearInterval(seconds);
            }
        }
    }, [timeDepositProcess]);

    return (
        <>
            <div className={modalStyles.boxTitle}>
                { checkTypePage() }
            </div>

            <div id="DepositInput" className={styles.inputBorder}>
                {
                    depositItems !== null && (
                        <BankSelect isUserLoggedIn={isUserLoggedIn()} bankList={bankList} depositItems={depositItems} typePage={typePage}></BankSelect>
                    )
                }
            </div>
            
            {
                isUserLoggedIn() && (
                    depositItems !== null && (
                        <>
                            {
                                renderBoxAuto()
                            }
                            {
                                renderCancelBtn()
                            }
                        </>
                    )
                )
            }

            <div className={styles.boxMarginFormTop}>
                <div className={styles.boxBankAgentBorder} style={{ borderColor: "#1E2330" }}>
                    <Condition typePage={typePage}></Condition>
                </div>
            </div>
        </>
    );
}
import {
    SET_DATA_ALERT
} from '../../actions/app/alert';

const initialState = {
    type: null,
    title: "",
    text: "",
    action: false,
    redirect: "",
    show: false,
    buttonText: ""
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_DATA_ALERT:
            return { ...state, ...action.payload.items };
        default:
            return state;
    }
}
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

// Redux
import { setDataCondition } from "./../../../../@core/redux/actions/app/conditions";

//  CSS
import modalStyles from "./../assets/css/Modal.module.css";
import styles from "./../assets/css/Style.module.css";
import buttonStyles from "./../assets/css/Button.module.css";

// Component
// import CloseButton from "./CloseButton";

export default function ConditionPopup({ conditionData }) {
    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    const [conditionJson, setConditionJson] = useState(null);
    const dispatch = useDispatch();

    const useStyles = makeStyles(() => ({
        // btnPosition: {
        //     position: "absolute",
        //     left: "50%",
        //     bottom: "0px",
        //     transform: "translateX(-50%)"
        // },
        borderContent: {
            color: "white",
            marginTop: "10px"
        },
        // boxPadding: {
        //     padding: "20px 40px"
        // },
        contentBoxAlign: {
            marginTop: "20px",
            textAlign: "center"
        },
        // imgAlertSize: {
        //     width: "80px",
        //     height: "auto"
        // },
        // contentBoxAlignText: {
        //     fontSize: "18px",
        //     color: "white"
        // },
        // textYellow: {
        //     color: "rgb(255, 193, 7)"
        // },
        boxContentTitle: {
            fontSize: "20px",
            textShadow: "0px 0px 4px rgb(113 246 255 / 73%)",
            textAlign: "center",
            color: "white"
        },
        textContent: {
            color: "white",
            fontSize: "16px"
        },
        textList: {
            marginBlockStart: "4px",
            marginBlockEnd: "4px"
        }
    }));

    const classes = useStyles();

    const closeBox = () => {
        let myJson = {
            statusShow: false,
            title: null,
            contentType: null
        };

        dispatch(setDataCondition(myJson));
    };

    const renderCondition = (agent_condition) => {
        if(agent_condition !== null) {
            if(agent_condition.length > 0) {
                let dataCondition = agent_condition.find((item) => {
                    return item.code === conditionJson.contentType
                });

                return ReactHtmlParser(dataCondition?.text);
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    };

    const renderContent = () => {
        if (conditionJson !== null) {
            if (conditionJson.contentType === "commission") {
                return (
                    <div className={classes.textContent}>
                        <p className={classes.textList}>
                            1. สมาชิกเชิญเพื่อน จะได้ค่าคอมมิชชั่นยอดการเดิมพัน
                        </p>
                        <p className={classes.textList}>
                            2. เพื่อนสมาชิกที่เชิญ จะได้ค่าคอมมิชชั่นยอดการเดิมพัน
                        </p>
                        <p className={classes.textList}>
                            3. แลกค่าคอมมิชชั่นเป็นเครดิต สมาชิกต้องไม่ติดโปร
                        </p>
                        <p className={classes.textList}>
                            4. ระบบจะคิดค่าคอมมิชชั่น ให้หลังจากที่เดิมพันเกมในรอบนั้นสิ้นสุด
                        </p>
                        <p className={classes.textList}>
                            5. ระบบจะไม่คิดค่าคอมมิชชั่น ในเกมยิงปลา
                        </p>
                    </div>
                );
            }
            else {
                if(dataSetting?.agent_condition !== undefined) {
                    return renderCondition(dataSetting.agent_condition);
                }
            }
        }

        return null;
    };

    useEffect(() => {
        setConditionJson(conditionData);
    }, [conditionData, dispatch]);

    return (
        <>
            <div className={modalStyles.contentPosition} style={{ zIndex: "2002" }}>
                <div className={modalStyles.boxBody}>
                    <div className={modalStyles.box}>
                        <div className={modalStyles.closeButton}>
                            <div className={buttonStyles.buttonClose} onClick={closeBox}>
                                <img className={styles.iconClose} src="/images/game/btn_close_circle.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                            </div>
                        </div>
                        <div className={[modalStyles.boxContentWidthBig].join(" ")}>
                            <div className={modalStyles.boxContentPadding}>
                                <div className={[classes.boxContentTitle, styles.center].join(" ")} style={{ marginTop: "0px" }}>
                                    เงื่อนไข{conditionJson !== null && (conditionJson.title)}
                                </div>
                                <div className={classes.borderContent}>
                                    {renderContent()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={modalStyles.fixedBackdrop} style={{ zIndex: "2000", opacity: "0.9" }} onClick={closeBox}></div>
        </>
    );
}
import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import translationTH from './i18n/translations/th.json';
import translationEN from './i18n/translations/en.json';


const resources = {
    th: {
      translation: translationTH
    },
    en: {
      translation: translationEN
    }
};
  
i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'th',
    debug: true,
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: true
    }
})

export default i18n;